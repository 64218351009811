const createPopover = (element) => {
  let template =
    '<div class="popover" role="tooltip"><div class="arrow"></div><h3 class="popover-title"></h3><div class="popover-content"></div></div>'

  let $this = $(element)

  if ($this.is('.popover-wide')) {
    template = template.replace('class="popover"', 'class="popover popover-wide"')
  }

  if ($this.is('.popover-content-pre')) {
    template = template.replace(
      '<div class="popover-content"></div>',
      '<pre class="popover-content no-margins"></pre>'
    )
  }

  $this.popover({
    placement: $this.data('placement') || 'top',
    trigger: $this.data('trigger') || 'hover',
    container: $this.data('container') || false,
    html: $this.data('html') ?? true,
    template: template,
    sanitize: false,
  })
}

$(document).on('argon:createPopover', (event) => createPopover(event.target))
