import { DeviceSearch } from '~/old/angular/models/device_search'

$(() => {
  $('@credits').on('cocoon:after-insert', (e, insertedItem) => {
    insertedItem.find('@billing_cycle').select2()
    new DeviceSearch(insertedItem.find('@device')).activate({})
  })

  $('@custom_charges_wrapper').on('cocoon:after-insert', () => {
    const $input = $('@custom_charges_wrapper @datepicker:last')
    $input.datepicker({
      format: 'yyyy-mm-dd',
      autoclose: true,
      todayHighlight: true,
    })
    const $select = $('@custom_charges_wrapper .select2:last')
    let options = {}
    const args = $select.data('select2-options')
    if (args && typeof args === 'object') {
      options = $.extend(options, args)
    }
    $select.select2(options)
  })
})
