import swal from 'sweetalert'
import {
  required_category_fields_customer_settings_accounting_type_path,
  most_frequent_attributes_customer_accounting_categories_path,
} from '~/helpers/rails-routes'

export class AccountingCategoriesForm {
  /**
   *
   * @param {JQuery} $container
   */
  constructor($container) {
    if ($container.length === 0) return

    const customer_id = $container.data('customer')

    $container.find('@accounting_types_select').on('change', (e) => {
      if ($(e.target).val() === '') return
      const accounting_type_id = $(e.target).val()
      const url = required_category_fields_customer_settings_accounting_type_path(
        customer_id,
        accounting_type_id
      )
      $.ajax({
        type: 'GET',
        dataType: 'json',
        url: url,
      })
        .then((json) => {
          $container
            .find('>input')
            .not('[name="accounting_category[name]"]')
            .prop('required', false)
          $container
            .find("select[name='accounting_category[approval_manager_ids]']")
            .prop('required', false)
          jQuery.each(json.required_fields, (index, value) => {
            const elem =
              value === 'approval_managers'
                ? $container.find("select[name='accounting_category[approval_manager_ids][]']")
                : $container.find(`input[name='accounting_category[${value}]']`)
            if (elem) {
              elem.prop('required', true)
            }
          })
        })
        .catch(() => swal({ title: 'Something went wrong.', icon: 'error' }))

      // Hardcoded behaviour just for Kiewit
      if (customer_id === 349) {
        const desc1 = $('[for=accounting_category_description]').text()
        const desc3 = $('[for=accounting_category_description_3]').text()
        const desc4 = $('[for=accounting_category_description_4]').text()
        const tax = $('[for=accounting_category_tax_jurisdiction]').text()

        $container.find('#accounting_category_description_2').on('change', (e) => {
          const url = most_frequent_attributes_customer_accounting_categories_path(customer_id)
          $.ajax({
            type: 'GET',
            dataType: 'json',
            url: url,
            data: { description_2: $(e.target).val() },
          }).then((json) => {
            const question =
              `Would you like to fill '${desc1}', '${desc3}', '${desc4}', and '${tax}' ` +
              `with the most common items for '${json.description}'?`
            if (confirm(question)) {
              $('#accounting_category_description').val(json.description).change()
              $('#accounting_category_description_3').val(json.description_3).change()
              $('#accounting_category_description_4').val(json.description_4).change()
              $('#accounting_category_tax_jurisdiction').val(json.tax_jurisdiction)
            }
          })
        })
      }
    })
  }
}
