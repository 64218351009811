import swal from 'sweetalert'

$(document).on('click', '@submit-carrier-features-assignment', (e) => {
  const message =
    'It will take normally up to 24-48 business hours to complete the action. If it’s' +
    ' needed right away please call in to complete, confirming will send an email to the carrier' +
    ' to add on the packages.'

  const swal_params = {
    title: 'Confirmation',
    text: message,
    icon: 'warning',
    buttons: ['Decline', 'Confirm'],
  }

  swal(swal_params).then((isConfirmed) => {
    if (!isConfirmed) return
    $(e.target).parents('form').submit()
  })
})
