import angular from 'angular'

angular.module('ArgonModule').directive('crSelectColumnsGroup', [
  'crColumnsService',
  (crColumnsService) => ({
    restrict: 'E',
    scope: {
      group: '@',
      vm: '=',
    },
    templateUrl: 'cr_select_columns_group.html',
    link: (scope) => {
      scope.VM = scope.vm
      scope.columns = crColumnsService.columns_grouped_for_select()[scope.group]
    },
  }),
])
