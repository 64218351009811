import angular from 'angular'
import { size, reduce } from 'lodash-es'
import { formatDate } from '~/helpers/format-date'
import { defineMessages, useI18n } from '~/helpers/use-i18n'

const messages = defineMessages({
  en: {
    billing_cycle: 'Billing Cycle',
    cycles: 'Cycles',
    custom_range: 'Custom Range',
    not: 'Not',
    contains: 'contains',
    does_not_contain: 'does not contain',
    not_empty: 'not empty',
    no_value: 'no value',
    greater_than: 'greater than',
    less_than: 'less than',
    between: 'between',
    and: 'and',
  },
  'fr-CA': {
    billing_cycle: 'Cycle de Facturation',
    cycles: 'Cycles',
    custom_range: 'Plage Personnalisée',
    not: "n'est pas",
    contains: 'contient',
    does_not_contain: 'ne contient pas',
    not_empty: 'pas vide',
    no_value: 'aucune valeur',
    greater_than: 'plus grand que',
    less_than: 'moins que',
    between: 'Entre',
    and: 'Et',
  },
})

const { t } = useI18n({ messages })

angular.module('ArgonModule').directive('currentFilters', [
  'dateFilter',
  function () {
    return {
      restrict: 'E',
      templateUrl: 'custom-report-current-filters.html',
      scope: {
        keys: '=',
        vm: '=',
      },
      link: (scope, element) => {
        // we need this to catch clicks on the element
        element.css('display', 'block')

        const tr = (key) => t(key)
        const l_date = (date) => formatDate(date, '%B %Y')

        const VM = scope.vm
        VM.current_filters_billing_cycle_value = tr('billing_cycle')
        scope.$watch(
          () => VM.current_filters,
          () => {
            scope.current_filters = {
              keys: scope.keys,
              descriptions: {},
              remove: (key) => {
                VM.report.conditions[key].enabled = false
                scope.current_filters.descriptions[key].removed = true
              },
            }

            if (!VM.current_filters) return

            const reducer = (memo, f) => {
              memo[f.key] = f
              return memo
            }
            const current_filters_by_keys = reduce(VM.current_filters, reducer, {})

            const filters = []
            for (const key of scope.keys) {
              if (current_filters_by_keys[key]) filters.push(current_filters_by_keys[key])
            }

            for (const filter of filters) {
              let result
              switch (filter.key) {
                case 'billing_cycle':
                  if (filter.values.single) {
                    const value = l_date(filter.values.single)
                    VM.current_filters_billing_cycle_value = value
                    VM.current_filters_billing_cycle_value_short = value
                  }
                  if (filter.values.last) {
                    const from = l_date(filter.values.last.min)
                    const to = l_date(filter.values.last.max)
                    result = { name: tr('cycles'), value: `${from} — ${to}` }
                    VM.current_filters_billing_cycle_value = filter.values.last.value
                    VM.current_filters_billing_cycle_value_short = filter.values.last.value
                  }
                  if (filter.values.range) {
                    const from = l_date(filter.values.range.min)
                    const to = l_date(filter.values.range.max)
                    result = { name: tr('cycles'), value: `${from} — ${to}` }
                    VM.current_filters_billing_cycle_value = tr('custom_range')
                    VM.current_filters_billing_cycle_value_short = tr('custom_range')
                  }
                  break

                default:
                  if (filter.valid == true) {
                    let name =
                      filter.translated_key_description || filter.key_description || filter.key
                    name = name.replace('Device ', '')
                    let value
                    if (filter.values.is !== undefined) {
                      value = filter.values.is.join(', ')
                    }
                    if (filter.values.not !== undefined) {
                      const filterPrefix = tr('not')
                      value = `${filterPrefix} ${filter.values.not.join(', ')}`
                    }
                    if (filter.values.contains !== undefined) {
                      const filterPrefix = tr('contains')
                      value = `${filterPrefix} '${filter.values.contains}'`
                    }
                    if (filter.values.not_contains !== undefined) {
                      const filterPrefix = tr('does_not_contain')
                      value = `${filterPrefix} '${filter.values.not_contains}'`
                    }
                    if (filter.values.known !== undefined) {
                      value = tr('not_empty')
                    }
                    if (filter.values.unknown !== undefined) {
                      value = tr('no_value')
                    }
                    if (filter.values.greater !== undefined) {
                      const filterPrefix = tr('greater_than')
                      value = `'${filterPrefix}' '${filter.values.greater}'`
                    }
                    if (filter.values.less !== undefined) {
                      const filterPrefix = tr('less_than')
                      value = `${filterPrefix} '${filter.values.less}'`
                    }
                    if (filter.values.between) {
                      let prefixBetween = tr('between')
                      let prefixAnd = tr('and')
                      value =
                        `${prefixBetween} '${filter.values.between.min}' ` +
                        `${prefixAnd} '${filter.values.between.max}'`
                    }
                    result = { name: name, value: value }
                  }
              }
              if (result) {
                // when filter is removed, it's set to true
                result.removed = false
                scope.current_filters.descriptions[filter.key] = result
              }

              // remove all filters descriptions, if only billing cycle is selected
              // BUT display if custom range is selected
              if (
                VM.current_filters.length == 1 &&
                VM.current_filters[0].key == 'billing_cycle' &&
                !VM.current_filters[0].values.range
              ) {
                scope.current_filters.descriptions = {}
              }
            }

            scope.current_filters.isEmpty = size(scope.current_filters.descriptions) == 0
          }
        )
      },
    }
  },
])
