import { isObject } from 'lodash-es'

// for
// input type=text role=datepicker data-datepickerOptions=options
const initDatepickers = (element) => {
  $(element)
    .find('[role=datepicker]')
    .each(function () {
      const $this = $(this)
      const options = {
        format: 'yyyy-mm-dd',
        autoclose: true,
        todayHighlight: true,
      }

      const datepicker_options = $this.data('datepickerOptions')

      if (isObject(datepicker_options)) $.extend(options, datepicker_options)

      $this.datepicker(options)
    })
}

$(document).on('init.argon.datepickers', (event) => {
  event.stopPropagation()
  initDatepickers(event.target)
})
