import angular from 'angular'

angular.module('ArgonModule').directive('uiDatatable', [
  '$timeout',
  function () {
    return {
      link: ($scope, element) => $(element).trigger('init.argon.datatable'),
    }
  },
])
