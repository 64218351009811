$(() => {
  const carrier_login_login_type = $('@carrier-login-login-type')
  const carrier_login_import_hw_orders = $('@carrier-login-import-hw-orders')
  $(carrier_login_login_type).on('change', () => {
    if (['bell_eordering', 'telus_iq', 'telus_iq2'].includes(carrier_login_login_type.val())) {
      carrier_login_import_hw_orders.show()
    } else {
      carrier_login_import_hw_orders.hide()
    }
  })
  carrier_login_login_type.change()
})
