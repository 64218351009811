import angular from 'angular'

angular.module('ArgonModule').directive('crFilterGroup', () => ({
  restrict: 'E',
  templateUrl: 'custom-report-filter-group.html',
  transclude: true,
  scope: {
    group: '=',
    title: '@',
  },
}))
