import { currentUser, customerNumericId } from '~/store'

// working with localStorage without the risk of breaking the app
const setItem = (key, value) => {
  try {
    localStorage.setItem(key, JSON.stringify(value))
  } catch (e) {
    console.error(e)
  }
}

const getItem = (key) => {
  try {
    const value = localStorage.getItem(key)
    return value && JSON.parse(value)
  } catch (e) {
    console.error(e)
  }
}

/**
 *
 * @typedef { { id: number; is_one_time_use: boolean; user_id: number; customer_id: number; name: string; } } LocationType
 */

const isValid = (location) => {
  try {
    return ['id', 'user_id', 'customer_id', 'name', 'is_one_time_use'].every(
      (key) => key in location
    )
  } catch {
    return false
  }
}

const ALL_LOCATIONS_KEY = 'oneTimeUseLocations'

/** @returns { LocationType[] } */
const getAllLocations = () => (getItem(ALL_LOCATIONS_KEY) || []).filter(isValid)

/** @param { LocationType[] } locations */
const setAllLocations = (locations = []) => setItem(ALL_LOCATIONS_KEY, locations.filter(isValid))

/** @returns { LocationType[] } */
export const getCustomerOneTimeUseLocations = () =>
  getAllLocations().filter(
    (location) => location.customer_id === customerNumericId && location.user_id === currentUser.id
  )

/** @param { Omit<LocationType, 'id' | 'user_id' | 'customer_id'> } location */
export const addCustomerOneTimeUseLocation = (location) => {
  const name = (
    location.address1 ||
    location.address2 ||
    location.address ||
    location.contact_name ||
    'Address'
  ).substring(0, 70)
  const customerLocations = getCustomerOneTimeUseLocations()
  // don't add address if it already exists
  const locationExists = customerLocations.find((el) => {
    if (el.name === name) return true
    if (location.address && location.address === el.address) return true
    if (location.address1 && location.address1 === el.address1) return true
    return false
  })
  if (locationExists) {
    return
  }
  const allLocations = getAllLocations()
  allLocations.unshift({
    ...location,
    name,
    text: name,
    user_id: currentUser.id,
    customer_id: customerNumericId,
    id: Math.random(),
    is_one_time_use: true,
  })
  // remove last address if there are 5 addresses already
  if (customerLocations.length > 4) {
    const lastId = customerLocations[customerLocations.length - 1].id
    const lastIndex = allLocations.find((el) => el.id === lastId)
    allLocations.splice(lastIndex, 1)
  }
  setAllLocations(allLocations)
}
