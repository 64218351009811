import angular from 'angular'
import { repair_requests_path, repair_request_path, search_path } from '~/helpers/rails-routes'

const ArgonModule = angular.module('ArgonModule')

ArgonModule.filter('splitByComma', () => {
  return (input) => (input ? input.replace(/[\s]/g, '').split(',').filter(Boolean) : '')
})

ArgonModule.controller('RepairRequestsCtrl', [
  '$rootScope',
  '$scope',
  '$http',
  '$timeout',
  '$filter',
  function ($rootScope, $scope, $http, $timeout, $filter) {
    const VM = this
    VM.$scope = $scope
    VM.repairRequest = {}
    VM.request = { isLoading: false }

    VM.refresh_content = () => {
      $.ajax({
        url: repair_requests_path(),
        type: 'GET',
        cache: false,
        success: (html) => {
          $('@openContent').html($(html).find('@openContent'))
          $('@closedContent').html($(html).find('@closedContent'))
          $('@openedCount').html($(html).find('@openedCount'))
          $('@closedCount').html($(html).find('@closedCount'))
          $('.dataTable#repairRequests').dataTable().fnDraw()
        },
      })
    }

    VM.submitUpdateRepairRequest = () => {
      VM.request.isLoading = true

      const numbers = VM.repairRequest.numbers_for_state_changes_notification
      if (jQuery.type(numbers) !== 'array') {
        VM.repairRequest.numbers_for_state_changes_notification = $filter('splitByComma')(numbers)
      }

      const emails = VM.repairRequest.emails_for_state_changes_notification
      if (jQuery.type(emails) != 'array') {
        VM.repairRequest.emails_for_state_changes_notification = $filter('splitByComma')(emails)
      }

      if (VM.$scope.fileread) {
        VM.repairRequest = angular.extend(VM.repairRequest, { dealer_invoice: VM.$scope.fileread })
      }

      const url = repair_request_path(VM.repairRequest.id) + '.json'
      $http.patch(url, { repair_request: VM.repairRequest }).then(
        ({ data }) => {
          VM.request.warningMessages = null
          VM.request.isLoading = false
          if (data.action == 'reload_content') {
            VM.refresh_content()
          } else if (data.action == 'reload_page') {
            location.reload()
          }
          VM.request.status = 'success'
          VM.request.successMessages = 'Request updated'
        },
        ({ data }) => {
          VM.request.isLoading = false
          VM.request.status = 'fail'
          VM.request.warningMessages = data.notice
        }
      )
    }

    VM.submitCreateRepairRequest = () => {
      VM.request.isLoading = true

      const numbers = VM.repairRequest.numbers_for_state_changes_notification
      VM.repairRequest.numbers_for_state_changes_notification = $filter('splitByComma')(numbers)

      const emails = VM.repairRequest.emails_for_state_changes_notification
      VM.repairRequest.emails_for_state_changes_notification = $filter('splitByComma')(emails)

      const url = repair_requests_path() + '.json'
      $http.post(url, { repair_request: VM.repairRequest }).then(
        () => {
          VM.request.warningMessages = null
          VM.request.isLoading = false
          VM.refresh_content()
          VM.request.status = 'success'
          VM.request.successMessages = 'Repair request was created'
        },
        (data) => {
          VM.request.isLoading = false
          VM.request.status = 'fail'
          VM.request.warningMessages = data.notice
        }
      )
    }

    $scope.$watch('VM.selectedDealer', () => {
      const id = VM.selectedDealer?.id
      const emails = $(`#dealer_id > option[value=${id}]`).data('emails')
      let html = ''
      for (let index in emails) {
        const email = emails[index]
        html += '<p>' + email + '</p>'
      }
      $('@dealerSelectedEmails').html(html)
    })

    $(document).on('change', '#invoice-file', (e) => {
      const file = e.target.files[0]
      if (!file) return
      const reader = new FileReader()
      reader.onload = () => {
        const object = {}
        object.filename = file.name
        object.data = reader.result
        VM.$scope.fileread = object
        VM.$scope.$apply()
      }
      reader.readAsDataURL(file)
    })

    $(document).on('change', '@devicesSelect', function () {
      VM.repairRequest = $('@currentDevice').data('device')
      VM.request.warningMessages = ''
      VM.request.successMessages = ''
      VM.$scope.$apply()
    })

    $(document).on('click', '@repairRequestsEdit', function () {
      VM.repairRequest = $(this).data('request')
      VM.request.warningMessages = ''
      VM.request.successMessages = ''
      VM.request.status = false
      VM.$scope.$apply()

      const target = $(this).data('target')
      const $datepicker = $(target).find('@datepicker')
      if (target == '#shipped_to_simplex_for_repair' || target == '#returned_to_simplex') {
        if (VM.repairRequest.date_shipped_from_simplex) {
          $datepicker.datepicker('update', new Date(VM.repairRequest.date_shipped_from_simplex))
        }
      } else if (target == '#sent_to_dealer_for_repair') {
        if (VM.repairRequest.dealer_date_shipped) {
          $datepicker.datepicker('update', new Date(VM.repairRequest.dealer_date_shipped))
        }
      }
    })

    return VM
  },
])

$(() => {
  const getSelect2Values = (select2Element, paramName, newData, params) => {
    if (select2Element.val()) {
      newData.push({ name: paramName, value: select2Element.val() })
      params[paramName] = select2Element.val()
    }
  }

  $('#repairRequests').dataTable({
    bSort: false,
    filter: false,
    iDisplayLength: 10,
    bProcessing: true,
    bServerSide: true,
    sAjaxSource: '/repair_requests/search.json',
    fnServerData: (sSource, aoData, fnCallback) => {
      const newData = aoData
      const params = {}
      getSelect2Values($('@statusFakeFilter #status'), 'status', newData, params)
      getSelect2Values($('@warrantyFakeFilter #warranty'), 'warranty', newData, params)
      getSelect2Values($('@modelFakeFilter #model'), 'model', newData, params)
      getSelect2Values($('@imeiFakeFilter input'), 'imei', newData, params)
      getSelect2Values($('@textFakeFilter input'), 'text', newData, params)
      getSelect2Values($('@usernameFakeFilter input'), 'username', newData, params)
      getSelect2Values($('@phoneFakeFilter input'), 'phone', newData, params)

      $.getJSON(sSource, newData, (json) => {
        let url = window.location.pathname
        if (!jQuery.isEmptyObject(params)) {
          url += '?' + $.param(params)
        }
        history.pushState({}, '', url)
        fnCallback(json)
      })
    },
  })

  // In last 4.0 version here should be "select2:select" and "select2:unselect" events
  // For some reasons "select2-selected" is not working
  $('@repairRequestsFilter input').on('input', () =>
    $('.dataTable#repairRequests').dataTable().fnDraw()
  )

  $('@repairRequestsFilter').on('selected select2-removed', () =>
    $('.dataTable#repairRequests').dataTable().fnDraw()
  )

  const formatRepo = (data) => {
    let html = ''
    html +=
      `<option data-id=${data.id} role='deviceOption'>` + data.text.employee_name + '</option>'
    return html
  }

  const formatRepoSelection = (data) => {
    return (
      `<span role='currentDevice' data-device='${JSON.stringify(data.text)}'>` +
      `${data.text.employee_name} - ` +
      `${data.text.phone_number}(` +
      `${data.device_make_name})` +
      '</span>'
    )
  }

  $('@devicesSelect').select2({
    ajax: {
      placeholder: 'Select the device...',
      url: search_path(),
      dataType: 'json',
      delay: 250,
      data: (params) => ({
        term: params,
        page_limit: 10,
        customer_ids: $('@devicesSelect').data('customer-ids') || [],
      }),
      results: (data) => {
        return {
          results: $.map(data.devices, (item) => {
            delete item.business_account
            return {
              text: {
                device_id: item.id,
                employee_name: item.username,
                phone_number: item.number,
                imei: item.imei_number,
                device_model_id: item.device_model_id,
                warranty_status: item.warranty_status,
              },
              device_make_name: item.device_make_name,
              id: item.id,
            }
          }),
        }
      },
      cache: true,
    },
    minimumInputLength: 3,
    formatResult: formatRepo,
    formatSelection: formatRepoSelection,
  })
})
