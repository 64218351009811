import angular from 'angular'
import { isEmpty, each } from 'lodash-es'

function controller($scope) {
  const ctrl = this

  $scope.form = {
    newPermissions: {},
    deletedPermissions: [],
  }

  $scope.initForm = (products_permissions) => {
    // ['1'] -> [{id: '1', title: '1'}] or angular will add 'string:1'
    // in <option value="string:1"> tags
    // https://github.com/SimplexMobility/issues/issues/3514#issuecomment-1419198826
    const convert_permissions = (permissions) => {
      permissions.map((el) =>
        ['plans', 'available_plans', 'colors', 'available_colors'].forEach(
          (key) => (el[key] = el[key].map((str) => ({ id: str, title: str })))
        )
      )
    }
    convert_permissions(products_permissions.all)
    convert_permissions(products_permissions.permitted)
    $scope.form.permissions = products_permissions
  }

  $scope.form.isValid = () => {
    return $scope.form.permissions.permitted.every((permission) => {
      if (isEmpty(permission.plans) && permission.available_plans.length != 0) return false
      if (isEmpty(permission.colors) && permission.available_colors.length != 0) return false
      return true
    })
  }

  $scope.form.hasPermissions = () => $scope.form.permissions.permitted.length > 0

  $scope.form.availablePermissions = () => {
    const permitted_product_ids = $scope.form.permissions.permitted.map(
      (permission) => permission.product.id
    )

    return $scope.form.permissions.all.filter(
      (permission) => permitted_product_ids.indexOf(permission.product.id) == -1
    )
  }

  $scope.form.addPermission = () => {
    const permission = $scope.form.newPermissions
    permission.permitted = '1'
    permission.colors = permission.available_colors
    permission.plans = permission.available_plans

    $scope.form.permissions.permitted.push(permission)

    // clear select
    $scope.form.newPermissions = { product: { name: '' } }
  }

  $scope.form.permitAllProducts = () => ($scope.form.permissions.permitted = [])

  $scope.form.remove_one_year_terms = () =>
    each(
      $scope.form.permissions.permitted,
      (permission) =>
        (permission.plans = permission.plans.filter((plan) => !/(1 year|1-year)/i.test(plan)))
    )

  $scope.form.remove_two_year_terms = () =>
    each(
      $scope.form.permissions.permitted,
      (permission) =>
        (permission.plans = permission.plans.filter((plan) => !/(2 year|2-year)/i.test(plan)))
    )

  $scope.form.remove_voice_only_terms = () =>
    each(
      $scope.form.permissions.permitted,
      (permission) =>
        (permission.plans = permission.plans.filter((plan) => !/(voice only)/i.test(plan)))
    )

  $scope.form.remove_by_maker = (maker) => {
    $scope.form.permissions.permitted = $scope.form.permissions.permitted.filter((permission) => {
      if (permission.product.device_make == maker) {
        $scope.form.deletedPermissions.push(permission)
      }
      return permission.product.device_make != maker
    })
  }

  $scope.form.add_deleted_by_maker = (maker) => {
    each($scope.form.deletedPermissions, (permission) => {
      if (permission.product.device_make == maker) {
        $scope.form.permissions.permitted.push(permission)
      }
    })
    $scope.form.deletedPermissions = $scope.form.deletedPermissions.filter(
      (permission) => permission.product.device_make == maker
    )
  }

  return ctrl
}

angular.module('argon.hardwareOrdering').controller('hwProductPermissionsCtrl', controller)

controller.$inject = ['$scope']
