import angular from 'angular'

angular.module('ArgonModule').controller('HardwareOrderingCtrl', [
  '$scope',
  function ($scope) {
    $scope.allow_select_locations = '1'

    $scope.contacts = []
    $scope.approvers = []
    $scope.simplexAccessories = []
    $scope.availableAccessories = []
    $scope.specialPricing = []

    $scope.initialApprovers = () => $scope.approvers.filter((approver) => approver.first == '1')

    $scope.finalApprovers = () => $scope.approvers.filter((approver) => approver.first != '1')

    $scope.removeApprover = (object) =>
      ($scope.approvers = $scope.approvers.filter((approver) => approver !== object))

    $scope.removeSpecialPricing = (accessory) => {
      const index = $scope.specialPricing.indexOf(accessory)
      $scope.specialPricing.splice(index, 1)
    }
  },
])
