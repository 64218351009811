import swal from 'sweetalert'
import angular from 'angular'
import { SotiInfo } from '../../models/soti_info'

angular.module('ArgonModule').controller('SotiDeviceActionsCtrl', [
  '$scope',
  function ($scope) {
    const VM = this

    VM.init = (params) => {
      VM.urls = $('#mdm-soti-device-urls').data('urls')
      VM.mdmInfo = { data: new SotiInfo(params.data), isLoading: true }
      checkApiAvailability()
    }

    VM.checkIn = () =>
      confirmAction(
        'Are you sure you want to requests this device to communicate with ' +
          'the server and update its information?',
        VM.urls.check_in
      )

    VM.delete = () =>
      confirmAction(
        'Are you sure you want to delete this device from Soti MobiControl?',
        VM.urls.delete
      )

    VM.disable = () =>
      confirmAction(
        'Are you sure you want disconnects this device from the Soti MobiControl?',
        VM.urls.disable
      )

    VM.factoryReset = () =>
      confirmAction(
        'Are you sure you want to performs this device factory reset?',
        VM.urls.factory_reset
      )

    VM.locate = () =>
      confirmAction(
        'Are you sure you want to request this device to send its current location?',
        VM.urls.locate
      )

    VM.unenroll = () =>
      confirmAction(
        'Are you sure you want to request this device remove its management configuration?',
        VM.urls.unenroll
      )

    VM.wipe = () =>
      confirmAction(
        'Are you sure you want to request a complete erase of this device' +
          'and restore it to factory defaults?',
        VM.urls.wipe
      )

    VM.fetchMdmInfo = () => {
      if (!VM.urls) return
      VM.mdmInfo.isLoading = true
      $.post(VM.urls.refresh_mdm_info, { force: true }, (response) => {
        VM.mdmInfo.data = new SotiInfo(response.data)
        initializeData()
        VM.mdmInfo.isLoading = false
        $scope.$apply()
      })
    }

    //

    const initializeData = () => {
      VM.installedApplications = { data: [], isLoading: false }
      VM.actionsHistory = { data: [], isLoading: false }
    }

    const checkApiAvailability = () => {
      if (!VM.urls) return
      $.post(VM.urls.status, {}, (response) => {
        if (response.available) {
          VM.fetchMdmInfo()
        } else {
          VM.mdmInfo.isLoading = false
          $scope.$apply()
        }
      })
    }

    const confirmAction = (message, url, params) => {
      if (!url) return

      const swal_params = {
        title: 'Confirmation',
        text: message,
        icon: 'warning',
        buttons: true,
      }
      swal(swal_params).then((isConfirmed) => {
        if (!isConfirmed) return
        makeRequest(url, params)
      })
    }

    const makeRequest = (url, params = {}) => {
      const success = (response) => {
        swal({
          title: response.succeeded ? 'Success' : 'Failure',
          text: response.message,
          icon: response.succeeded ? 'info' : 'error',
        })
      }
      const fail = () => swal({ title: 'Failure', text: 'Something went wrong', icon: 'error' })

      return $.post(url, params).done(success).fail(fail)
    }

    return VM
  },
])
