import angular from 'angular'
import { DeviceManagersSearch } from '../models/device_managers_search'

angular.module('ArgonModule').directive('deviceManagersSearch', [
  '$timeout',
  function ($timeout) {
    return {
      link: ($scope, element, attributes) => {
        const options = JSON.parse(attributes.deviceSearch || '{}')
        $timeout(() => new DeviceManagersSearch(element).activate(options))
      },
    }
  },
])
