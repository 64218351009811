import { updateDateTimeItems } from '~/old/elements/format_date_time'
$(() => {
  // It is used by events page and by events/activity_log partial block
  $(document).on('click', '.next_page', (e) => {
    if (!$(e.target).is('a')) {
      return false
    }
    const $container = $(e.target).closest('.events_container')
    $container.append('<div class="pure-spinner-block margin-bottom-30"></div>')
    const url = $('.next_page').attr('href') + ' #' + $container.attr('id')
    $container.load(url, () => updateDateTimeItems('#' + $container.attr('id')))
    $(e.target).hide()
    return false
  })
})
