import { map } from 'lodash-es'

// You can use angular directive `selected-object` directive to set initial value
export class hwAccountingCategoriesSearch {
  constructor(element) {
    this.element = $(element)
    this.action = this.element.data('action')
  }

  activate() {
    this.element.select2({
      minimumInputLength: 1,
      allowClear: true,
      url: this.action,
      ajax: {
        url: this.action,
        dataType: 'json',
        delay: 250,
        cache: true,
        data: (text) => ({ q: { name_cont: text } }),
        results: (data) => {
          const options = map(data.accounting_categories, (category) =>
            this.formatCategoryToOption(category)
          )
          return { results: options }
        },
      },
    })
  }

  chooseOption(id) {
    // what if I need to clear?
    if (!id) return
    const url = `${this.action}/${id}`
    return $.getJSON(url).then((category) =>
      this.element.select2('data', this.formatCategoryToOption(category))
    )
  }

  formatCategoryToOption(category) {
    return { id: category.name, text: category.short_info }
  }
}
