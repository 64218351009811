import angular from 'angular'

function PurchaseRequestCtrl($scope, $attrs, $loader, $purchaseOrder, PurchaseRequest) {
  const ctrl = this
  ctrl.purchaseRequest = new PurchaseRequest()
  ctrl.purchaseRequest.purchaseOrder = $purchaseOrder

  const initialize = (data) => {
    ctrl.purchaseRequest.update(data)
    $purchaseOrder.update(data.purchase_order)
  }

  const loadPurchaseRequest = () => {
    $loader.get($attrs.model).then((data) => {
      initialize(data)
      $scope.$apply()
    })
  }

  loadPurchaseRequest()

  const addNewEmail = () => ctrl.purchaseRequest.addEmail('')
  const removeEmail = (index) => ctrl.purchaseRequest.removeEmail(index)
  const requestedPurchaseOrder = () => {
    if ($purchaseOrder.isRequested()) {
      return $purchaseOrder
    }
  }

  ctrl.addNewEmail = addNewEmail
  ctrl.removeEmail = removeEmail
  ctrl.requestedPurchaseOrder = requestedPurchaseOrder

  return ctrl
}

angular.module('argon.hardwareOrdering').controller('hwPurchaseRequestCtrl', PurchaseRequestCtrl)

PurchaseRequestCtrl.$inject = [
  '$scope',
  '$attrs',
  '$hwPurchaseRequestLoader',
  '$hwPurchaseOrder',
  'hwPurchaseRequest',
]
