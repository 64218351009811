import swal from 'sweetalert'
import angular from 'angular'
import { filter, includes, first, toLower, isEmpty, find, reduce, keys } from 'lodash-es'
import { countryCodes, ownershipTypes } from './helpers'

angular.module('ArgonModule').controller('Maas360EnrollDeviceCtrl', [
  '$scope',
  '$http',
  '$q',
  '$timeout',
  function ($scope, $http, $q, $timeout) {
    const VM = this

    VM.init = (params) => {
      VM.state = {}
      VM.deviceInfo = params.device || {}
      VM.countryCodes = countryCodes()
      VM.urls = $('#mdm-maas360-urls').data('urls')
      VM.mdmInfo = angular.copy(params.mdm_info) || { base: {} }
      VM.ownershipTypes = ownershipTypes()
      VM.authenticityToken = $('meta[name="csrf-token"]').attr('content')
    }

    VM.fillForm = () => {
      activateTab('basic')
      reInitilizeForm()

      $q.all([initUserData(), fetchPolicies()]).then(() => (VM.form.isLoading = false))
    }

    VM.platformCahnged = () => {
      VM.state.availablePolicyNames = VM.state.policies[VM.form.platformName]
      VM.form.policyName = null
    }

    VM.searchUser = () => {
      if (VM.form.user.name.length < 2) return
      $('#usersFilter[data-toggle=dropdown]').parent().removeClass('open')

      VM.state.foundUsers = filter(VM.state.users, (obj) =>
        includes(obj.userName, VM.form.user.name)
      )
      if (VM.state.foundUsers.length > 0) {
        $('#usersFilter[data-toggle=dropdown]').first().dropdown('toggle')
      }
      return
    }

    VM.userPicked = (user) => setUserData(user)

    VM.enrollDevice = () => {
      VM.form.isLoading = true
      let params = buildEnrollParams()

      params = { authenticity_token: VM.authenticityToken, data: params }
      if (VM.deviceInfo.id) params['device_id'] = VM.deviceInfo.id
      $http.post(VM.urls.enroll, params).then((response) => {
        VM.form.isLoading = false
        if (response.data.succeeded) {
          VM.result = response.data.data
          activateTab('result')
        } else {
          swal({ title: 'Failure', text: response.data.message, icon: 'error' })
        }
      })
    }

    //
    const activateTab = (name) => {
      const tab = $(`#mdm-enroll-device li#${name}-tab > a`)
      $timeout(() => tab.click())
    }

    const reInitilizeForm = () => {
      VM.form = {
        isLoading: true,
        ownership: first(VM.ownershipTypes).value,
        notifyByEmail: true,
        phoneNumber: VM.mdmInfo.base.phone_number || VM.deviceInfo.number,
        countryCode: first(VM.countryCodes),
        user: {
          name: VM.mdmInfo.base.username,
          email: VM.mdmInfo.base.email_address || VM.deviceInfo.email,
        },
        isValid: isValid,
        errors: [],
      }

      VM.result = {}
    }

    const buildEnrollParams = () => {
      const params = {
        userName: VM.form.user.name,
        domain: VM.form.user.domain,
        emailAddress: VM.form.user.email,
      }

      if (VM.form.countryCode && VM.form.phoneNumber) {
        params.phoneNumber = VM.form.phoneNumber
        params.country = VM.form.countryCode
      }

      if (VM.form.ownership) params.ownership = VM.form.ownership
      if (VM.form.notifyByEmail) params.emailUser = 'Yes'
      if (VM.form.notifyBySms) params.sendSMSUser = 'Yes'
      if (VM.form.emailCopyTo && VM.form.copyToEmail) params.emailOther = VM.form.copyToEmail

      if (VM.form.platformName) params.platform = pickPlatformIdentifier(VM.form.platformName)
      if (VM.form.policyName) params.policySet = VM.form.policyName

      params.comments = VM.form.comments
      return params
    }

    const pickPlatformIdentifier = (platformName) => {
      const nameLowerCase = toLower(platformName)
      if (includes(nameLowerCase, 'ios')) return 'iOS'
      if (includes(nameLowerCase, 'android')) return 'Android'
      if (includes(nameLowerCase, 'windows phone')) return 'Windows Phone 7'
      if (includes(nameLowerCase, 'blackberry')) return 'BlackBerry'
      if (includes(nameLowerCase, 'windows')) return 'Windows'
      if (includes(nameLowerCase, 'mac')) return 'Mac'
    }

    const isValid = () => {
      VM.form.errors.length = 0
      validate()
      return VM.form.errors.length == 0 ? true : false
    }

    const validate = () => {
      if (isEmpty(VM.form.user.name)) VM.form.errors.push("Username can't be blank")
      if (isEmpty(VM.form.user.email)) VM.form.errors.push("Email can't be blank")
      if (isEmpty(VM.form.user.domain)) VM.form.errors.push("Domain can't be blank")
    }

    const fetchUsers = () => {
      if (!VM.urls) return deferredPromise()
      const params = { authenticity_token: VM.authenticityToken }
      return $http
        .post(VM.urls.search_user, params)
        .then((response) => (VM.state.users = response.data.data.users))
    }

    const initUserData = () => {
      fetchUsers().then(() => {
        const user = find(VM.state.users, { userName: VM.form.user.name })
        if (!user) return
        setUserData(user)
      })
    }

    const setUserData = (user) => {
      VM.form.user.name = user.userName
      VM.form.user.email = user.emailAddress
      VM.form.user.domain = user.domain
    }

    const fetchPolicies = () => {
      if (!VM.urls) return deferredPromise()

      const reducer = (result, el) => {
        if (el.service) (result[el.service] || (result[el.service] = [])).push(el.policyName)
        return result
      }

      const params = { authenticity_token: VM.authenticityToken }
      return $http.post(VM.urls.policies, params).then((response) => {
        const published = filter(
          response.data.data.policies,
          (policy) => policy.policyStatus == 'PUBLISHED'
        )
        VM.state.policies = reduce(published, reducer, { Select: [] })
        VM.state.platformNames = keys(VM.state.policies)
        VM.form.platformName = first(VM.state.platformNames)
      })
    }

    const deferredPromise = () => {
      const resp = $q.defer()
      resp.resolve()
      return resp.promise
    }

    return VM
  },
])
