import angular from 'angular'

class PurchaseRequest {
  constructor(attributes = {}) {
    // for select2
    this.dealer = { name: '' }
    this.dealers = []
    this.update(attributes)
  }

  update(attributes) {
    for (let attr in attributes) {
      this[attr] = attributes[attr]
    }
  }

  addEmail(email) {
    return this.dealer.emails.push(email)
  }

  removeEmail(index) {
    return this.dealer.emails.splice(index, 1)
  }
}

// regular function (not arrow function) have to be used as an argument of .service/.directive/.controller
// https://stackoverflow.com/questions/49273401/getting-function-prototype-bind-apply-is-not-a-constructor-error
angular.module('argon.hardwareOrdering').service('hwPurchaseRequest', function () {
  return PurchaseRequest
})
