$(document).on('click', '.individual-report-modal', function () {
  $('#device-modal-bill').modal()
  const url = $(this).attr('href')
  $('#device-modal-bill .modal-content')
    .html($('<div class="pure-spinner-block"></div>'))
    .load(url, { partial: true })
  return false
})

$(document).on('click', '.individual-report-toggle-additional-charges', (e) => {
  const className = '__collapsed'
  const $link = $(e.currentTarget)
  const $ul = $link.closest('ul')
  $ul.toggleClass(className)
  const linkText = $ul.hasClass(className) ? $link.data('show-text') : $link.data('hide-text')
  $link.text(linkText)
})
