export class OrderItem {
  constructor(item = {}) {
    for (let attr in item) {
      this[attr] = item[attr]
    }
    if (!this.category) this.category = 'new'
    if (!this.inventory) this.inventory = { pretty_info: '', device_make_id: '' }
    if (!this.device_model_id) this.device_model_id = ''
    if (!this.carrier_base_id) this.carrier_base_id = ''
    if (!this.accessories) this.accessories = []

    this.portIn = (this.port_request || {}).prev_carrier_name ? '1' : '0'
    if (this.port_request) {
      if (!this.port_request.business) this.port_request.business = '0'
    }
  }

  isForNewUser() {
    return this.category == 'new'
  }

  isReplacement() {
    return this.category == 'replacement'
  }
  isAccessory() {
    return this.category == 'accessory'
  }

  hasUpgradeCost() {
    return (
      !this.isAccessory() &&
      !this.inventory.id &&
      this.line &&
      this.line.upgrade_cost !== null &&
      !this.plan.name?.toLowerCase().match('replacement')
    )
  }

  useLine(device) {
    return (this.line = device)
  }

  carrierId() {
    return Number(this.line?.carrier_base_id || this.carrier_base_id)
  }

  availableColors() {
    if (!this.product) return []
    const result = this.product.colors
    return result
  }

  deviceModelId() {
    let result = this.product?.device_model_id
    if (!result) {
      result = this.inventory?.device_model_id
    }
    if (!result) {
      result = this.line?.device_model_id
    }
    if (!result) {
      if (this.isAccessory()) result = this.device_model_id
    }

    result = Number(result)
    return result
  }

  orderedApprovers() {
    // OrderItem::APPROVERS_MOD[:custom]
    // new approvers should be append custom approvers instead append all approvers
    // TODO: Maybe I should eval position when add new approver.
    const last_position = 2000 - this.approvers.length

    const approvers = this.approvers.sort(
      (a, b) => Number(b.position || last_position) - Number(a.position || last_position)
    )

    return approvers
  }

  addNewApprover() {
    return this.approvers.push({ mode: 'existing', state: 'waiting' })
  }
}
