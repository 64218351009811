import { find } from 'lodash-es'

// Manage device status update requests.
export class DeviceStatusUpdateRequest {
  static EXTEND_SUSPENSION = 'Extend Suspension'

  // id for customer GOA
  // to remove status Suspend 'Full Rate'
  // TODO: delete when rewrite to Vue
  static CUSTOMER_GOA_ID = '59c73aa1-cd8f-4d80-9c9f-fdec53d7093c'

  // All available statuses for device requests.
  static statuses = [
    { state: 'cancelled', state_details: '', title: 'Cancel' },
    { state: 'suspended', state_details: 'Reduced Rate', title: 'Suspend - Reduced Rate' },
    { state: 'suspended', state_details: 'Full Rate', title: 'Suspend - Full Rate' },
    {
      state: 'suspended',
      state_details: 'Lost Device/Stolen',
      title: 'Suspend - Lost Device/Stolen',
    },
    { state: 'active', state_details: '', title: 'Reactivate' },
    {
      state: 'suspended',
      state_details: DeviceStatusUpdateRequest.EXTEND_SUSPENSION,
      title: 'Suspend - Extend Suspension',
    },
  ]

  static status_cancel_with_churn = {
    state: 'cancelled',
    state_details: 'Cancel with Churn',
    title: 'Cancel with Churn',
  }

  constructor(request) {
    for (let attr in request) {
      this[attr] = request[attr]
    }
    this.needNewUsername = false
    this.set_status_from_request()
  }

  // Get statuses available for a device.
  available_statuses() {
    const device_status = this.device.status
    const carrier_name = this.carrier_name

    const device_denied_statuses = this.device_denied_statuses

    let statuses = DeviceStatusUpdateRequest.statuses.filter((status) => {
      if (
        carrier_name == 'bell' &&
        status.state_details == DeviceStatusUpdateRequest.EXTEND_SUSPENSION
      ) {
        return true
      }
      return status.state != device_status && !device_denied_statuses.includes(status.state)
    })

    const index = statuses.findIndex((status) => status.state_details == 'Cancel with Churn')
    if (index == -1 && this.carrier_name != 'bell') {
      const cancel_index = statuses.findIndex((status) => status.state == 'cancelled')
      statuses.splice(cancel_index + 1, 0, DeviceStatusUpdateRequest.status_cancel_with_churn)
    }
    if (this.device.customer_id == DeviceStatusUpdateRequest.CUSTOMER_GOA_ID) {
      statuses = statuses.filter((status) => status.state_details != 'Full Rate')
    }
    return statuses
  }

  // Set request fields from given status object.
  set_request_from_status() {
    const status = this.new_status_with_details
    if (status) {
      this.new_status = status.state
      this.new_status_details = status.state_details
    } else {
      this.new_status = ''
      this.new_status_details = ''
    }
  }

  // Set request status object determined from request's fields.
  // Set suspended status details to 'Reduced Rate' if it's empty.
  set_status_from_request() {
    const request_status = this.new_status
    const request_details = this.new_status_details || ''

    this.new_status_with_details = find(
      DeviceStatusUpdateRequest.statuses,
      (status) =>
        (status.state == request_status && status.state_details == request_details) ||
        (request_status == 'extend_suspension' &&
          status.state_details == DeviceStatusUpdateRequest.EXTEND_SUSPENSION) ||
        (status.state == request_status &&
          status.state == 'suspended' &&
          status.state_details == 'Reduced Rate' &&
          request_details == '')
    )

    if (this.new_status_with_details) {
      this.new_status_details = this.new_status_with_details.state_details
      if (
        this.new_status_with_details.state_details == DeviceStatusUpdateRequest.EXTEND_SUSPENSION
      ) {
        this.new_status = this.new_status_with_details.state
      }
    }
  }
}
