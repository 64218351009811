import { defineMessages, useI18n } from '~/helpers/use-i18n'

const edgeLink = "<a target='_blank' href='https://www.microsoft.com/edge'>Microsoft Edge</a>"
const messages = defineMessages({
  en: {
    text: `Your browser is outdated. Please, switch to ${edgeLink}`,
  },
  'fr-CA': {
    text: `Votre navigateur est obsolète. Veuillez passer à ${edgeLink}`,
  },
})
const { t } = useI18n({ messages })

const isIE = /Trident\/|MSIE/.test(window.navigator.userAgent)

function showBanner() {
  const bannerEl = document.createElement('div')
  const style = bannerEl.style
  style.position = 'fixed'
  style.background = 'black'
  style.left = 0
  style.bottom = 0
  style.color = 'white'
  style.zIndex = 100
  style.width = '100%'
  style.padding = '20px'
  bannerEl.innerHTML = t('text')
  document.body.appendChild(bannerEl)
}

document.addEventListener('DOMContentLoaded', () => {
  if (isIE) showBanner()
})
