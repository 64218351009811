import swal from 'sweetalert'

$(() => {
  const options = $('body').data('sweetalert')
  if (options) {
    if (jQuery.type(options) == 'string') swal({ title: options })
    else if (jQuery.type(options) == 'array') swal(...options)
    else swal(options)
  }
})
