import angular from 'angular'
import { ApproversSearch } from '../models/approvers_search'

angular.module('ArgonModule').directive('approversSearch', [
  '$timeout',
  function ($timeout) {
    return {
      require: '?ngModel',
      link: ($scope, element, attributes, $ngModel) => {
        const search = new ApproversSearch(element)

        $timeout(() => search.activate())

        // If given model changes
        const model_getter = () => $ngModel.$modelValue
        $scope.$watch(model_getter, (nV) => {
          // sometimes it's just id for some reasons
          if (nV instanceof Object) {
            $timeout(() => element.select2('data', nV))
          }
        })
        // If selected value changes
        element.on('change', () => $timeout(() => $ngModel.$setViewValue(element.select2('data'))))
      },
    }
  },
])
