import { map } from 'lodash-es'
import { device_models_path } from '~/helpers/rails-routes'

$(() => {
  const container = $('@deviceModelsMapper')
  const tableContainer = $('#device-model-mappings-table')

  if (container.length && tableContainer.length) {
    new DeviceModelsMapping(container, tableContainer)
  }
})

// Helper class to map DeviceModelMapping to DeviceModel
class DeviceModelsMapping {
  /**
   *
   * @param {JQuery} $container
   * @param {JQuery} $tableContainer
   */
  constructor($container, $tableContainer) {
    this.$container = $container
    this.$tableContainer = $tableContainer

    if (this.models_data === undefined) {
      this.retrieveDeviceModels()
      this.bindEvents()
    } else {
      this.initSelects()
      this.initDataTable()
    }
  }

  // Get device models list
  retrieveDeviceModels() {
    const url = device_models_path({ format: 'json' })
    $.getJSON(url, (data) => {
      const device_models = data['device_models'] || []
      this.models_data = map(device_models, (model) => ({
        id: model.id,
        text: model.make_and_model,
      }))
      this.initSelects()
      this.initDataTable()
    })
  }

  // Get container of device mapping with given id
  mappingContainer(id) {
    return this.$container.find('tr[data-mapping-id=' + id + ']')
  }

  // Get integer id of device mapping from its element
  mappingId(element) {
    return $(element).closest('tr').data('mapping-id')
  }

  // Init datatable
  initDataTable() {
    this.$tableContainer.dataTable({
      retrieve: true,
      filter: false,
      order: [],
      iDisplayLength: 25,
    })
  }

  // Set device mapping save button enabled state
  setSaveButtonEnabled(id, enabled) {
    const button = this.mappingContainer(id).find('.devices-mapper-save-button')
    button.attr('disabled', !enabled)
  }

  // Enable device mapping save button
  enableSaveButton(id) {
    this.setSaveButtonEnabled(id, true)
  }

  // Disable device mapping save button
  disableSaveButton(id) {
    this.setSaveButtonEnabled(id, false)
  }

  // Select device model for device mapping
  setModel(id, model_id) {
    const model_select = this.mappingContainer(id).find('.devices-mapper-model-select')
    model_select.select2('val', model_id)
  }

  // Submit device mapping form
  submit(id) {
    this.disableSaveButton(id)
    this.mappingContainer(id).find('form').submit()
  }

  // Show new device model modal window
  new_device_modal(id) {
    const device_name = this.mappingContainer(id).find('.devices-mapping-name-column').text()
    $('#device_model_name').val(device_name)
    $('#device_model_device_make_id').val('')
    $('#device_model_mapping_id').val(id)
    $('#new-device-modal').find('.errors').addClass('hide').text('')
    $('#new-device-modal').modal('show')
  }

  // Fill select2 selects with device models
  initSelects() {
    const selects = this.$container.find('.devices-mapper-model-select')
    selects.select2({ data: this.models_data, allowClear: true, placeholder: ' ' })
  }

  // Bind events handlers
  bindEvents() {
    // Save device mapping
    $(document).on('click', '.devices-mapper-save-button', (event) => {
      event.preventDefault()
      const element = event.currentTarget
      this.submit(this.mappingId(element))
    })

    // Set device model input value from clicked link
    $(document).on('click', '.devices-mapper-set-model-link', (event) => {
      event.preventDefault()
      const element = event.currentTarget
      const model_id = $(element).data('model-id')
      this.setModel(this.mappingId(element), model_id)
    })

    // Show new device form
    $(document).on('click', '.devices-mapper-new-device', (event) => {
      event.preventDefault()
      const element = event.currentTarget
      this.new_device_modal(this.mappingId(element))
    })

    // Process device mapping update events
    $(document)
      .on('ajax:success', '.device-model-mapping-form', (event) => {
        const element = event.currentTarget
        $(element).find('.errors').addClass('hide').text('')
        this.enableSaveButton(this.mappingId(element))
      })
      .on('ajax:error', '.device-model-mapping-form', (event, xhr) => {
        const element = event.currentTarget
        const errors = xhr.responseJSON.errors
        $(element).find('.errors').removeClass('hide').text(errors)
        this.enableSaveButton(this.mappingId(element))
      })

    // Process new device create events
    $(document)
      .on('ajax:success', '.device-mapping-new-device-form', (event, data) => {
        const mapping_id = $('#device_model_mapping_id').val()
        // Push created model to @models_data array for selects
        const new_model = { id: data.device_model.id, text: data.device_model.make_and_model }
        this.models_data.push(new_model)
        // Set model id of the current mapping
        this.setModel(mapping_id, new_model.id)
        // Save current mapping
        this.submit(mapping_id)
        // Close modal
        $('#new-device-modal').modal('hide')
      })
      .on('ajax:error', '.device-mapping-new-device-form', (event, xhr) => {
        const element = event.currentTarget
        const errors = xhr.responseJSON.errors
        $(element).find('.errors').removeClass('hide').text(errors)
      })
  }
}
