$(() => {
  $('@api-ticket-completed-button').click((e) => {
    $('@api-ticket-status').val('completed')
    $(e.target).closest('form').submit()
  })

  $('@api-ticket-cancelled-button').click((e) => {
    $('@api-ticket-status').val('cancelled')
    $(e.target).closest('form').submit()
  })
})
