import { api } from '~/helpers/api'
import { wireline_bill_parsers_path } from '~/helpers/rails-routes'
import { showError } from '~/helpers/notifications'

$(() => {
  const el = document.querySelector('.wireline-bill-parsers-fileupload')
  if (!el) return

  const inputEl = el.querySelector('input[type="file"]')
  const fileslistEl = el.querySelector('.fileslist')
  let selectedFiles = []
  inputEl.addEventListener('change', uploadFiles)

  async function uploadFiles(e) {
    selectedFiles = Array.from(e.target.files)
    if (!selectedFiles.length) return
    inputEl.closest('label').classList.add('is-hidden')
    fileslistEl.classList.remove('is-hidden')
    renderFilesList()
    try {
      // 2 independant arrays as we use splice in the loop
      for (const file of [...selectedFiles]) {
        await uploadFile(file)
        selectedFiles.splice(selectedFiles.indexOf(file), 1)
        renderFilesList()
      }
      alert('[SUCCESS] All files uploaded. Reloading the page')
      location.reload()
    } catch (e) {
      showError(e)
    }
  }

  async function uploadFile(file) {
    const formData = new FormData()
    formData.append('file[0]', file)
    await api.post(wireline_bill_parsers_path(), formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  }

  function renderFilesList() {
    fileslistEl.innerHTML = ''
    let html = ''
    selectedFiles.forEach((file) => (html += `<span class='tag mr-2 mb-2'>${file.name}</span>`))
    html += '<div class="pure-spinner mt-4"></div>'
    fileslistEl.insertAdjacentHTML('afterbegin', html)
  }
})
