import { DatatableLib } from '~/old/elements/datatable'
import { table_data_customer_reports_data_behavior_path } from '~/helpers/rails-routes'

$(() => new DataBehaviorReportShow($('@dataBehaviorReport')))

class DataBehaviorReportShow {
  /**
   *
   * @param {JQuery} $container
   */
  constructor($container) {
    if ($container.length === 0) return
    this.has_employees = $container.data('has-employees')
    this.atypes = $container.data('atypes')
    const customer_id = $container.data('customer')
    const cycle_id = $container.data('cycle')

    if (customer_id && cycle_id) {
      const table = $('@devices_table')
      const path = table_data_customer_reports_data_behavior_path(customer_id, cycle_id, {
        type: table.data('type'),
      })
      new DatatableLib(table, this.datatable_options(path))
    }
  }

  datatable_options(data_provider_url) {
    let columns = [{ data: 'device_number' }, { data: 'device_username' }, { data: 'device_make' }]
    if (this.has_employees) {
      columns.push({ data: 'employee_status' })
    }
    this.atypes.forEach((at) => columns.push({ data: `acc_${at.id}` }))
    columns = columns.concat([
      { data: 'total', className: 'dt-right nowrap', type: 'numeric-file-size' },
      { data: 'daytime_percentage', className: 'dt-right' },
      { data: 'evening_percentage', className: 'dt-right' },
      { data: 'weekend_percentage', className: 'dt-right' },
      { data: 'ratio', className: 'dt-right' },
    ])

    return {
      ajax: data_provider_url,
      sort: true,
      scrollX: true,
      order: [[columns.length - 5, 'desc']],
      iDisplayLength: 50,
      columns: columns,
    }
  }
}
