$(() => {
  $.fn.overlayProgressBar = function () {
    const progressbars_layout = (rows, value) => {
      const $ul = $(document.createElement('ul')).attr('class', 'overlay-progress-bar')
      let row_index = 0
      while (row_index < rows) {
        let width = rows * value - row_index * 100
        if (width < 0) width = 0
        if (width > 100) width = 100
        row_index++
        $ul.prepend($(document.createElement('li')).attr('style', `width: ${width}%`))
      }
    }

    this.each(function () {
      const rows = parseInt($(this).data('rows')) || 1
      const value_inverse = 100 - parseFloat($(this).data('valuenow'))
      $(this).append(progressbars_layout(rows, value_inverse)).css('position', 'relative')
    })
  }

  // semi-transparent overlays progress bar that might have several lines
  // Example:
  // %picture{ 'role': 'overlay-progress', data: { 'valuenow': '12', 'rows': 2 } }
  // valuenow -- current progress value in percentage
  // rows -- number of lines (default: 1)
  $('@overlay-progress').overlayProgressBar()
})
