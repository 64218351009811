import { isEmpty } from 'lodash-es'

export class DeviceManagersSearch {
  constructor(element) {
    this.element = element
  }

  activate() {
    const $input = $(this.element)

    $input.select2({
      placeholder: $input.placeholder,
      minimumInputLength: 3,
      allowClear: false,
      ajax: {
        url: $input.data('action'),
        dataType: 'json',
        delay: 250,
        cache: true,
        data: (params) => ({ q: params }),
        results: (data) => ({ results: data.items }),
      },
      formatResult: (manager) => {
        const employee_number = isEmpty(manager.employee_number) ? '' : manager.employee_number
        return `${manager.name} (${employee_number})`
      },
      formatSelection: (manager) => {
        const employee_number = isEmpty(manager.employee_number) ? '' : manager.employee_number
        return `${manager.name} (${employee_number})`
      },
    })

    $input.select2('data', $input.data('selected'))
  }
}
