import { DatatableLib } from '~/old/elements/datatable'
import {
  helpdesk_device_status_update_requests_path,
  confirm_customer_device_status_update_request_path,
  resend_customer_device_status_update_request_path,
  edit_customer_device_status_update_request_path,
  customer_device_path,
} from '~/helpers/rails-routes'

$(() => new HelpdeskDeviceStatusUpdateRequests($('@deviceStatusUpdateRequestsContainer')))

class HelpdeskDeviceStatusUpdateRequests {
  /**
   *
   * @param {JQuery} $container
   */
  constructor($container) {
    const current_state = $container.find('li.active @currentState').text()
    const path = helpdesk_device_status_update_requests_path({ state: current_state })
    new DatatableLib($('@deviceStatusUpdateRequestsDatatable'), this.datatable_options(path))
  }

  datatable_options(data_provider_url) {
    return {
      ajax: {
        url: data_provider_url,
        type: 'POST',
      },
      iDisplayLength: 25,
      bProcessing: true,
      bServerSide: true,
      columns: [
        { data: 'customer_name' },
        { data: 'device_link_data', className: 'dt-right', render: this.device_link_fn.bind(this) },
        { data: 'new_status', className: 'dt-right' },
        { data: 'new_username', className: 'dt-right' },
        { data: 'need_request_to_carrier', className: 'dt-right' },
        { data: 'need_transfer', className: 'dt-right' },
        { data: 'cycle_date_data', className: 'dt-right' },
        {
          data: 'action_links_data',
          className: 'dt-center',
          render: this.action_links_fn.bind(this),
        },
      ],
    }
  }

  device_link_fn(data) {
    let html = ''
    if (data.device_id) {
      const url = customer_device_path(data.customer_id, data.device_id)
      html += '<a href="' + url + '">' + data.device_username
      html += '<br>' + data.device_number + '</a>'
    } else {
      html += 'No device found'
    }
    return html
  }

  action_links_fn(data) {
    if (!data.actions_ability) return ''

    let html = ''
    const confirmation_link = confirm_customer_device_status_update_request_path(
      data.customer_id,
      data.uuid
    )
    html += '<a class="btn btn-success btn-xs btn-block" role="sweetconfirm" '
    html += ' data-request-uuid="' + data.uuid + '"'
    html += ' data-confirm="Your request is auto-confirmed via device import or next bill import,'
    html += ' if you\'ve received confirmation from the carrier please go ahead and hit confirm" '
    html += ' data-ajax=false'
    html += ' href="' + confirmation_link + '">Confirm</a>'

    if (data.resend_ability) {
      const resend_link = resend_customer_device_status_update_request_path(
        data.customer_id,
        data.uuid
      )
      html += '<a class="btn btn-primary btn-xs btn-block" method="POST" '
      html += 'href="' + resend_link + '">Resend</a>'
    }

    const edit_link = edit_customer_device_status_update_request_path(data.customer_id, data.uuid)
    html += '<a class="btn btn-danger btn-xs btn-block" href="' + edit_link + '">Cancel</a>'

    return html
  }
}
