import angular from 'angular'
import 'angular-simple-logger/dist/index'
import 'checklist-model'
import 'ng-mask/dist/ngMask'
import { locale } from '~/helpers/use-i18n'

// Application components configuration

// Angualr initialization
angular.module('argon.hardwareOrdering', [])
const ArgonModule = angular.module('ArgonModule', [
  'checklist-model',
  'ngMask',
  'argon.hardwareOrdering',
  'nemLogging',
])

ArgonModule.config([
  '$compileProvider',
  function ($compileProvider) {
    // https://docs.angularjs.org/guide/production#disabling-debug-data
    // run angular.reloadWithDebugInfo() to enable debugging
    $compileProvider.debugInfoEnabled(false)
  },
])

const relativeTimeFormatter = new Intl.RelativeTimeFormat(locale)
const DIVISIONS = [
  { amount: 60, name: 'seconds' },
  { amount: 60, name: 'minutes' },
  { amount: 24, name: 'hours' },
  { amount: 7, name: 'days' },
  { amount: 4.34524, name: 'weeks' },
  { amount: 12, name: 'months' },
  { amount: Number.POSITIVE_INFINITY, name: 'years' },
]
const timeAgoInWords = (time) => {
  let duration = (new Date(time) - new Date()) / 1000
  for (let i = 0; i < DIVISIONS.length; i++) {
    let division = DIVISIONS[i]
    if (!division) continue
    if (Math.abs(duration) < division.amount) {
      return relativeTimeFormatter.format(Math.round(duration), division.name)
    }
    duration /= division.amount
  }
}

ArgonModule.filter('timeAgoInWords', function () {
  return function (time) {
    return timeAgoInWords(time)
  }
})

ArgonModule.filter('toCssClass', function () {
  return function (string) {
    return String(string).toLowerCase().replace(/\s/g, '')
  }
})

// UI components initialization
$(document).ready(() => {
  $('[role=tooltip]').tooltip()

  // TODO:
  // This should be moved to initializeUIComponents()
  // but we call the function too often for full document instead only new elements
  // so I start do that manually in remote_partial.js for now
  // before we will refactore this behaviuor
  $(document).trigger('init.argon.datepickers')
  $(document).trigger('init.argon.selects')

  $('[role=editableSelect2]').each(function () {
    const $input = $(this)
    const options = $input.data('select') ?? {}
    const editableSelectOptions = {
      multiple: false,
      data: JSON.parse($input.attr('data-values')),
      createSearchChoice: function (term, data) {
        if (
          $(data).filter(function () {
            return this.text.localeCompare(term) === 0
          }).length === 0
        ) {
          return { id: term, text: term }
        }
      },
    }

    $input.select2(Object.assign(editableSelectOptions, options))
    // fix for select2 html5 validation
    // tried to add a class, but it didn't help, style attr works good
    $input.attr(
      'style',
      `display: block !important; width: 0px !important;
      height: 0px !important; border-color: transparent !important;
      margin-top: 0px !important; margin-bottom: 0px !important;
      z-index: -1 !important; padding: 0px !important;`
    )
  })

  initializeUIComponents()
})

// Event listeners initialization

// Prevent clicking disabled links
$(document).on('click', '.disabled a', (event) => event.preventDefault())
$(document).on('click', 'a[disabled]', (event) => event.preventDefault())
$(document).on('mousewheel', ':input[type=number]', (event) => event.preventDefault())

// Bootstrap Tabs
$(document).on('shown.bs.tab', 'a[data-toggle="tab"]', (e) => {
  const target = $(e.target)
  const tab = $(target.attr('href'))
  return tab.find('svg').each(function () {
    return $(this).parent().data('chart') && $(this).parent().data('chart').update()
  })
})

$(document).on('shown.bs.tab', 'a[data-toggle="tab"]', () => initializeUIComponents())

// DataTables
$(document).on('draw.dt', () => initializeUIComponents())

$(document).on('click', '[role=ajax_link]', function (event) {
  event.preventDefault()
  $.get($(this).data('href')).done(() => alert('Successfully sent request'))
})

$(document).on('show.bs.modal', () => $('[role=popover]').popover('hide'))

// Close currently playing videos upon closing modals
$(document).on('hide.bs.modal', () => {
  const $iframe = $('.modal:visible iframe')
  const video_source = $iframe.attr('src')
  $iframe.attr('src', '')
  $iframe.attr('src', video_source)
})

// TODO:
// There is a problem here - it initialize ui components on each ajax request for full document
// not only new html.
$(document).on('ajaxComplete ajax:replaced', () => initializeUIComponents())

// Changing bill summary via clicking by link without relading
const links = `a[role=device-bill-modal-load],
         #device-modal-bill @bill-monthly-summary-form a[role=submit-button],
         .report @bill-monthly-summary-form a[role=submit-button]`
$(document).on('click', links, function (e) {
  e.preventDefault()

  const url = $(this).attr('href')
  $('[role=bill-monthly-summary-form]').parent().load(url, { partial: true })
})

// Modal events for device bill summary modal window
$(document).on('hidden.bs.modal', '.modal', function () {
  $(this).removeData('bs.modal')
})

$(document).on('hide.bs.modal', '.modal', function (e) {
  if (!$(this).data('bs.modal')) return
  if ($(this).data('bs.modal').preventDefault) {
    e.preventDefault()
  }
})

// Function defenitions

// Initialize datatable
window.activateDataTable = (element) => $(element).trigger('init.argon.datatable')

// Reinitializing UI components
const initializeUIComponents = () => {
  const eventName = 'init.argon.ui'
  // jQuery listeners
  $(document).trigger(eventName)
  // native listeners
  document.dispatchEvent(new CustomEvent(eventName))
}
