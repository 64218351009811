jQuery(function ($) {
  $('.ujs').each(function () {
    var parent_table = $(this)

    if (parent_table.find('.select-column').length > 0) {
      parent_table.on('click', '.select-column', function () {
        var $this = $(this)
        var column_index = $this.parents('th').index() + 1
        parent_table
          .find('tr td:nth-child(' + column_index + ') input:checkbox')
          .prop('checked', $this.is(':checked'))
      })

      parent_table.find('.select-column').each(function () {
        var $this = $(this)
        var column_index = $this.parents('th').index() + 1
        var selector = 'tr td:nth-child(' + column_index + ') input:checkbox'
        parent_table.on('change', selector, function () {
          // If any of the row checkboxes are unchecked, we don't want the top one checked
          $this.prop('checked', !parent_table.find(selector).is(':not(:checked)'))
        })
      })
    }
  })
})
