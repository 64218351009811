import { map } from 'lodash-es'

export class CanadianCitiesSearch {
  constructor(element) {
    this.element = $(element)
    this.action = this.element.data('action')
  }

  activate() {
    this.element.select2({
      minimumInputLength: 3,
      allowClear: true,
      url: this.action,
      ajax: {
        url: this.action,
        dataType: 'json',
        delay: 250,
        cache: true,
        data: (text) => ({ q: { bell_api_city_name_cont: text } }),
        results: (data) => {
          const options = map(data.cities, (city) => this.formatSearchResultToOption(city))
          return { results: options }
        },
      },
      initSelection: (element, callback) => {
        const cityId = element.val()
        if (!cityId) return

        callback({ id: cityId, text: element.data('city-with-province') })
      },
    })
  }

  chooseOption(id) {
    // what if I need to clear?
    if (!id) return
    const url = `${this.action}/${id}`
    return $.getJSON(url).then((city) =>
      this.element.select2('data', this.formatSearchResultToOption(city))
    )
  }

  formatSearchResultToOption(result) {
    return {
      id: result.id,
      text: result.city_with_province,
    }
  }
}
