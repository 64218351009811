import { defineMessages, useI18n } from '~/helpers/use-i18n'

const messages = defineMessages({
  en: {
    exportToCsv: 'Export to CSV',
  },
  'fr-CA': {
    exportToCsv: 'Exporter au format CSV',
  },
})

const { t } = useI18n({ messages })

$(() => {
  $('@executive_summary_datatable').dataTable({
    dom: 'Bfrtip',
    buttons: {
      dom: {
        button: {
          className: 'btn btn-sm btn-default margin-top-15',
        },
        buttonLiner: {
          // prevent button's text wrapping within span tag
          tag: '',
        },
      },
      buttons: [
        {
          extend: 'csv',
          text: `<i class="fa fa-file-excel-o"></i> ${t('exportToCsv')}`,
        },
      ],
    },
  })
})
