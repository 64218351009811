export const ownershipTypes = () => [
  { value: 0, title: 'Corporate Owned' },
  { value: 1, title: 'Employee Owned' },
  { value: 2, title: 'Corporate Shared' },
  { value: 3, title: 'Provide Later' },
]

export const countryCodes = () => [
  { value: -1, title: 'Select code' },
  { value: 43, title: 'Afghanistan(93)' },
  { value: 132, title: 'Albania(355)' },
  { value: 70, title: 'Algeria(213)' },
  { value: 189, title: 'American Samoa(684)' },
  { value: 143, title: 'Andorra(376)' },
  { value: 97, title: 'Angola(244)' },
  { value: 47, title: 'Anguilla(1264)' },
  { value: 48, title: 'Antigua(1268)' },
  { value: 24, title: 'Argentina(54)' },
  { value: 141, title: 'Armenia(374)' },
  { value: 124, title: 'Aruba(297)' },
  { value: 100, title: 'Ascension Island(247)' },
  { value: 30, title: 'Australia(61)' },
  { value: 14, title: 'Austria(43)' },
  { value: 230, title: 'Azerbaijan(994)' },
  { value: 49, title: 'Bahamas(1242)' },
  { value: 224, title: 'Bahrain(973)' },
  { value: 211, title: 'Bangladesh(880)' },
  { value: 50, title: 'Barbados(1246)' },
  { value: 64, title: 'Barbuda(1268)' },
  { value: 142, title: 'Belarus(375)' },
  { value: 7, title: 'Belgium(32)' },
  { value: 157, title: 'Belize(501)' },
  { value: 82, title: 'Benin(229)' },
  { value: 51, title: 'Bermuda(1441)' },
  { value: 226, title: 'Bhutan(975)' },
  { value: 167, title: 'Bolivia(591)' },
  { value: 151, title: 'Bosnia and Herzegovina(387)' },
  { value: 119, title: 'Botswana(267)' },
  { value: 25, title: 'Brazil(55)' },
  { value: 52, title: 'British Virgin Islands(1284)' },
  { value: 178, title: 'Brunei(673)' },
  { value: 136, title: 'Bulgaria(359)' },
  { value: 79, title: 'Burkina Faso(226)' },
  { value: 110, title: 'Burundi(257)' },
  { value: 204, title: 'Cambodia(855)' },
  { value: 90, title: 'Cameroon(237)' },
  { value: 53, title: 'Canada(1)' },
  { value: 91, title: 'Cape Verde Islands(238)' },
  { value: 54, title: 'Cayman Islands(1345)' },
  { value: 89, title: 'Central African Republic(236)' },
  { value: 88, title: 'Chad(235)' },
  { value: 26, title: 'Chile(56)' },
  { value: 39, title: 'China(86)' },
  { value: 177, title: 'Christmas Island(672)' },
  { value: 237, title: 'Cocos-Keeling Islands(891)' },
  { value: 27, title: 'Colombia(57)' },
  { value: 234, title: 'Comoros(269)' },
  { value: 95, title: 'Congo(242)' },
  { value: 96, title: 'Congo, Democratic Republic of the(243)' },
  { value: 187, title: 'Cook Islands(682)' },
  { value: 162, title: 'Costa Rica(506)' },
  { value: 78, title: 'Cote dIvoire(225)' },
  { value: 149, title: 'Croatia(385)' },
  { value: 23, title: 'Cuba(53)' },
  { value: 134, title: 'Cyprus(357)' },
  { value: 153, title: 'Czech Republic(420)' },
  { value: 16, title: 'Denmark(45)' },
  { value: 99, title: 'Diego Garcia(246)' },
  { value: 106, title: 'Djibouti(253)' },
  { value: 55, title: 'Dominica(1767)' },
  { value: 56, title: 'Dominican Republic(1809)' },
  { value: 169, title: 'Ecuador(593)' },
  { value: 3, title: 'Egypt(20)' },
  { value: 159, title: 'El Salvador(503)' },
  { value: 93, title: 'Equatorial Guinea(240)' },
  { value: 123, title: 'Eritrea(291)' },
  { value: 139, title: 'Estonia(372)' },
  { value: 104, title: 'Ethiopia(251)' },
  { value: 152, title: 'F.Y.R.O.M.(389)' },
  { value: 156, title: 'Falkland Islands(500)' },
  { value: 125, title: 'Faroe Islands(298)' },
  { value: 184, title: 'Fiji Islands(679)' },
  { value: 135, title: 'Finland(358)' },
  { value: 8, title: 'France(33)' },
  { value: 236, title: 'French Antilles(596)' },
  { value: 170, title: 'French Guiana(594)' },
  { value: 194, title: 'French Polynesia(689)' },
  { value: 94, title: 'Gabon(241)' },
  { value: 73, title: 'Gambia(220)' },
  { value: 231, title: 'Georgia(995)' },
  { value: 20, title: 'Germany(49)' },
  { value: 86, title: 'Ghana(233)' },
  { value: 127, title: 'Gibraltar(350)' },
  { value: 5, title: 'Greece(30)' },
  { value: 126, title: 'Greenland(299)' },
  { value: 57, title: 'Grenada(1473)' },
  { value: 166, title: 'Guadeloupe(590)' },
  { value: 68, title: 'Guam(1671)' },
  { value: 235, title: 'Guantanamo Bay(5399)' },
  { value: 158, title: 'Guatemala(502)' },
  { value: 77, title: 'Guinea(224)' },
  { value: 98, title: 'Guinea-Bissau(245)' },
  { value: 168, title: 'Guyana(592)' },
  { value: 165, title: 'Haiti(509)' },
  { value: 160, title: 'Honduras(504)' },
  { value: 202, title: 'Hong Kong S.A.R.(852)' },
  { value: 10, title: 'Hungary(36)' },
  { value: 206, title: 'INMARSAT(870)' },
  { value: 207, title: 'INMARSAT (Atlantic-East)(871)' },
  { value: 210, title: 'INMARSAT (Atlantic-West)(874)' },
  { value: 209, title: 'INMARSAT (Indian)(873)' },
  { value: 208, title: 'INMARSAT (Pacific)(872)' },
  { value: 131, title: 'Iceland(354)' },
  { value: 41, title: 'India(91)' },
  { value: 31, title: 'Indonesia(62)' },
  { value: 200, title: 'International Freephone Service(800)' },
  { value: 46, title: 'Iran(98)' },
  { value: 217, title: 'Iraq(964)' },
  { value: 130, title: 'Ireland(353)' },
  { value: 223, title: 'Israel(972)' },
  { value: 11, title: 'Italy(39)' },
  { value: 58, title: 'Jamaica(1876)' },
  { value: 36, title: 'Japan(81)' },
  { value: 215, title: 'Jordan(962)' },
  { value: 198, title: 'Kazakhstan(7)' },
  { value: 107, title: 'Kenya(254)' },
  { value: 191, title: 'Kiribati(686)' },
  { value: 201, title: "Korea, Democratic People's Republic of(850)" },
  { value: 37, title: 'Korea, Republic of(82)' },
  { value: 218, title: 'Kuwait(965)' },
  { value: 232, title: 'Kyrgyzstan(996)' },
  { value: 205, title: 'Laos(856)' },
  { value: 138, title: 'Latvia(371)' },
  { value: 214, title: 'Lebanon(961)' },
  { value: 118, title: 'Lesotho(266)' },
  { value: 84, title: 'Liberia(231)' },
  { value: 72, title: 'Libya(218)' },
  { value: 155, title: 'Liechtenstein(423)' },
  { value: 137, title: 'Lithuania(370)' },
  { value: 129, title: 'Luxembourg(352)' },
  { value: 203, title: 'Macau S.A.R.(853)' },
  { value: 241, title: 'Macedonia(389)' },
  { value: 113, title: 'Madagascar(261)' },
  { value: 117, title: 'Malawi(265)' },
  { value: 29, title: 'Malaysia(60)' },
  { value: 213, title: 'Maldives(960)' },
  { value: 76, title: 'Mali(223)' },
  { value: 133, title: 'Malta(356)' },
  { value: 197, title: 'Marshall Islands(692)' },
  { value: 172, title: 'Martinique(596)' },
  { value: 75, title: 'Mauritania(222)' },
  { value: 83, title: 'Mauritius(230)' },
  { value: 121, title: 'Mayotte Island(262)' },
  { value: 22, title: 'Mexico(52)' },
  { value: 196, title: 'Micronesia, Federated States of(691)' },
  { value: 140, title: 'Moldova(373)' },
  { value: 144, title: 'Monaco(377)' },
  { value: 227, title: 'Mongolia(976)' },
  { value: 59, title: 'Montserrat(1664)' },
  { value: 69, title: 'Morocco(212)' },
  { value: 111, title: 'Mozambique(258)' },
  { value: 45, title: 'Myanmar(95)' },
  { value: 116, title: 'Namibia(264)' },
  { value: 179, title: 'Nauru(674)' },
  { value: 228, title: 'Nepal(977)' },
  { value: 6, title: 'Netherlands(31)' },
  { value: 175, title: 'Netherlands Antilles(599)' },
  { value: 192, title: 'New Caledonia(687)' },
  { value: 33, title: 'New Zealand(64)' },
  { value: 161, title: 'Nicaragua(505)' },
  { value: 80, title: 'Niger(227)' },
  { value: 87, title: 'Nigeria(234)' },
  { value: 188, title: 'Niue(683)' },
  { value: 240, title: 'Norfolk Island(672)' },
  { value: 18, title: 'Norway(47)' },
  { value: 221, title: 'Oman(968)' },
  { value: 42, title: 'Pakistan(92)' },
  { value: 185, title: 'Palau(680)' },
  { value: 163, title: 'Panama(507)' },
  { value: 180, title: 'Papua New Guinea(675)' },
  { value: 171, title: 'Paraguay(595)' },
  { value: 21, title: 'Peru(51)' },
  { value: 32, title: 'Philippines(63)' },
  { value: 19, title: 'Poland(48)' },
  { value: 128, title: 'Portugal(351)' },
  { value: 65, title: 'Puerto Rico(1)' },
  { value: 225, title: 'Qatar(974)' },
  { value: 114, title: 'Reunion Island(262)' },
  { value: 12, title: 'Romania(40)' },
  { value: 238, title: 'Rota Island(670)' },
  { value: 2, title: 'Russia(7)' },
  { value: 103, title: 'Rwanda(250)' },
  { value: 66, title: 'Saint Lucia(1758)' },
  { value: 176, title: 'Saipan Island(670)' },
  { value: 190, title: 'Samoa(685)' },
  { value: 145, title: 'San Marino(378)' },
  { value: 92, title: 'Sao Tome and Principe(239)' },
  { value: 219, title: 'Saudi Arabia(966)' },
  { value: 74, title: 'Senegal Republic(221)' },
  { value: 148, title: 'Serbia(381)' },
  { value: 101, title: 'Seychelles(248)' },
  { value: 85, title: 'Sierra Leone(232)' },
  { value: 34, title: 'Singapore(65)' },
  { value: 154, title: 'Slovakia(421)' },
  { value: 150, title: 'Slovenia(386)' },
  { value: 182, title: 'Solomon Islands(677)' },
  { value: 105, title: 'Somalia(252)' },
  { value: 4, title: 'South Africa(27)' },
  { value: 9, title: 'Spain(34)' },
  { value: 44, title: 'Sri Lanka(94)' },
  { value: 122, title: 'St. Helena(290)' },
  { value: 60, title: 'St. Kitts and Nevis(1869)' },
  { value: 164, title: 'St. Pierre and Miquelon(508)' },
  { value: 61, title: 'St. Vincent and the Grenadines(1784)' },
  { value: 102, title: 'Sudan(249)' },
  { value: 173, title: 'Suriname(597)' },
  { value: 120, title: 'Swaziland(268)' },
  { value: 17, title: 'Sweden(46)' },
  { value: 13, title: 'Switzerland(41)' },
  { value: 216, title: 'Syria(963)' },
  { value: 212, title: 'Taiwan(886)' },
  { value: 199, title: 'Tajikistan(992)' },
  { value: 108, title: 'Tanzania(255)' },
  { value: 35, title: 'Thailand(66)' },
  { value: 239, title: 'Tinian Island(670)' },
  { value: 81, title: 'Togo(228)' },
  { value: 195, title: 'Tokelau(690)' },
  { value: 181, title: 'Tonga(676)' },
  { value: 62, title: 'Trinidad and Tobago(1868)' },
  { value: 71, title: 'Tunisia(216)' },
  { value: 40, title: 'Turkey(90)' },
  { value: 229, title: 'Turkmenistan(993)' },
  { value: 63, title: 'Turks and Caicos Islands(1649)' },
  { value: 193, title: 'Tuvalu(688)' },
  { value: 109, title: 'Uganda(256)' },
  { value: 147, title: 'Ukraine(380)' },
  { value: 222, title: 'United Arab Emirates(971)' },
  { value: 15, title: 'United Kingdom(44)' },
  { value: 67, title: 'United States Virgin Islands(1340)' },
  { value: 1, title: 'United States of America(1)' },
  { value: 174, title: 'Uruguay(598)' },
  { value: 233, title: 'Uzbekistan(998)' },
  { value: 183, title: 'Vanuatu(678)' },
  { value: 146, title: 'Vatican City(379)' },
  { value: 28, title: 'Venezuela(58)' },
  { value: 38, title: 'Vietnam(84)' },
  { value: 186, title: 'Wallis and Futuna Islands(681)' },
  { value: 220, title: 'Yemen(967)' },
  { value: 112, title: 'Zambia(260)' },
  { value: 115, title: 'Zimbabwe(263)' },
]
