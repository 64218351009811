import angular from 'angular'
import { customer_hardware_orders_path, hardware_order_path } from '~/helpers/rails-routes'

class HardwareOrderLoader {
  get(url) {
    return $.getJSON(url)
  }

  send(order) {
    const options = {}
    options.customer_id = order.customer_id
    if (order.uuid) options.id = order.uuid

    let path = customer_hardware_orders_path(options)
    let method = 'POST'

    if (options.id) {
      path = hardware_order_path(options)
      method = 'PATCH'
    }

    return $.ajax({
      url: path,
      method: method,
      dataType: 'json',
      contentType: 'application/json',
      data: JSON.stringify({ hardware_order: order.attributes() }),
    })
  }
}

angular.module('argon.hardwareOrdering').service('$hwHardwareOrderLoader', HardwareOrderLoader)
