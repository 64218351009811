$(() => {
  $('@import_upload_csv').on('change', (e) => {
    $(e.target).closest('form').submit()
  })

  if ($('#ierarchical_managers').length) {
    const $table = $('#ierarchical_managers')
    $table.treetable({
      expandable: true,
      indent: 25,
      initialState: 'collapsed',
    })
  }
})
