import swal from 'sweetalert'
import angular from 'angular'
import { customer_business_contact_change_locale_path } from '~/helpers/rails-routes'
import { locale, defineMessages, useI18n } from '~/helpers/use-i18n'

const messages = defineMessages({
  en: {
    confirmBtnText: 'Yes, save my preference',
    cancelBtnText: 'No, just this time',
    message: 'Do you want reports for {manager_name} to be always in English?',
  },
  'fr-CA': {
    confirmBtnText: 'Oui, sauvegardez ma préférence',
    cancelBtnText: 'Non, seulement cette fois-ci',
    message:
      'Désirez-vous que les rapports pour {manager_name} soient toujours obtenus en français?',
  },
  es: {
    confirmBtnText: 'Sí, guarda mi preferencia',
    cancelBtnText: 'No, solo esta vez',
    message: '¿Quieres informes para que {manager_name} esté siempre en español?',
  },
})

const { t } = useI18n({ messages })

$(() => {
  const $container = $('@managerialReport')
  if ($container.length) {
    new SecureManagerialReportShow($container)
  }
})

class SecureManagerialReportShow {
  /**
   *
   * @param {JQuery} $container
   */
  constructor($container) {
    this.$container = $container

    const manager_name = this.$container
      .find('@managerName')
      .text()
      .replace(/ /g, String.fromCharCode(160))
    const manager_locale = this.$container.data('managerlocale')

    if (manager_locale && manager_locale !== locale) {
      swal({
        title: t('message').replace('{manager_name}', manager_name),
        buttons: [t('cancelBtnText'), t('confirmBtnText')],
      }).then((isConfirm) => this.onLocaleChangeConfirm(isConfirm))
    }

    // initialize js for dispute form
    $(document).on('managerial_report:dispute_form_loaded', () => {
      $('@businessUnitForm').each((i, element) => {
        const $element = $(element)
        if ($element.data('initialized') !== true) {
          $element.data('initialized', true)
          angular
            .element($element)
            .injector()
            .invoke([
              '$rootScope',
              '$compile',
              ($rootScope, $compile) => {
                $compile($element)($rootScope.$new())
                $rootScope.$digest()
              },
            ])
        }
      })
    })
  }

  onLocaleChangeConfirm(isConfirm) {
    if (!isConfirm) return
    const customer_id = this.$container.data('customer')
    const contact_id = this.$container.data('contact')
    if (!(contact_id && customer_id)) return
    $.ajax({
      url: customer_business_contact_change_locale_path(customer_id, contact_id),
      dataType: 'json',
      type: 'patch',
      data: {
        authenticity_token: $('meta[name="csrf-token"]').attr('content'),
        contact: {
          locale,
        },
      },
      beforeSend: (xhr) =>
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')),
    })
  }
}
