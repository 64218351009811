import swal from 'sweetalert'

$(() => {
  const $invoiceButton = $('@accounting-invoice-button')
  const $invoiceModal = $('#accounting-invoice-modal')

  const $invoiceForm = $invoiceModal.find('form')
  const $invoiceSubmit = $invoiceModal.find('button')
  const $invoiceNumber = $invoiceModal.find('#accounting-invoice-number')

  $invoiceButton.click(function () {
    $invoiceModal.modal('show')
    const invoiceUrl = $(this).attr('data-url')
    $invoiceForm.attr('action', invoiceUrl)
    $invoiceNumber.val('')
  })

  $invoiceSubmit.click(() => $invoiceModal.modal('hide'))

  $('@accounting-report-push-button').on('confirm:complete', function (e, confirmed) {
    if (!confirmed) return
    e.target.disabled = true
    $.ajax({
      type: 'POST',
      url: $(this).attr('data-url'),
      data: {
        billing_cycle_id: $(this).attr('data-cycle-id'),
        billing_data_id: $(this).attr('data-data-id'),
      },
      success: () => swal({ title: 'Success', text: 'Accounting report is sent', icon: 'success' }),
      error: () =>
        swal({ title: 'Failure', text: "Accounting report can't be sent", icon: 'error' }),
    })
  })
})
