import angular from 'angular'

function controller($scope, $searchFilters) {
  const ctrl = this

  ctrl.filters = $searchFilters

  const watchers = {
    tab: (nV, oV) => {
      // I need display accessories tab for existing accessory only items (on edit).
      if (ctrl.filters.isAccessoriesTab()) ctrl.filters.enableAccessoriesMode()

      if (nV && oV && nV != oV) ctrl.filters.clear()
    },
    mode: (nV) => {
      if (!nV) ctrl.filters.enableProductsMode()
    },
    carrier: (nV) => {
      if (nV) ctrl.filters.carrier_id = nV
    },
  }

  $scope.ctrl = ctrl
  $scope.$watch('ctrl.filters.mode', watchers.mode)
  $scope.$watch('ctrl.filters.tab', watchers.tab)
  $scope.$watch('ctrl.filters.required_carrier_id', watchers.carrier)

  ctrl.enableProductsMode = () => ctrl.filters.enableProductsMode()
  ctrl.enableAccessoriesMode = () => ctrl.filters.enableAccessoriesMode()

  return ctrl
}

controller.$inject = ['$scope', '$hwSearchFilters']

angular.module('argon.hardwareOrdering').controller('hwSearchFiltersCtrl', controller)
