import angular from 'angular'
import { customer_orderable_devices_path } from '~/helpers/rails-routes'

function controller($scope, $searchFilters) {
  const ctrl = this

  ctrl.filters = $searchFilters
  ctrl.urlForDevices = null

  const filtersWatcher = () => {
    if (ctrl.filters.isDevicesMode()) {
      ctrl.loadDevices()
    } else {
      // hide template
      ctrl.urlForDevices = null
    }
  }

  $scope.ctrl = ctrl
  $scope.$watch('ctrl.filters', filtersWatcher, true)

  $scope.$on('$includeContentRequested', () => (ctrl.loaded = false))
  $scope.$on('$includeContentLoaded', () => (ctrl.loaded = true))

  const urlForDevices = () => {
    if (!ctrl.filters.customer_id) return

    const options = {
      customer_id: ctrl.filters.customer_id,
      // disable select limitation
      page_limit: 1000000,
    }

    // true if order has some items.
    if (ctrl.filters.required_carrier_id) {
      options.carrier_base_ids = [ctrl.filters.required_carrier_id]
    }

    return customer_orderable_devices_path(options)
  }

  // $event needed for pagination
  // angular will render template by url
  const loadDevices = ($event = null) => {
    if ($event) $event.preventDefault()
    const customUrl = $event?.target.href

    return (ctrl.urlForDevices = customUrl || urlForDevices())
  }

  const deviceIsInvalid = () => {
    if (!ctrl.selectedDevice) return true
    if (ctrl.selectedDevice.substatus) return true
    return false
  }

  ctrl.loadDevices = loadDevices
  ctrl.deviceIsInvalid = deviceIsInvalid

  return ctrl
}

controller.$inject = ['$scope', '$hwSearchFilters']

angular.module('argon.hardwareOrdering').controller('hwOrderableDevicesCtrl', controller)
