$(() => {
  const container = $('#managerial_report_monthly_summary_submanagers')
  if (!container.length) return
  container.treetable({ expandable: true, initialState: 'collapsed' })
  $('#managerial_report_monthly_summary_search').keyup((e) => {
    const search_value = $.trim($(e.target).val()).replace(/\s+/g, ' ').toLowerCase()
    const all_rows = $('#managerial_report_monthly_summary_submanagers tbody tr')
    all_rows.show()
    const filtered_out_rows = all_rows.filter((index, el) => {
      const manager_name = $(el).data('manager-name').toLowerCase()
      return !manager_name.includes(search_value)
    })
    filtered_out_rows.hide()
  })
})
