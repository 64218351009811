// It might help you to create remote form with client-side validation. Also there is example
// how to handle ajax request from the server. See all details here:
// http://stefan.magnuson.co/articles/rails/simpler-handling-of-ajax-form-errors-in-rails-4/
// Example:
// targetFormSelector = '@remoteForm'
// $(document)
// .on "ajax:error", targetFormsSelector, (e, data, status, xhr) ->
// $(e.target).render_form_errors('contact', data.responseJSON)
// .on "ajax:success", targetFormsSelector, (e, data, status, xhr) =>
// $('#contactFormModal').modal('hide');
// Just a helper to clear form errors
$.fn.clear_form_errors = function () {
  this.find('.form-group').removeClass('has-error')
  this.find('span.help-block').remove()
}
// Render the errors in the form
// @param [String] - model name for wich form was created e.g. contact, device
// @param [Object] - list of model errors
$.fn.render_form_errors = function (model_name, errors = []) {
  const form = this
  this.clear_form_errors()

  $.each(errors, function (field, messages) {
    const input = form.find('input, select, textarea').filter(function () {
      const name = $(this).attr('name')
      if (name) {
        return name.match(new RegExp(model_name + '\\[' + field + '\\(?'))
      }
    })
    const capitalize = (str) => $.map(str, (m) => m.charAt(0).toUpperCase() + m.slice(1))
    input.closest('.form-group').addClass('has-error')
    input
      .parent()
      .append('<span class="help-block">' + capitalize(messages).join('<br />') + '</span>')
  })
}
