$(() => {
  $(document).on('click', '[role=select_all]', function (event) {
    event.preventDefault()

    const target = this.getAttribute('href')

    $(`${target} option`).prop('selected', true)
    $(target).trigger('change')
  })
})
