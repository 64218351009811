import { merge, map } from 'lodash-es'
import { tagColors, defaultTagColor } from './colors'

// Utility class for taggable models
class Taggable {
  // Initialize a single value Select2 with some typeahead values for dropdown.
  // That's not a common Select2 usage aimed to avoid extra typeahead plugins.
  static initTagSelect = (element) => {
    if (!element?.length) return
    element
      .select2({
        createSearchChoice: (term, data) => {
          const filtered = $(data).filter(function () {
            return this.text.localeCompare(term) === 0
          })

          if (filtered.length == 0) return { id: '_new_tag_', text: term, name: term, color: null }
          return
        },
        data: this.typeaheadData(),
        initSelection: (element, callback) => {
          const data = { id: element.val(), text: element.val() }
          return callback(data)
        },
      })
      .on('change', function (e) {
        const tag = e.added
        const $tagRow = $(this).closest('.row')
        $tagRow.find('@customer-tag-id').val(tag.id == '_new_tag_' ? '' : tag.id)
        $tagRow.find('@customer-tag-name').val(tag.name)
        $tagRow
          .find('@customer-tag-color')
          .select2('val', tag.color || '')
          .trigger('change')
        return
      })
  }

  static initColorSelect = (element) => {
    if (!element?.length) return
    element.select2({
      data: this.colorPalette(),
      minimumResultsForSearch: -1,
      formatResult: this.formatColor,
      formatSelection: this.formatColor,
      escapeMarkup: (m) => ({ m }),
    })
  }

  static formatColor = (element) =>
    "<span style='background: #" +
    element.text +
    "; display: inline-block; width: 100%; padding: 0 8px;'>&nbsp;</span>"

  // Get typeahead data stored in a taggable form attribute.
  static typeaheadData = () => {
    const tags = $('@taggable-form').data('typeaheads') || []
    return tags.map((tag) => merge(tag, { text: tag.name }))
  }

  // Background colors available for tags. Taken from labels and badges CSS.
  static colorPalette = () => {
    return map(tagColors, (color) => {
      const id = color == defaultTagColor ? '' : color
      return { id: id, text: color }
    })
  }
}

$(() => {
  $(document).on('cocoon:after-insert', (e, element) => {
    Taggable.initTagSelect(element.find('@customer-tag-name'))
    Taggable.initColorSelect(element.find('@customer-tag-color'))
  })

  // views/tags/taggable/edit
  const initCustomerTags = () => {
    Taggable.initTagSelect($('@taggable-form').find('@customer-tag-name'))
    Taggable.initColorSelect($('@taggable-form').find('@customer-tag-color'))
  }
  initCustomerTags()
  $(document).on('initCustomerTags', initCustomerTags)
})
