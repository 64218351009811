import swal from 'sweetalert'
import { order_path } from '~/helpers/rails-routes'

$(() => {
  const $container = $('@customerOrdersContainer')
  if ($container.length === 0) return

  $container.on('confirm:complete', '@orderCancelBtn', (e, answer) => {
    if (!answer) return
    const url = order_path($(e.target).data('orderId'))
    $.ajax({
      type: 'DELETE',
      dataType: 'json',
      url,
      data: window.location.search,
    })
      .then((json) => {
        if (json.status === 'success') {
          $(e.target).closest('@ordersTableWrapper').replaceWith(json.table_html)
          self.$container.find('@sidebarCustomersMenu').replaceWith(json.sidebar_customers_html)
          self.$container.find('@ordersInfoSidebarNavigation').replaceWith(json.sidebar_html)
        } else {
          swal({ title: 'Something went wrong.', icon: 'error' })
        }
      })
      .catch(() => swal({ title: 'Something went wrong.', icon: 'error' }))
  })
})
