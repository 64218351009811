import angular from 'angular'

// http://jsfiddle.net/wldaunfr/JSFLa/

angular.module('ArgonModule').directive('ngSelectAll', [
  '$filter',
  function () {
    return {
      link: (scope, iElement, iAttrs) => {
        const changeState = (checked, indet) =>
          iElement.prop('checked', checked).prop('indeterminate', indet)
        const selectItems = () =>
          angular.forEach(
            scope.$eval(iAttrs.items),
            (el) => (el[iAttrs.prop] = iElement.prop('checked'))
          )

        iElement.bind('change', () => scope.$apply(() => selectItems()))

        scope.$watch(
          iAttrs.items,
          (newValue) => {
            const checkedItems = newValue.filter((item) => item[iAttrs.prop])

            switch (checkedItems.length) {
              case 0:
                // none selected
                changeState(false, false)
                break
              case newValue.length:
                // all selected
                changeState(true, false)
                break
              default:
                // some selected
                changeState(false, true)
            }
          },
          true
        )

        return selectItems()
      },
    }
  },
])
