import { formatDate } from '~/helpers/format-date'
const format = (el, format = '%b %d, %Y') =>
  (el.innerText = formatDate(new Date(+el.dataset.date), format))

export const updateDateTimeItems = (withinSelector = '') => {
  $(`${withinSelector} .format-date`).each((i, el) => format(el))
  $(`${withinSelector} .format-time`).each((i, el) => format(el, '%H:%M'))
}

$(() => updateDateTimeItems())
