import angular from 'angular'
import { each, isEmpty, clone, keys, without, includes, filter, extend, size } from 'lodash-es'

angular.module('ArgonModule').service('CustomReport', [
  'dateFilter',
  '$timeout',
  'crColumnsService',
  function (dateFilter, $timeout, crColumnsService) {
    return class CustomReport {
      constructor(filter_data) {
        // filter_data should be set on the page with ng-init
        this.billing_cycle = filter_data.billing_cycle || {}
        this.conditions = filter_data.conditions || {}
        this.customer_id = filter_data.customer_id
        this.visible_columns = this.setVisibleColumns(filter_data.visible_columns)
        this.billing_filters_visible = this.setBillingFiltersVisibility()
      }

      validFilters() {
        const result = {}
        each(this.conditions, (value = {}, key) => {
          if (value.enabled == true) {
            // value shouldn't be an empty array or undefined or null or ''
            const condition =
              value.operator == 'known' ||
              value.operator == 'unknown' ||
              (value.operator != 'between' &&
                (!isEmpty(value.value) || $.isNumeric(value.value))) ||
              (value.operator == 'between' && $.isNumeric(value.min) && $.isNumeric(value.max)) ||
              (value.operator == 'between' && !isEmpty(value.min) && !isEmpty(value.max))

            if (condition) {
              result[key] = clone(value)
            }
          }
          return true
        })
        return result
      }

      enabledFilters() {
        const result = {}
        each(this.conditions, (value = {}, key) => {
          if (value.enabled == true) {
            result[key] = clone(value)
          }
          return true
        })
        return result
      }

      to_JSON(options = {}) {
        const result = {
          billing_cycle: this.billing_cycle,
          conditions: this.validFilters(),
          visible_columns: this.visible_columns,
        }

        if (options.datatables_search) {
          // Current Search applied in datatable, needed for Export to Excel
          result.conditions.datatables_search = {
            enabled: true,
            operator: 'is',
            value: options.datatables_search,
          }
        }

        if (options.ordered_phone_numbers) {
          // Ordered phone numbers, needed for Export to Excel
          result.conditions.ordered_phone_numbers = {
            enabled: true,
            operator: 'is',
            value: options.ordered_phone_numbers,
          }
        }

        return result
      }

      clear_value_when_switching_between_isnot_and_contains(
        newValidFiltersValue,
        oldValidFiltersValue
      ) {
        // $scope.$watch 'validFilters()' passes new and old values
        // we need to clear value to avoid prepopulating value field with id.
        // for example, we have this filter: device model is Apple iPhone 4 (id 25)
        // if we don't clear the value, then changing to:
        // device model contains filter
        // will have "25" as a string in the value field
        // we want to avoid this
        const report = this
        each(newValidFiltersValue, (value, key) => {
          const new_operator = value.operator
          const old_operator = oldValidFiltersValue[key]?.operator
          if (
            ((new_operator == 'contains' || new_operator == 'not_contains') &&
              (old_operator == 'is' || old_operator == 'not')) ||
            ((old_operator == 'contains' || old_operator == 'not_contains') &&
              (new_operator == 'is' || new_operator == 'not'))
          ) {
            report.conditions[key].value = ''
          }
          return true
        })
      }

      setCorrespondingColumn(id, enabled) {
        // Special case for accounting types columns
        if (id.startsWith('business_unit_accounting_type_')) {
          return this.setBusinessUnitColumn(enabled)
        }

        // there's no such column, return
        if (!crColumnsService.columns_as_object()[id]) {
          return
        }

        if (enabled) {
          if (!includes(this.visible_columns, id)) {
            this.visible_columns.push(id)
          }
        } else {
          if (includes(this.visible_columns, id)) {
            this.visible_columns = without(this.visible_columns, id)
          }
        }
      }

      setBusinessUnitColumn(enabled) {
        const id = 'business_unit'
        const business_unit_filters = filter(keys(this.enabledFilters()), (f) =>
          f.startsWith('business_unit_accounting_type_')
        )

        // there's no such column, return
        if (!crColumnsService.columns_as_object()[id]) {
          return
        }

        if (enabled) {
          if (!includes(this.visible_columns, id)) {
            this.visible_columns.push(id)
          }
        } else {
          if (includes(this.visible_columns, id) && business_unit_filters.length == 0) {
            this.visible_columns = without(this.visible_columns, id)
          }
        }
      }

      enableCondition(conditions) {
        // Used for shortcut filters in devices breakdown
        let condition, new_value
        for (condition in conditions) {
          new_value = conditions[condition]
          // Display billing filters if we set total cost condition
          if (condition == 'total_cost_total') {
            this.billing_filters_visible = true
          }
          condition = this.conditions[condition]
          extend(condition, { enabled: true }, new_value)
          $timeout(() => true)
        }
      }

      setVisibleColumns(columns) {
        // set Visible columns from passed value (from url hash), then localstorage, then default ones
        if (!columns || columns.length == 0) {
          columns = this.setVisibleColumnsFromLocalStorage()
        }
        if (!columns || columns.length == 0) {
          columns = crColumnsService.defaultColumns()
        }
        return crColumnsService.selectValidColumns(columns)
      }

      setVisibleColumnsFromLocalStorage() {
        // set visible columns from localstorage
        let result
        try {
          // we need try block just in case LocalStorage has an invalid JSON
          result = JSON.parse(localStorage.getItem('argon_devices_page_visible_columns_2'))
        } catch {
          // catch
        }
        return result
      }

      setBillingFiltersVisibility() {
        // if conditions have keys which include either _cost, or _usage,
        // then display billing related filte
        const ks = keys(this.conditions)
        const billing_keys = ks.filter((key) => includes(key, '_cost') || includes(key, '_usage'))
        return billing_keys.length > 0
      }

      setLink(hash) {
        // set URL to the value received from server, don't set URL, if no filters are selected
        // add debug parameter if it was present in the url
        let url = '?filter=default'
        if (hash && size(this.validFilters()) > 0) {
          url = `?filter=${hash}`
        }
        if (includes(location.href, 'debug')) {
          url = `${url}&debug=1`
        }
        history.replaceState(history.state, document.title, url)
      }
    }
  },
])
