import angular from 'angular'
import { customer_orderable_accessories_path } from '~/helpers/rails-routes'

function controller($scope, $attrs, $searchFilters) {
  const ctrl = this

  ctrl.filters = $searchFilters
  ctrl.urlForAccessories = null

  const filtersWatcher = () => {
    if (ctrl.filters.isAccessoriesMode()) {
      ctrl.loadAccessories()
    } else {
      // hide template
      ctrl.urlForAccessories = null
    }
  }

  $scope.ctrl = ctrl
  $scope.$watch('ctrl.filters', filtersWatcher, true)

  $scope.$on('$includeContentRequested', () => (ctrl.loaded = false))
  $scope.$on('$includeContentLoaded', () => (ctrl.loaded = true))

  const urlForAccessories = () => {
    if (!ctrl.filters.customer_id) return

    const options = {
      customer_id: ctrl.filters.customer_id,
      carrier_id: ctrl.filters.carrier_id,
      order_id: $attrs.orderId,
      page: 1,
      per_page: 16,
    }

    // blank value for all device makes
    if (ctrl.filters.device_make_id) options.device_make_id = ctrl.filters.device_make_id
    if (ctrl.filters.device_model_id) options.device_model_id = ctrl.filters.device_model_id
    if (ctrl.filters.scraper == 'Simplex Accessories') options.scraper = 'simplex_accessories'

    const category = ctrl.filters.category
    if (category) {
      // 'Simplex Accessories' is a special made up item in the categories filter.
      // If selected then filter by scraper instead of category.
      if (category == 'Simplex Accessories') {
        options.scraper = 'simplex_accessories'
      } else {
        options.category = category
      }
    }

    options.name = ctrl.filters.name

    return customer_orderable_accessories_path(options)
  }

  // $event needed for pagination
  // angular will render template by url
  const loadAccessories = ($event = null) => {
    if ($event) $event.preventDefault()
    const customUrl = $event?.target.href

    return (ctrl.urlForAccessories = customUrl || urlForAccessories())
  }

  ctrl.loadAccessories = loadAccessories

  return ctrl
}

controller.$inject = ['$scope', '$attrs', '$hwSearchFilters']

angular.module('argon.hardwareOrdering').controller('hwOrderableAccessoriesCtrl', controller)
