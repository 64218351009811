$(function () {
  this.filterBusinessUnirReviewData = function (el) {
    $(el).parent().find('.filter-loader').css({
      display: 'inline',
    })
    $.ajax({
      url: $(el).data('url'),
      data: {
        company_codes: [$(el).val()],
      },
      type: 'GET',
      dataType: 'script',
    })
  }
  this.showLoader = function (loader) {
    document.getElementById(loader).style.display = 'inline'
  }
})
