import angular from 'angular'

angular.module('ArgonModule').controller('OrderItemsDetailsCtrl', [
  '$scope',
  '$element',
  function ($scope, $element) {
    $scope.item = $element.data('ngdata')
    $scope.form = {}

    $scope.form.mandatoryFeatures = () =>
      $scope.item.rate_plan_permissions[$scope.item.rate_plan]?.features
  },
])
