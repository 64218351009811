import angular from 'angular'
import { customer_orderable_inventory_devices_path } from '~/helpers/rails-routes'

function controller($scope, $searchFilters) {
  const ctrl = this

  ctrl.filters = $searchFilters
  ctrl.urlForInventoryDevices = null

  const filtersWatcher = () => {
    if (ctrl.filters.isInventoryDevicesMode()) {
      ctrl.loadInventoryDevices()
    } else {
      // hide template
      ctrl.urlForInventoryDevices = null
    }
  }

  $scope.ctrl = ctrl
  $scope.$watch('ctrl.filters', filtersWatcher, true)

  $scope.$on('$includeContentRequested', () => (ctrl.loaded = false))
  $scope.$on('$includeContentLoaded', () => (ctrl.loaded = true))

  const urlForInventoryDevices = () => {
    if (!ctrl.filters.customer_id) return

    const options = {
      customer_id: ctrl.filters.customer_id,
      carrier_id: ctrl.filters.carrier_id,
    }

    // I don't need to search by device make
    // because I'm using datatable filters now.
    // blank value for all device makes
    // options.device_make_id = ctrl.filters.device_make_id if ctrl.filters.device_make_id
    return customer_orderable_inventory_devices_path(options)
  }

  // $event needed for pagination
  // angular will render template by url
  const loadInventoryDevices = ($event = null) => {
    if ($event) $event.preventDefault()
    const customUrl = $event?.target.href

    return (ctrl.urlForInventoryDevices = customUrl || urlForInventoryDevices())
  }

  ctrl.loadInventoryDevices = loadInventoryDevices

  return ctrl
}

controller.$inject = ['$scope', '$hwSearchFilters']

angular.module('argon.hardwareOrdering').controller('hwOrderableInventoryDevicesCtrl', controller)
