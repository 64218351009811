import { defineMessages, useI18n } from '~/helpers/use-i18n'
import { formatNumber } from '~/helpers/format-unit'

export const dataMessages = defineMessages({
  en: {
    byte: 'Bytes',
    kb: 'KB',
    mb: 'MB',
    gb: 'GB',
    tb: 'TB',
  },
  'fr-CA': {
    byte: 'Octets',
    kb: 'ko',
    mb: 'Mo',
    gb: 'Go',
    tb: 'To',
  },
})
const { t } = useI18n({ messages: dataMessages })

/**
 * @param {string | number} value
 * @param {{precision: number}} options
 * @return {string}
 */
export function humanSize(value = 0, options) {
  const oneKb = 1024
  let size = parseInt(value) || 0
  let unit = 'byte'
  // kb
  if (size / oneKb >= 1) {
    size = size / oneKb
    unit = 'kb'
  }
  // mb
  if (size / oneKb >= 1) {
    size = size / oneKb
    unit = 'mb'
  }
  // gb
  if (size / oneKb >= 1) {
    size = size / oneKb
    unit = 'gb'
  }
  // tb
  if (size / oneKb >= 1) {
    size = size / oneKb
    unit = 'tb'
  }

  return `${formatNumber(size, options)} ${t(unit)}`
}
