// sometimes I want to load partial once.
$(document).on('ajax:before', '[data-remote][data-target][data-partial]', function () {
  const $element = $(this)

  const allowed_requests = $element.data('requests')

  if ([undefined, null].includes(allowed_requests)) return true

  const counter = $element.data('counter') || 0
  $element.data('counter', counter + 1)

  return allowed_requests > counter
})

$(document).on('ajax:success', '[data-remote][data-target][data-partial]', function (xhr, data) {
  const selector = $(this).data('target')
  const $element = $(selector)
  $element.html(data)

  $element.trigger('init.argon.datepickers')
  $element.trigger('init.argon.selects')
})
