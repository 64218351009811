import Mousetrap from 'mousetrap'

const ArgonKeyBindings = {}

ArgonKeyBindings.setup = () => {
  const initMousetrap = () => {
    Mousetrap.reset()

    const navigationBind = (shortcuts, role) => {
      shortcuts.forEach((shortcut) =>
        Mousetrap.bind(shortcut, () => $(`a[role=${role}]`)[0].click())
      )
    }

    // Keybindings mapping

    navigationBind(['g i', 'п ш'], 'mousetrap-customer-inventory-link')
    navigationBind(['g d', 'п в'], 'mousetrap-customer-devices-link')
    navigationBind(['g o', 'п щ'], 'mousetrap-customer-orders-link')
    navigationBind(['g m', 'п ь'], 'mousetrap-managers-and-employees')
    navigationBind(['g a e', 'п ф у'], 'mousetrap-admin-emails')
    navigationBind(['g a j', 'п ф о'], 'mousetrap-admin-jobs')
    navigationBind(['g r m', 'п к ь'], 'mousetrap-customer-reports-managerial')
    navigationBind(['g r a', 'п к ф'], 'mousetrap-carrier-reports-accounting')
    navigationBind(['g s o', 'п ы щ'], 'mousetrap-settings-hardware-ordering')
    Mousetrap.bind('?', ArgonKeyBindings.showShortcutsModal)
  }

  initMousetrap()
}

ArgonKeyBindings.showShortcutsModal = () => $('#keybindingShortuts').modal()

$(() => {
  ArgonKeyBindings.setup()
  $(document).on('click', '@mousetrap-show-shortcuts-link', ArgonKeyBindings.showShortcutsModal)
})
