const pad = (n) => (n < 10 ? '0' + n : n)

export class DateHelper {
  static displayDate = (dateTime, showTime = true) => {
    if (!dateTime) return ''

    const date = new Date(Date.parse(dateTime))
    const time = `${date.getHours()}:${pad(date.getMinutes())}`
    const dateFormated = `${pad(date.getDate())}/${pad(date.getMonth() + 1)}/${date.getFullYear()}`
    return showTime ? `${time} ${dateFormated}` : `${dateFormated}`
  }
}
