import { map, reject, join, isEmpty, merge } from 'lodash-es'

// Use `data-device` attribute or `selected-object` directive to set initial default value
// Example:
// %input{ 'device-search': true, data: { action: search_path(customer_ids: [@customer.id]), device: "{{request.device}}" }}

export class DeviceSearch {
  constructor(element) {
    this.element = element
  }

  activate(options = {}) {
    options = jQuery.extend(this.defaultOptions(), options)

    const $input = $(this.element)

    $input.select2({
      placeholder: options.placeholder,
      minimumInputLength: options.minimumInputLength,
      allowClear: options.allowClear,
      ajax: {
        url: options.url,
        dataType: 'json',
        quietMillis: 100,
        data: (term) => ({
          term: term,
          page_limit: options.page_limit,
          include_wirelines: options.include_wirelines,
          include_inventory: options.include_inventory,
          include_contacts: options.include_contacts,
        }),
        results: (data) => {
          const result = []

          if (data.devices?.length > 0) {
            const children = map(data.devices, (device) => merge(device, { type: 'device' }))
            result.push({ text: 'Mobility Devices', children: children })
          }

          if (data.wirelines?.length > 0) {
            const children = map(data.wirelines, (wireline) => merge(wireline, { type: 'device' }))
            result.push({ text: 'Wireline Devices', children: children })
          }

          if (data.inventory?.length > 0) {
            const children = map(data.inventory, (inventory) =>
              merge(inventory, { type: 'device' })
            )
            result.push({ text: 'Spare Hardware', children: children })
          }

          if (data.contacts?.length > 0) {
            const children = map(data.contacts, (contact) => merge(contact, { type: 'contact' }))
            result.push({ text: 'Contacts', children: children })
          }

          return { results: result }
        },
      },
      formatResult: (result) => {
        // optgroup
        if (result.text) {
          return `<div class='select2-result-header role='option'>${result.text}</div>`
        } else {
          if (result.type == 'device') {
            return this.formatDataDevice(result, ' <br> ')
          } else if (result.type == 'contact') {
            return this.formatDataContact(result, ' <br> ')
          }
        }
      },

      formatSelection: (result) => {
        if (result.type == 'device') {
          return this.formatDataDevice(result, ' - ')
        } else if (result.type == 'contact') {
          return this.formatDataContact(result, ' - ')
        }
      },
    })

    return $input.select2('data', $input.data('device'))
  }

  defaultOptions() {
    return {
      url: $(this.element).data('action'),
      page_limit: 10,
      minimumInputLength: 4,
      placeholder: 'Enter a name or number',
      allowClear: true,
      include_wirelines: $(this.element).attr('include_wirelines'),
      include_inventory: $(this.element).attr('include_inventory'),
      include_contacts: $(this.element).attr('include_contacts'),
    }
  }

  formatDataDevice(device, separator) {
    const status = device.status
    if (!status) return ''

    let number = device.number
    if (number.length > 10) number = number.substring(0, 9) + '…'
    number = number.replace(/(\d{3})(\d{3})(\d{4})/, '$1–$2–$3')

    let result = `${device.username} ${separator} ${number}`
    let klass = ''

    if (device.substatus) {
      result += ` <span class='text-info'>(${device.substatus} — ${device.device_make_name})</span>`
    } else if (status == 'active' && device.device_make_name) {
      if (device.device_make_name.length > 0) result += ` <span>(${device.device_make_name})</span>`
    } else {
      if (status == 'suspended') klass = 'text-warning'
      if (status == 'cancelled') klass = 'text-danger'

      const deviceStatus = device.status.charAt(0).toUpperCase() + device.status.slice(1)
      result += ` <span>(${deviceStatus} — ${device.device_make_name})</span>`
    }

    result = `<span class='${klass}'>${result}</span>`

    return result
  }

  formatDataContact(contact, separator) {
    let result = contact.name
    if (contact.status) result = `${result} (${contact.status})`

    let desc = reject([contact.employee_number, contact.email], (el) => isEmpty(el))
    desc = join(desc, ' - ')
    if (!isEmpty(desc)) result = `${result} ${separator} ${desc}`

    let klass = ''
    if (contact.status != 'active') {
      klass = contact.status == 'inactive' ? 'text-danger' : 'text-warning'
    }

    return `<span class='${klass}'>${result}</span>`
  }
}
