import angular from 'angular'
import { map, each, isArray, isString } from 'lodash-es'

const addHiddenInputNearElement = (element, elName, elValue, elClass) => {
  const input = document.createElement('input')
  input.setAttribute('type', 'hidden')
  input.setAttribute('name', elName)
  input.setAttribute('class', elClass)
  input.setAttribute('value', elValue)
  return element.parent().append(input)
}

// Remove select name when 'multiple' option is true ans it's name ends on '[]'. We are not
// going to use it's value directly. We will create hidden inputs for each value. Otherwise it
// sends selected values as one string separating each value with comma
const multipleSelectRemoveName = (element) => {
  const name = element.attr('name')
  if (name.slice(-2) != '[]') return

  element.attr('element-name', name)
  element.attr('name', '')
}

angular.module('ArgonModule').directive('ngSelect2Ajax', [
  '$timeout',
  '$parse',
  function () {
    return {
      require: '?ngModel',
      link: ($scope, element, attrs, $ngModel) => {
        if (!$ngModel) return

        let config = element.data('select2ajax')
        let isMultiple = $.inArray(element.attr('multiple'), [undefined, false]) == -1

        const prepareOption = (el) => {
          if (isArray(el) && el.length == 2) return { id: el[0], text: el[1] }
          if (isString(el)) return { id: el, text: el }
          return { id: el.id, text: el.value || el.name || el.text }
        }

        const multipleSelectAssignValues = (element, values) => {
          const elementName = element.attr('element-name') || element.attr('name')
          // If values are expected to be sent as an array
          if (elementName.slice(-2) == '[]') {
            const valuesClassName = `${element.attr('id')}-select2-added-values`

            // Delete earlier added hidden inputs
            element.parent().find(`.${valuesClassName}`).remove()
            // Add hidden input with empty value to be able to delete values on backend
            addHiddenInputNearElement(element, elementName, '', valuesClassName)

            // Add hidden inputs with selected values
            each(values, (item) =>
              addHiddenInputNearElement(element, elementName, item['id'], valuesClassName)
            )

            element.val(map(values, (el) => el.id))
          } else {
            const ids = element.select2('data').reduce((memo, el) => {
              memo.push(el['id'])
              return memo
            }, [])
            element.val(ids.toString())
          }
          $ngModel.$modelValue = element.select2('data')
        }

        if (config) {
          const options = {
            initSelection: (element, callback) => {
              const data = isMultiple
                ? map(config.default, prepareOption)
                : prepareOption(config.default)

              let value = []
              if (isMultiple) {
                each(data, (item) => {
                  if (item && item.id && item.text) {
                    value.push({ id: item.id, text: item.text })
                  }
                })
              } else if (data && data.id && data.text) {
                value = { id: data.id, text: data.text }
              }
              if (value.length == 0) {
                return
              }
              callback(value)
            },
            // <option> tag can't be used here (allowed parents are <select> or <datalist>)
            // But i left it as is. It can be used by other pieces of application. If not - remove <option> tag from here.
            formatResult: (data) =>
              `<div><option style='display: none;' data-id=${data.id}>` +
              data.text +
              '</option> <span>' +
              data.text +
              '</span> </div>',
            formatSelection: (data) => `<span data-id='${data.id}'>` + data.text + '</span>',
            ajax: {
              placeholder: element.attr('placeholder'),
              url: config.url,
              dataType: 'json',
              quietMillis: 250,
              cache: true,
              data: (params) => {
                const queryParams = { page_limit: 10 }
                queryParams[config.queryParameterName || 'term'] = params
                return queryParams
              },
              results: (json) => {
                return { results: $.map(json.data, prepareOption) }
              },
            },
            minimumInputLength: config.minimumInputLength || 2,
            maximumSelectionSize: config.maximumSelectionSize || -1,
            multiple: isMultiple,
          }

          if (config.options instanceof Object) $.extend(options, config.options)
          // Don't delete "select2('val', [])". "initSelection" doesn't work without it
          element.select2(options).select2('val', [])

          if (isMultiple) {
            multipleSelectRemoveName(element)
          }

          let value = null
          if (config.default) {
            if (isMultiple) {
              const values = config.default
              multipleSelectAssignValues(element, values)
            } else {
              value = config.default.id
              element.val(value).change()
            }
          }

          element.on('load-options', (e, params) => {
            return $.getJSON(config.url, params, (res) => {
              config.default = res.data.map(prepareOption)

              if (isMultiple) {
                multipleSelectAssignValues(element, res.data)
              } else {
                config.default = config.default[0]
                element.val(res.data[0]?.id)
              }

              return element.change()
            })
          })
          element.on('change-options', function () {
            const slice = [].slice
            // coffee: (_, obj...)
            const obj = 2 <= arguments.length ? slice.call(arguments, 1) : []
            config.default = obj
            if (isMultiple) {
              multipleSelectAssignValues(element, obj)
            } else {
              element.val(obj.id)
            }
            return element.change()
          })
        }

        // If model changes
        const changeModel = () => {
          if (element.attr('multiple') || $ngModel.$modelValue instanceof Object) {
            multipleSelectAssignValues(element, element.select2('data'))
            return ($ngModel.$modelValue = element.select2('data'))
          } else {
            return ($ngModel.$modelValue = element.select2('val'))
          }
        }

        // If selected value changes
        element.on('change', () => changeModel())
      },
    }
  },
])
