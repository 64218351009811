import swal from 'sweetalert'
import angular from 'angular'
import { GSuiteInfo } from '../../models/g_suite_info'

angular.module('ArgonModule').controller('GSuiteDeviceActionsCtrl', [
  '$scope',
  function ($scope) {
    const VM = this

    VM.init = (params) => {
      VM.urls = $('#mdm-g-suite-device-urls').data('urls')
      VM.mdmInfo = { data: new GSuiteInfo(params.data), isLoading: true }
      checkApiAvailability()
    }

    VM.adminAccountWipe = () =>
      confirmAction(
        'Are you sure you want to wipe only GSuite data from this device?',
        VM.urls.admin_account_wipe
      )

    VM.adminRemoteWipe = () =>
      confirmAction(
        'Are you sure you want to wipe all data on this device?',
        VM.urls.admin_remote_wipe
      )

    VM.approve = () =>
      confirmAction('Are you sure you want to aprove this device?', VM.urls.approve)

    VM.block = () =>
      confirmAction(
        'Are you sure you want to block access to GSuite data for this device?',
        VM.urls.block
      )

    VM.cancelRemoteWipeThenActivate = () =>
      confirmAction(
        'Are you sure you want to cancel remote wipe of the device and then reactivate it?',
        VM.urls.cancel_remote_wipe_then_activate
      )

    VM.cancelRemoteWipeThenBlock = () =>
      confirmAction(
        'Are you sure you want to cancel remote wipe of the device and then block it?',
        VM.urls.cancel_remote_wipe_then_block
      )

    VM.delete = () =>
      confirmAction('Are you sure you want to delete this device from GSuite?', VM.urls.delete)

    VM.fetchMdmInfo = () => {
      if (!VM.urls) return
      VM.mdmInfo.isLoading = true
      $.post(VM.urls.refresh_mdm_info, { force: true }, (response) => {
        VM.mdmInfo.data = new GSuiteInfo(response.data)
        initializeData()
        VM.mdmInfo.isLoading = false
        $scope.$apply()
      })
    }

    //
    const initializeData = () => {
      VM.installedApplications = { data: [], isLoading: false }
      VM.actionsHistory = { data: [], isLoading: false }
    }

    const checkApiAvailability = () => {
      if (!VM.urls) return
      $.post(VM.urls.status, {}, (response) => {
        if (response.available) {
          VM.fetchMdmInfo()
        } else {
          VM.mdmInfo.isLoading = false
          $scope.$apply()
        }
      })
    }

    const confirmAction = (message, url, params) => {
      if (!url) return

      const swal_params = {
        title: 'Confirmation',
        text: message,
        icon: 'warning',
        buttons: true,
      }
      swal(swal_params).then((isConfirmed) => {
        if (!isConfirmed) return
        makeRequest(url, params)
      })
    }

    const makeRequest = (url, params = {}) => {
      const success = (response) => {
        swal({
          title: response.succeeded ? 'Success' : 'Failure',
          text: response.message,
          icon: response.succeeded ? 'info' : 'error',
        })
      }
      const fail = () => swal({ title: 'Failure', text: 'Something went wrong', icon: 'error' })

      return $.post(url, params).done(success).fail(fail)
    }

    return VM
  },
])
