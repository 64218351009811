import angular from 'angular'
import swal from 'sweetalert'
import { MiradoreInfo } from '../../models/miradore_info'

angular.module('ArgonModule').controller('MiradoreDeviceActionsCtrl', [
  '$scope',
  function ($scope) {
    const VM = this

    VM.init = (params) => {
      VM.urls = $('#mdm-miradore-device-urls').data('urls')
      VM.mdmInfo = { data: new MiradoreInfo(params.data), isLoading: true }
      checkApiAvailability()
    }

    VM.changeUsername = () => {
      if (!(VM.urls && VM.urls.change_username)) return
      $('#mdm-miradore-change-username').modal('hide')
      if (VM.username.firstname || VM.username.lastname || VM.username.middle) {
        $('#mdm-send-message').modal('hide')

        const success = (response) => {
          if (response.succeeded) {
            VM.username.firstname = ''
            VM.username.lastname = ''
            VM.username.middle = ''
            $scope.$apply()
          }
        }

        makeRequest(VM.urls.change_username, {
          firstname: VM.username.firstname,
          lastname: VM.username.lastname,
          middle: VM.username.middle,
        }).done(success)
      }
    }

    VM.displayLocation = () => {
      if (!VM.urls) return
      $('#mdm-miradore-location').modal('show')
      VM.location = { isLoading: true, isError: false }
      $.get(VM.urls.location, {}, (response) => {
        if (!response.succeeded) {
          VM.location.isError = true
          VM.location.error = response.message
        }
        if (response.data) VM.location.data = response.data
        VM.location.isLoading = false
        $scope.$apply()
      })
    }

    VM.lostModeOn = () => {
      if (!VM.urls) return
      $('#mdm-miradore-lost-mode').modal('hide')

      const success = (response) => {
        if (response.succeeded) {
          VM.location.message = ''
          VM.location.phone = ''
          VM.location.footnote = ''
          $scope.$apply()
        }
      }

      makeRequest(VM.urls.lost_mode_on, {
        message: VM.username.message,
        phone: VM.username.phone,
        footnote: VM.username.footnote,
      }).done(success)
    }

    VM.lostModeOff = () => makeRequest(VM.urls.lost_mode_off)

    VM.lock = () => confirmAction('Are you sure you want to lock this device?', VM.urls.lock)

    VM.wipe = () =>
      confirmAction(
        'Are you sure you want to wipe this device? This action can not be reversed.',
        VM.urls.wipe
      )

    VM.displayInstalledSoftware = () => {
      if (!VM.urls) return
      $('#mdm-miradore-installed-applications').modal('show')
      VM.installedApplications.data = []
      VM.installedApplications.isLoading = true
      $.post(VM.urls.installed_software, {}, (response) => {
        if (!response.data) return
        VM.installedApplications.isLoading = false
        VM.installedApplications.data = response.data
        $scope.$apply()
      })
    }

    VM.fetchMdmInfo = () => {
      if (!VM.urls) return
      VM.mdmInfo.isLoading = true
      $.post(VM.urls.refresh_mdm_info, { force: true }, (response) => {
        VM.mdmInfo.data = new MiradoreInfo(response.data)
        initializeData()
        VM.mdmInfo.isLoading = false
        $scope.$apply()
      })
    }

    //

    const initializeData = () => {
      VM.installedApplications = { data: [], isLoading: false }
      VM.location = { isLoading: false, isError: false }
      VM.actionsHistory = { data: [], isLoading: false }
      VM.username = {
        firstname: '',
        lastname: '',
        middle: '',
      }
    }

    const checkApiAvailability = () => {
      if (!VM.urls) return
      $.post(VM.urls.status, {}, (response) => {
        if (response.available) {
          VM.fetchMdmInfo()
        } else {
          VM.mdmInfo.isLoading = false
          $scope.$apply()
        }
      })
    }

    const confirmAction = (message, url, params) => {
      if (!url) return

      const swal_params = {
        title: 'Confirmation',
        text: message,
        icon: 'warning',
        buttons: true,
      }
      swal(swal_params).then((isConfirmed) => {
        if (!isConfirmed) return
        makeRequest(url, params)
      })
    }

    const makeRequest = (url, params = {}) => {
      const success = (response) => {
        swal({
          title: response.succeeded ? 'Success' : 'Failure',
          text: response.message,
          icon: response.succeeded ? 'info' : 'error',
        })
      }
      const fail = () => swal({ title: 'Failure', text: 'Something went wrong', icon: 'error' })

      return $.post(url, params).done(success).fail(fail)
    }

    return VM
  },
])
