import angular from 'angular'
import { DeviceStatusUpdateRequest } from '../models/device_status_update_request'

angular.module('ArgonModule').controller('ManagerialDisputeCtrl', [
  '$scope',
  '$element',
  function ($scope, $element) {
    $scope.request = new DeviceStatusUpdateRequest($element.data('request') || {})
    $scope.view = {
      changeStatus: '1',
    }
  },
])
