import angular from 'angular'
import { ZipCodesSearch } from '../models/zip_codes_search'

angular.module('ArgonModule').directive('zipCodesSearch', [
  '$timeout',
  function ($timeout) {
    return {
      require: '?ngModel',
      link: ($scope, element, attributes, $ngModel) => {
        const search = new ZipCodesSearch(element)
        $timeout(() => search.activate())

        // If given model changes
        const model_getter = () => $ngModel.$modelValue
        $scope.$watch(model_getter, (nV) => $timeout(() => search.chooseOption(nV)))

        // If selected value changes
        element.on('change', () => {
          $ngModel.$modelValue = element.select2('data').id
          return $timeout(() => true)
        })
      },
    }
  },
])
