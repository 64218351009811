import { load_complex_data_customer_dashboard_index_path } from '~/helpers/rails-routes'

// Business Accounts Stats
$(document).on('shown.bs.tab', () => {
  $('@customer_dashboard_tabs @popover').popover({ html: true })
})

// Open Billing Data Lines Modal when click on Devices Count link
$(document).on('click', '.dashboard_business_accounts_stats_billing_data_lines_link', function (e) {
  e.preventDefault()
  const url = $(this).data('url')
  const $modal = $('#business_accounts_stats_billing_data_lines')
  $modal.find('.modal-body').load(url, () => $modal.modal())
})

$(document).on('click', '.dashboard_business_accounts_stats_month_over_month_link', function (e) {
  e.preventDefault()
  const $modal = $('#business_accounts_stats_month_over_month')
  $modal.find('.modal-body').html($(this).data('modal-content'))
  $modal.modal()
})

// Function to Load Tab Content from Server
const loadTabContent = (tab, activate) => {
  const $tab = $(tab)
  if ($($tab.attr('href')).length) {
    if (activate === true) $tab.tab('show')
  } else {
    const $target = $(
      `<div id='${$tab.attr('href').substr(1)}' class='tab-pane ${activate ? 'active' : ''}'></div>`
    )
    const $tabContent = $tab
      .parents('.tabs-container')
      .find('.tab-content')
      .addClass('pure-spinner-block ease-out')
      .append($target)
    $.get($tab.attr('data-url'), (data) => {
      $tabContent.removeClass('pure-spinner-block')
      $target.html(data)
      if (activate === true) $tab.tab('show')
    })
  }
}

// Load Tab from Ajax on click
$(document).on('click', '.business_accounts_stats [data-toggle="tab-ajax"]', (e) => {
  e.preventDefault()
  loadTabContent(e.target, true)
})

// Display Business Accounts Table if we display only carriers
$(document).on('click', '.display_business_accounts_table_link', (e) => {
  e.preventDefault()
  const $this = $(e.target)
  $($this.attr('href')).toggle('slow')
})

// Load and activate the last tab
$(() => {
  if ($('.business_accounts_stats').length > 0) {
    const lastTab = $('.business_accounts_stats [data-toggle="tab-ajax"][data-autoload]').last()
    if (lastTab.length) {
      loadTabContent(lastTab, true)
    }
  }
})

// Dashboard Charts

// Init unpaid bills datatable
const initializeUnpaidBillsDatatable = (targetEl) => {
  const $table = $(targetEl).find('@unpaid_bills_datatable')
  if (!$table.length) return
  $table.dataTable({
    dom: 'Bfrtip',
    pageLength: 50,
    order: [5, 'desc'],
    buttons: {
      dom: {
        button: {
          className: 'btn btn-sm btn-default margin-top-45',
        },
        buttonLiner: {
          // prevent button's text wrapping within span tag
          tag: '',
        },
      },
      buttons: [{ extend: 'csv', text: '<i class="fa fa-file-excel-o"></i> Export to CSV' }],
    },
  })
}

// Load content for customer dashboard tabs
const loadCustomerDashboardTabs = (tab) => {
  $('@customer_dashboard_tabs .tab-content').addClass('pure-spinner-block ease-out')
  const url = tab.attr('data-url')
  if (!tab[0]) return
  const href = tab[0].hash

  // ajax load from data-url
  $(href).load(url, function () {
    $('.tab-pane').removeClass('show')
    tab.tab('show')
    $('@customer_dashboard_tabs .tab-content').removeClass('pure-spinner-block')
    // Init datatable inside tab (with CSV export button)
    initializeUnpaidBillsDatatable(this)
  })
}

// Function to Load Tab Content from Server
const loadGraphContent = (tab) => {
  $('.dashboard-graph').addClass('pure-spinner-block ease-out')
  const url = tab.attr('data-url')
  if (!tab[0]) return
  const href = tab[0].hash

  const showTab = () => {
    $('.tab-pane').removeClass('show')
    tab.tab('show')
    $('.dashboard-graph').removeClass('pure-spinner-block')
  }

  if (url) {
    // ajax load from data-url
    $(href).load(url, showTab)
  } else {
    // simply show tab if there's nothing to load
    showTab()
  }
}

const loadComplexData = () => {
  if ($('@postloaded_value:first').length == 0) return
  const customerId = $('@postloaded_value:first').data('customer')
  const $suspendCandidates = $('@suspend_candidates')
  const $dataConflictsCount = $('@data_conflicts_count')
  const $hardwareCosts = $('@hardware_costs')
  const requiredValues = {
    suspension_candidates: $suspendCandidates.length > 0,
    data_conflicts_count: $dataConflictsCount.length > 0,
    hardware_cost: $hardwareCosts.length > 0,
  }
  $.ajax({
    url: load_complex_data_customer_dashboard_index_path(customerId, {
      // { includes: ['suspension_candidates', 'data_conflicts_count'] }
      includes: Object.keys(requiredValues).filter((k) => requiredValues[k]),
    }),
    dataType: 'json',
    success: ({ data }) => {
      if ($suspendCandidates.length) $suspendCandidates.replaceWith(data.suspension_candidates)
      if ($dataConflictsCount.length) $dataConflictsCount.replaceWith(data.data_conflicts_count)
      if ($hardwareCosts.length) $hardwareCosts.replaceWith(data.hardware_cost)
    },
  })
}

$(() => {
  $(document).on('click', '#dashboard_tabs :not(.active) a', function (e) {
    e.preventDefault()
    loadGraphContent($(this))
  })

  $(document).on('click', '@customer_dashboard_tabs :not(.active) @tab_link', function (e) {
    e.preventDefault()
    loadCustomerDashboardTabs($(this))
  })

  if ($('.customer_dashboard').length > 0) {
    loadCustomerDashboardTabs($('@customer_dashboard_tabs .active > @tab_link'))
    loadComplexData()
  }
})
