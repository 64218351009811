import { defineMessages, useI18n } from '~/helpers/use-i18n'

const messages = defineMessages({
  en: {
    airtime: 'Airtime',
    all: 'All',
    carrier_details: 'Carrier Details',
    cost_and_averages: 'Costs & Averages',
    data_and_other_costs: 'Data & Other Costs',
    default: 'Default',
    device_details: 'Device Details',
    employee_details: 'Employee Details',
    export_to_csv: 'Export to CSV',
    export_to_excel: 'Export to Excel',
    exporting: 'Exporting...',
    messaging: 'Messaging',
    none: 'None',
    roaming: 'Roaming',
    select_all: 'select all',
    select_columns: 'Select Columns',
    user_details: 'User Details',
    '411_services_cost_avg': '411 Services Cost (avg)',
    account: 'Account',
    additional_manager: 'Additional Manager',
    airtime_cost_avg: 'Airtime Cost (avg)',
    airtime_usage_avg: 'Airtime Usage (avg)',
    asset_tag: 'Asset Tag',
    billing_status: 'Billing Status',
    bulk_suspend_cancel: 'Bulk Suspend/Cancel',
    cancellation_fees_charged: 'Cancellation Fees Charged',
    carrier: 'Carrier',
    carrier_imei: 'Carrier IMEI',
    contract_balance: 'Contract Balance',
    contract_expiry: 'Contract Expiry',
    cost_avg: 'Cost (avg)',
    data_cost_avg: 'Data Cost (avg)',
    data_usage_avg: 'Data Usage (avg)',
    daytime_data_usage: 'Daytime Data Usage',
    daytime_usage_avg: 'Daytime Usage (avg)',
    device_enrollment: 'Device Enrollment',
    device_purchase: 'Device Purchase',
    early_upgrade_cost: 'Early Upgrade Cost',
    early_upgrade_fees: 'Early Upgrade Fees',
    edit_selected: 'Edit Selected',
    eligibility: 'Eligibility (Months)',
    email: 'Email',
    employee_department_name: 'Employee Department Name',
    employee_email: 'Employee Email',
    employee_location: 'Employee Location',
    employee_number: 'Employee Number',
    employee_status: 'Employee Status',
    employee_title: 'Employee Title',
    employee_user_id: 'Employee User ID',
    employment_status: 'Employment Status',
    evenings_and_weekends_usage_avg: 'Evenings & Weekends Usage (avg)',
    evenings_data_usage: 'Evenings Data Usage',
    evergreen_date: 'Evergreen Date',
    export: 'Export',
    features: 'Features',
    features_cost_avg: 'Features Cost (avg)',
    highest_month: 'Highest Month',
    hr_employee_name: 'HR Employee Name',
    int_roaming_days: "Int'l Roaming Days",
    int_roaming_days_cost_total: "Int'l Roaming Days Cost (total)",
    last_note: 'Most Recent Note',
    last_upgraded_date: 'Last Upgraded Date',
    location: 'Location',
    long_distance_continental_cost_avg: 'Long Distance Continental Cost (avg)',
    long_distance_continental_usage_avg: 'Long Distance Continental Usage (avg)',
    long_distance_cost_avg: 'Long Distance Cost (avg)',
    long_distance_domestic_cost_avg: 'Long Distance Domestic Cost (avg)',
    long_distance_domestic_usage_avg: 'Long Distance Domestic Usage (avg)',
    long_distance_international_cost_avg: 'Long Distance International Cost (avg)',
    long_distance_international_usage_avg: 'Long Distance International Usage (avg)',
    long_distance_usage_avg: 'Long Distance Usage (avg)',
    mac_address: 'MAC Address',
    make: 'Make',
    manager: 'Manager',
    mdm_last_update: 'MDM Last Update',
    memory_size: 'Memory Size',
    mms_cost_avg: 'MMS Cost (avg)',
    mms_usage_avg: 'MMS Usage (avg)',
    mobile_to_mobile_usage_avg: 'Mobile to Mobile Usage (avg)',
    model: 'Model',
    name: 'Name',
    number: 'Number',
    premium_messaging_cost_avg: 'Premium Messaging Cost (avg)',
    print: 'Print',
    rate_plan: 'Rate Plan',
    rate_plan_costs_avg: 'Rate Plan Costs (avg)',
    roaming_cost_avg: 'Roaming Cost (avg)',
    roaming_data_cost_avg: 'Roaming Data Cost (avg)',
    roaming_data_usage_avg: 'Roaming Data Usage (avg)',
    roaming_text_cost_avg: 'Roaming Text Cost (avg)',
    roaming_text_usage_avg: 'Roaming Text Usage (avg)',
    roaming_voice_cost_avg: 'Roaming Voice Cost (avg)',
    roaming_voice_usage_avg: 'Roaming Voice Usage (avg)',
    search_by_any_cell: 'Search by any cell value...',
    send_email_sms: 'Send Email/SMS',
    sim: 'SIM',
    sms_cost_avg: 'SMS Cost (avg)',
    sms_usage_avg: 'SMS Usage (avg)',
    static_ip: 'Static IP',
    private_apn_area: 'Private APN Area',
    status: 'Current Status',
    subscription_services_cost_avg: 'Subscription Services Cost (avg)',
    subsidized_plan: 'Subsidized',
    initial_activation_date: 'Initial Activation Date',
    tags: 'Tags',
    termination_date: 'Termination Date',
    total_airtime_usage: 'Total Airtime Usage',
    total_credits: 'Total Credits',
    total_data_cost: 'Total Data Cost',
    total_data_usage: 'Total Data Usage',
    total_gst: 'Total GST',
    total_hardware_charges: 'Total Hardware Charges',
    total_hst: 'Total HST',
    total_long_distance_continental_cost: 'Total Long Distance Continental Cost',
    total_long_distance_continental_usage: 'Total Long Distance Continental Usage',
    total_long_distance_cost: 'Total Long Distance Cost',
    total_long_distance_domestic_cost: 'Total Long Distance Domestic Cost',
    total_long_distance_domestic_usage: 'Total Long Distance Domestic Usage',
    total_long_distance_international_cost: 'Total Long Distance International Cost',
    total_long_distance_international_usage: 'Total Long Distance International Usage',
    total_long_distance_usage: 'Total Long Distance Usage',
    total_pst: 'Total PST',
    total_qst: 'Total QST',
    total_roaming_cost: 'Total Roaming Cost',
    total_sms_usage: 'Total SMS Usage',
    total_spend: 'Total Spend',
    upgrade_status: 'Upgrade Status',
    us_roaming_days: 'US Roaming Days',
    us_roaming_days_cost_total: 'US Roaming Days Cost (total)',
    used_for: 'Used For',
    weekend_data_usage: 'Weekend Data Usage',
  },
  'fr-CA': {
    airtime: "Temps d'Appels",
    all: 'Tous',
    carrier_details: 'Détails Fournisseur',
    cost_and_averages: 'Coût et Moyennes',
    data_and_other_costs: 'Données et Autre Coûts',
    default: 'Défaut',
    device_details: 'Détails Appareil',
    employee_details: 'Détails Employées',
    export_to_csv: 'Exporter au format CSV',
    export_to_excel: 'Exporter au format Excel',
    exporting: 'Exporter...',
    messaging: 'Messagerie',
    none: 'Aucun',
    roaming: 'Itinérance',
    select_all: 'tout sélectionner',
    select_columns: 'Sélectionner colonnes',
    user_details: 'Détails Utilisateur',
    '411_services_cost_avg': 'Coût service 411 (moy)',
    account: 'Compte',
    additional_manager: 'Additionnelle Gestionnaire',
    airtime_cost_avg: "Coût du Temps d'Antenne (moy)",
    airtime_usage_avg: "Usage du Temps d'Antenne (moy)",
    asset_tag: "Numéro d'inventaire",
    billing_status: 'État de Facturation',
    bulk_suspend_cancel: 'Suspension / annulation en masse',
    cancellation_fees_charged: "Frais d'Annulation Facturés",
    carrier: 'Fournisseur',
    carrier_imei: 'IMEI du Fournisseur',
    contract_balance: 'Solde du Contrat',
    contract_expiry: 'Expiration du Contrat',
    cost_avg: 'Coût (moy)',
    data_cost_avg: 'Coût des données (moy)',
    data_usage_avg: 'Usage de données (moy)',
    daytime_data_usage: 'Utilisation des Données pendant le Jour',
    daytime_usage_avg: 'Usage de Jour (moy)',
    device_enrollment: "Inscription de l'appareil",
    device_purchase: "Achat de l'appareil",
    early_upgrade_cost: 'Coût de surclassement précoce',
    early_upgrade_fees: 'Frais de surclassement précoce',
    edit_selected: 'Modifier la sélection',
    eligibility: 'Éligibilité (mois)',
    email: 'Courriel',
    employee_department_name: "Nom du Département de l'Employé",
    employee_email: "Courriel d'Employé",
    employee_location: "Emplacement de l'Employé",
    employee_number: "Numéro de l'Employé",
    employee_status: "Statut d'Employée",
    employee_title: "Titre d'Employé",
    employee_user_id: "ID Utilisateur de l'Employé",
    employment_status: "Statut d'Emploi",
    evenings_and_weekends_usage_avg: 'Usage de soir et fin de semaine (moy)',
    evenings_data_usage: 'Usage de données en soirée',
    evergreen_date: "Surclassement d'Appareil",
    export: 'Exporter',
    features: 'Fonctionnalités',
    features_cost_avg: 'Coût des options (moy)',
    highest_month: 'Mois le plus élevé',
    hr_employee_name: "Nom de l'employé RH",
    int_roaming_days_cost_total: 'Coût des jours en itinérance Intl (total)',
    last_note: 'Note la plus récente',
    last_upgraded_date: 'Dernière Mise à Jour',
    location: 'Emplacement',
    long_distance_continental_cost_avg: "Coût d'interrurbain continentale (moy)",
    long_distance_continental_usage_avg: "Usage d'interrurbain continentale (moy)",
    long_distance_cost_avg: "Coût d'interrurbain (moy)",
    long_distance_domestic_cost_avg: "Coût d'interrurbain domestique (moy)",
    long_distance_domestic_usage_avg: "Usage d'interrurbain domestique (moy)",
    long_distance_international_cost_avg: "Coût d'interrurbain internationale (moy)",
    long_distance_international_usage_avg: "Usage d'interrurbain internationale (moy)",
    long_distance_usage_avg: "Usage d'interrurbain (moy)",
    mac_address: 'Adresse Mac',
    make: 'Fabricant',
    manager: 'Gestionnaire',
    mdm_last_update: 'Dernière mise à jour MDM',
    memory_size: 'Capacité de mémoire',
    mms_cost_avg: 'Coût MMS (moy)',
    mms_usage_avg: 'Usage MMS (moy)',
    mobile_to_mobile_usage_avg: 'Usage de Portable à Portable (moy)',
    model: 'Modèle',
    name: 'Nom',
    number: 'Numéro',
    premium_messaging_cost_avg: 'Coût de messagerie vip (moy)',
    print: 'Imprimer',
    rate_plan: 'Plan Tarifaire',
    rate_plan_costs_avg: 'Coût du plan tarifaire',
    roaming_cost_avg: "Coût d'itinérance (moy)",
    roaming_data_cost_avg: 'Coût des données en itinérance  (moy)',
    roaming_data_usage_avg: 'Usage des données en itinérance (moy)',
    roaming_text_cost_avg: 'Coût de textes en itinérance (moy)',
    roaming_text_usage_avg: 'Usage de textes en itinérance (moy)',
    roaming_voice_cost_avg: 'Coût de voix en itinérance (moy)',
    roaming_voice_usage_avg: 'Usage de voix en itinérance (moy)',
    search_by_any_cell: "Recherche par n'importe quelle valeur de cellule ...",
    send_email_sms: 'Envoy de courriel / SMS',
    sim: 'SIM',
    sms_cost_avg: 'Coût SMS (moy)',
    sms_usage_avg: 'Usage SMS (moy)',
    static_ip: 'IP statique',
    private_apn_area: 'Domaine APN privé',
    status: 'État Actuel',
    subscription_services_cost_avg: "Coût d'abonnement aux services (moy)",
    subsidized_plan: 'Subventionné',
    initial_activation_date: "Date d''activation initiale",
    tags: 'Étiquettes',
    termination_date: 'Date de fin de service',
    total_airtime_usage: "Usage du Temps d'Antenne total",
    total_credits: 'Crédits totaux',
    total_data_cost: 'Coût totale des données',
    total_data_usage: 'Usage Total de Données',
    total_gst: 'Total TPS',
    total_hardware_charges: 'Total des Frais de Matériel',
    total_hst: 'Total TVH',
    total_long_distance_continental_cost: "Coût total d'interrurbain continentale",
    total_long_distance_continental_usage: "Usage total d'interrurbain continentale",
    total_long_distance_cost: "Coût total d'interrurbain",
    total_long_distance_domestic_cost: "Coût total d'interrurbain domestique",
    total_long_distance_domestic_usage: "Usage total d'interrurbain domestique",
    total_long_distance_international_cost: "Coût total d'interrurbain internationale",
    total_long_distance_international_usage: "Usage total d'interrurbain internationale",
    total_long_distance_usage: "Usage total d'interrurbain",
    total_pst: 'Total TVP',
    total_qst: 'Total TVQ',
    total_roaming_cost: "Coût Total d'Itinérance",
    total_sms_usage: 'Usage SMS totale',
    total_spend: 'Dépense Totale',
    upgrade_status: 'Statut de Surclassement',
    us_roaming_days: 'Jours en itinérance USA',
    us_roaming_days_cost_total: 'Coût des jours en itinérance USA (total)',
    used_for: 'Utilisé pour',
    weekend_data_usage: 'Usage de données en fin de semaine',
  },
})

export const { t } = useI18n({ messages })
