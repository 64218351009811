import angular from 'angular'

// TODO: repeated model should include new value?
// and label for value in VALUES - values should include new value
angular.module('ArgonModule').directive('ngSelect2', [
  '$timeout',
  '$parse',
  function ($timeout, $parse) {
    return {
      require: '?ngModel',
      link: ($scope, element, attrs, $ngModel) => {
        const $element = $(element)
        let options

        if (!$element.data('select2')) {
          // #|| attrs.ngSelect2 - warning! rails helper save true as "true"
          options = {}
          const data = $element.data('select')

          if (data instanceof Object) jQuery.extend(options, data)

          options.formatResult = (result) => {
            let text = result.text.replace('\n', '<br>', 'g')
            text = text.replace(/(.*?)<br>/, '<b>$1</b><br>')
            return text
          }

          // I don't know how display it correctly now so I hided it.
          // .select2-container .select2-choice
          // !Hardware ordering Inventory Select.
          options.formatSelection = (selection) => {
            let result = selection instanceof Object ? selection.text : selection
            if (!result) result = ''
            return result.replace('\n', '<br><br>', 'g')
          }
          $element.data('select2options', options).select2(options)
        }

        if (!$ngModel) return

        // Enable watching of the options dataset if in use
        // timeout because options should exists.
        $timeout(() => {
          const repeatedModel = (element) => {
            const selectors =
              'optgroup[ng-repeat], optgroup[data-ng-repeat], option[ng-repeat], option[data-ng-repeat]'
            options = element.find(selectors)

            let repeatedModel = null

            if (options.length > 0) {
              for (let i = 0; i <= options.length; i++) {
                const repeatAttr = options.attr('ng-repeat') || options.attr('data-ng-repeat')
                if (repeatAttr)
                  repeatedModel = jQuery.trim(repeatAttr.split('|')[0]).split(' ').pop()
                if (repeatedModel) break
              }
            }

            return repeatedModel
          }

          const watchRepeatedModel = (repeatedModel) => {
            if (!repeatedModel) return false
            return $scope.$watch(repeatedModel, () => {
              $timeout(() => {
                options = $element.data('select2options')
                element.select2('destroy')
                element.select2(options)
              })
            })
          }

          if (!attrs.ngOptions) {
            watchRepeatedModel(repeatedModel(element))
          }
        })

        // If model changes
        const model = () => $ngModel.$modelValue

        const watchFn = (nV) => {
          if (typeof nV == 'undefined') return
          if (nV instanceof Object && attrs.ngOptions) {
            // https://github.com/angular/angular.js/blob/master/src/ng/directive/select.js#L327
            const NG_OPTIONS_REGEXP =
              /^\s*([\s\S]+?)(?:\s+as\s+([\s\S]+?))?(?:\s+group\s+by\s+([\s\S]+?))?\s+for\s+(?:([$\w][$\w]*)|(?:\(\s*([$\w][$\w]*)\s*,\s*([$\w][$\w]*)\s*\)))\s+in\s+([\s\S]+?)(?:\s+track\s+by\s+([\s\S]+?))?$/
            const match = attrs.ngOptions.match(NG_OPTIONS_REGEXP)
            // LABEL for value in values
            const displayFn = $parse(match[2] || match[1])
            // label for VALUE in values
            const valueName = match[4] || match[6]

            // !Maybe wrong.
            if (nV instanceof Array) {
              nV.forEach((item) => {
                $scope[valueName] = item
                if (!item.text) item.text = displayFn($scope)
              })
            } else {
              $scope[valueName] = nV
              if (!nV.text) nV.text = displayFn($scope)
            }

            return element.select2('data', nV)
          } else {
            if (options.tags) nV = [nV]
            // set the value asynchronously
            return $timeout(() => element.select2('val', nV))
          }
        }

        $scope.$watch(model, watchFn, true)

        const changeModel = () => {
          if (element.attr('multiple') || $ngModel.$modelValue instanceof Object) {
            return ($ngModel.$modelValue = element.select2('data'))
          } else {
            return ($ngModel.$modelValue = element.select2('val'))
          }
        }

        // If selected value changes
        element.on('change', () => changeModel())
      },
    }
  },
])
