$(() => {
  const container = $('@contactsNewContainer').add('@contactsEditContainer')
  if (!container[0]) return
  container.on('click', '@addAdditionalContactInfo', (e) => $(e.target).remove())
})

$(() => {
  $('#contact_role_ids')
    .change(function () {
      const showCarrierName = $('option:selected', this)
        .text()
        .match(/Carrier.*?Contact/)
      $('#carrier_name_group').parents('.panel.panel-default').toggle(!!showCarrierName)
      $('#contact_carrier_name').attr('disabled', !showCarrierName)
    })
    .trigger('change')
})
