import swal from 'sweetalert'

$(() => {
  const $element = $('.page-inventory-index')
  if ($element.length === 0) return

  const $importModal = $element.find('#inventory-import-modal')
  const $importButton = $element.find('#inventory-import-button')
  const $links = $element.find('.inventory-actions a')

  $importButton.click((event) => {
    event.preventDefault()
    $importModal.modal('show')
  })

  const findRow = ($button) => $button.closest('tr')
  const findError = ($button) => findRow($button).find('td.inventory-error')

  $links.on('ajax:before', (e) => findError($(e.target)).empty())
  $links.on('ajax:success', (e) => {
    const $row = findRow($(e.target))
    $row.fadeOut(150, () => $row.remove())
  })

  $links.on('ajax:error', (e, data) => {
    const error = data.responseJSON?.inventory?.join(',\n')
    swal({
      title: 'Error while updating inventory',
      text: error,
      icon: 'error',
      allowOutsideClick: true,
    })
    findError($(e.target)).html('!')
  })
})
