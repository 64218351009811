import angular from 'angular'

angular.module('ArgonModule').controller('DeviceUsageRulesCtrl', [
  '$scope',
  '$element',
  function ($scope, $element) {
    $scope.form = $element.data('values')

    const $modal = $element.find('#device-usage-rules-modal .modal')
    const $grid = $element.find('.datagrid')

    $scope.showPreview = () => {
      $modal.find('.modal-dialog').addClass('modal-lg')
      $modal.modal('show')
      $grid.trigger('datagrid-reload', $scope.form)
      return
    }
  },
])
