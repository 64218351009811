import swal from 'sweetalert'
import angular from 'angular'
import { merge, filter, map, isEmpty } from 'lodash-es'
import { Maas360Info } from '../../models/maas360_info'

angular.module('ArgonModule').controller('Maas360DeviceActionsCtrl', [
  '$scope',
  '$timeout',
  function ($scope, $timeout) {
    const VM = this

    VM.init = (params) => {
      VM.urls = merge(
        $('#mdm-maas360-device-urls').data('urls'),
        $('#mdm-maas360-urls').data('urls')
      )
      VM.mdmInfo = { data: new Maas360Info(params.data), isLoading: true }
      VM.availableActions = []
      checkApiAvailability()
    }

    VM.toggleView = () =>
      (VM.mdmInfo.data.displayMode = VM.mdmInfo.data.displayMode === 'short' ? 'full' : 'short')

    VM.lock = () => confirmAction('Are you sure you want to lock this device?', VM.urls.lock)

    VM.wipe = () => confirmAction('Are you sure you want to wipe this device?', VM.urls.wipe)

    VM.enrollDevice = () => {
      if (!VM.urls) return
      $('#mdm-enroll-device').modal('show')
      return false
    }

    VM.displayChangePolicyModal = () => {
      if (!VM.urls) return
      $('#mdm-change-policy').modal('show')
      VM.policies.data = []
      VM.policies.isLoading = true
      $.post(VM.urls.policies, {}, (response) => {
        if (!response.data) return
        VM.policies.isLoading = false
        const published = filter(
          response.data.policies,
          (policy) => policy.policyStatus == 'PUBLISHED'
        )
        VM.policies.data = map(published, 'policyName')
        $scope.$apply()
      })
    }

    VM.displaySecurityCompliance = () => {
      if (!VM.urls) return
      $('#mdm-security-compliance').modal('show')
      VM.securityCompliance.data = []
      VM.securityCompliance.isLoading = true
      $.post(VM.urls.security_compliance, {}, (response) => {
        if (!response.data) return
        VM.securityCompliance.isLoading = false
        VM.securityCompliance.data = filter(response.data, 'value')
        $scope.$apply()
      })
    }

    VM.cancelPendingWipe = () =>
      confirmAction(
        'Are you sure you want to cancel pending wipe for this device?',
        VM.urls.cancel_pending_wipe
      )

    VM.removeDeviceMailboxServer = () =>
      confirmAction(
        'Are you sure you want to remove device mailbox server for this device?',
        VM.urls.remove_mailbox_server
      )

    VM.refreshDeviceInformation = () =>
      confirmAction(
        'Are you sure you want to send request to synchronize device information?',
        VM.urls.request_to_sync_info
      )

    VM.changePolicy = () => {
      $('#mdm-change-policy').modal('hide')
      confirmAction('Are you sure you want to change deivce policy?', VM.urls.change_policy, {
        policy_name: VM.policies.newValue,
      })
    }

    VM.resetDevicePasscode = () => {
      if (!VM.urls) return
      if (VM.resetPasscode.value) {
        $('#mdm-reset-passcode').modal('hide')

        makeRequest(VM.urls.reset_passcode, { passcode: VM.resetPasscode.value })

        VM.resetPasscode.value = ''
        VM.resetPasscode.confirmation = ''
      }
    }

    VM.displayInstalledSoftware = () => {
      if (!VM.urls) return
      $('#mdm-installed-applications').modal('show')
      VM.installedApplications.data = []
      VM.installedApplications.isLoading = true
      $.post(VM.urls.installed_software, {}, (response) => {
        if (!response.data) return
        VM.installedApplications.isLoading = false
        VM.installedApplications.data = response.data
        $scope.$apply()
      })
    }

    VM.displayLocationHistory = () => {
      $('#mdm-location-history').modal('show')
      $timeout(() => $('#reinitialize-locations-history').click())
    }

    VM.isAllowedAction = (actionName) =>
      VM.availableActions.length > 0 && VM.availableActions.indexOf(actionName) >= 0

    VM.fetchMdmInfo = () => {
      if (!VM.urls) return
      VM.mdmInfo.isLoading = true
      $.post(VM.urls.refresh_mdm_info, { force: true }, (response) => {
        if (response.succeeded) {
          VM.mdmInfo.data = new Maas360Info(response.data)
          initializeData()
        }

        VM.mdmInfo.isLoading = false
        $scope.$apply()
      })
    }

    //
    const initializeData = () => {
      VM.installedApplications = { data: [], isLoading: false }
      VM.securityCompliance = { data: [], isLoading: false }
      VM.locationHistory = { data: [], isLoading: false }

      VM.policies = {
        data: [],
        isLoading: false,
        currentValue: VM.mdmInfo.data.getAttributeRawValue('mdm_policy'),
        newValue: VM.mdmInfo.data.getAttributeRawValue('mdm_policy'),
      }

      VM.resetPasscode = {
        value: '',
        confirmation: '',
        minLength: 4,
        isValid: isValidPasscode,
        clear: function () {
          this.confirmation = ''
          this.value = ''
        },
      }

      VM.message = {
        title: '',
        text: '',
        maxTitleLength: 30,
        maxTextLength: 255,
        send: sendMessage,
        isValid: isValidMessage,
      }
    }

    const checkApiAvailability = () => {
      if (!VM.urls) return

      $.post(VM.urls.status, {}, (response) => {
        if (response.available) {
          VM.fetchMdmInfo()
          displayActionButtons()
        } else {
          VM.mdmInfo.isLoading = false
          $scope.$apply()
        }
      })
    }

    const displayActionButtons = () => {
      if (!VM.urls) return
      $.post(VM.urls.device_actions, {}, (response) => {
        if (!response.data) return
        VM.availableActions = response.data
        $scope.$apply()
      })
    }

    const isValidMessage = () =>
      !(
        isEmpty(VM.message.title) ||
        isEmpty(VM.message.text) ||
        VM.message.title.length > VM.message.maxTitleLength ||
        VM.message.text.length > VM.message.maxTextLength
      )

    const isValidPasscode = () =>
      !(
        isEmpty(VM.resetPasscode.value) ||
        isEmpty(VM.resetPasscode.confirmation) ||
        VM.resetPasscode.value.length < VM.resetPasscode.minLength ||
        VM.resetPasscode.value != VM.resetPasscode.confirmation
      )

    const sendMessage = () => {
      if (!VM.urls) return
      if (VM.message.title && VM.message.text) {
        $('#mdm-send-message').modal('hide')

        const success = (response) => {
          if (response.succeeded) {
            VM.message.title = ''
            VM.message.text = ''
            $scope.$apply()
          }
        }

        makeRequest(VM.urls.send_message, {
          title: VM.message.title,
          message: VM.message.text,
        }).done(success)
      }
    }

    const confirmAction = (message, url, params) => {
      if (!url) return

      const swal_params = {
        title: 'Confirmation',
        text: message,
        icon: 'warning',
        buttons: true,
      }
      swal(swal_params).then((isConfirmed) => {
        if (!isConfirmed) return
        makeRequest(url, params)
      })
    }

    const makeRequest = (url, params = {}) => {
      const success = (response) => {
        swal({
          title: response.succeeded ? 'Success' : 'Failure',
          text: response.message,
          icon: response.succeeded ? 'info' : 'error',
        })
      }
      const fail = () => swal({ title: 'Failure', text: 'Something went wrong', icon: 'error' })

      return $.post(url, params).done(success).fail(fail)
    }

    return VM
  },
])
