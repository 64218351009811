import { DatatableLib } from '~/old/elements/datatable'
import { customerNumericId } from '~/store'
import {
  cancelled_devices_table_data_customer_reports_retention_path,
  all_devices_table_data_customer_reports_retention_path,
  customer_device_path,
} from '~/helpers/rails-routes'

$(() => {
  const $report = $('@retentionReportShow')
  if ($report.length > 0) new RetentionReport($report)
})

class RetentionReport {
  /** @param {JQuery} $container */
  constructor($container) {
    const cancelled_devices_datatable_data_provider_url =
      cancelled_devices_table_data_customer_reports_retention_path(customerNumericId)
    new DatatableLib(
      $container.find('@cancelledDevicesDataTableRetention'),
      this.datatable_options(cancelled_devices_datatable_data_provider_url)
    )

    const all_devices_datatable_data_provider_url =
      all_devices_table_data_customer_reports_retention_path(customerNumericId)
    new DatatableLib(
      $container.find('@allDevicesDataTableRetention'),
      this.datatable_options(all_devices_datatable_data_provider_url)
    )
  }

  device_link_fn(data) {
    const id = data.split('*')[0]
    const text = data.split('*')[1]
    const link = customer_device_path(customerNumericId, id)
    return '<a target="_blank" href="' + link + '">' + text + '</a>'
  }

  datatable_options(data_provider_url) {
    return {
      ajax: data_provider_url,
      sort: true,
      bFilter: true,
      order: [[1, 'desc']],
      iDisplayLength: 15,
      columns: [
        { data: 'username', render: this.device_link_fn },
        { data: 'number', render: this.device_link_fn },
        { data: 'first_billing_month' },
        { data: 'last_billing_month' },
        { data: 'months_count', className: 'dt-right' },
        { data: 'spread', bVisible: false, bSearchable: true },
      ],
    }
  }
}
