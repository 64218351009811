import angular from 'angular'

$(document).on('ajax:success', '[data-remote][role=lazy_modal]', (e, data) => {
  $('.lazy.modal').remove()
  const newElement = $(data).appendTo('body')

  angular
    .element('body')
    .injector()
    .invoke([
      '$rootScope',
      '$compile',
      function ($rootScope, $compile) {
        $compile(newElement)($rootScope.$new())
        $rootScope.$apply()
      },
    ])

  newElement.modal('show')
})
