$(() => {
  const form_selector = '[role=hardware_ordering_new_accounting_category_modal] form'

  $(document).on('ajax:success', form_selector, (e, data) => {
    const category = data.accounting_category
    const accounting_type_id = category.accounting_type_id
    const select_input = $(`select[role='accounting_type_${accounting_type_id}_code_input']`)
    const ajax_input = $(`input[role='accounting_type_${accounting_type_id}_code_input']`)
    if (select_input.length > 0) {
      // yes, now it's use name for value
      // related to hardware ordering business unit form.
      const newOption = new Option(category.name, category.name, false, true)
      select_input.append(newOption).trigger('change')
    } else if (ajax_input.length > 0) {
      ajax_input.select2('data', { id: category.name, text: category.name })
    }
    $(e.target).parents('.modal').modal('hide')
  })

  $(document).on('ajax:error', form_selector, (e, xhr) => {
    const alert = $(e.target).find('.alert-danger')
    alert.html(xhr.responseJSON.errors.join('<br>'))
    alert.removeClass('hidden')
  })
})
