export class DatatableLib {
  constructor($container, options = {}) {
    this.$container = $container
    this.options = options
    this.table = this.$container.DataTable($.extend(true, {}, this.default_options(), this.options))
  }

  default_options = () => ({
    sort: false,
    bFilter: false,
    iDisplayLength: 25,
    bLengthChange: false,
  })
}

$.fn.dataTableExt.oSort['numeric-file-size-asc'] = (a, b) => {
  if (fileSizeUnify(a) < fileSizeUnify(b)) return -1
  else if (fileSizeUnify(a) > fileSizeUnify(b)) return 1
  else return 0
}

$.fn.dataTableExt.oSort['numeric-file-size-desc'] = (a, b) => {
  if (fileSizeUnify(a) < fileSizeUnify(b)) return 1
  else if (fileSizeUnify(a) > fileSizeUnify(b)) return -1
  else return 0
}

const fileSizeUnify = (size) => {
  if (new RegExp('TB').test(size))
    return parseFloat(size.replace(/TB/, '')) * 1024 * 1024 * 1024 * 1024
  else if (new RegExp('GB').test(size))
    return parseFloat(size.replace(/GB/, '')) * 1024 * 1024 * 1024
  else if (new RegExp('MB').test(size)) return parseFloat(size.replace(/MB/, '')) * 1024 * 1024
  else if (new RegExp('KB').test(size)) return parseFloat(size.replace(/KB/, '')) * 1024
  else if (new RegExp('Bytes').test(size)) return parseFloat(size.replace(/Bytes/, ''))
}
