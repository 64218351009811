$(() => {
  const DESCRIPTION_COLUMN_INDEX = 5

  $('#carrier_features').DataTable({
    bPaginate: true,
    bInfo: false,
    dom: 'Bfrtip',
    columnDefs: [
      {
        targets: DESCRIPTION_COLUMN_INDEX,
        render: (data, type) => {
          const cutoff = 20
          if (type === 'display' && data.length > cutoff) {
            return `<span title='${data}'>${data.substr(0, cutoff)}…</span>`
          }
          return data
        },
      },
    ],
    buttons: [
      {
        extend: 'colvis',
        columns: ':not(.noVis)',
        className: 'btn btn-sm btn-success',
      },
    ],
  })
})
