$(() => {
  $.fn.clipboardShortcut = function () {
    const delay = (ms, func) => setTimeout(func, ms)

    this.each((index, element) => {
      if ($(element).data('clipboard_shortcut_initiated?')) return
      $(element).tooltip({ trigger: 'manual' })

      $(element).data('clipboard_shortcut_initiated?', true)
      $(element).click(() => {
        navigator.clipboard
          .writeText($(element).data('clipboard-text'))
          .then(() => {
            $(element).attr('data-original-title', 'Copied!').tooltip('show')
            delay(1000, () => $(element).tooltip('hide'))
          })
          .catch(() => {
            $(element).attr('data-original-title', 'Failed to copy').tooltip('show')
            delay(2000, () => $(element).tooltip('hide'))
          })
      })
    })
  }

  // Creates a small shortcut button for copying unstyled text to the clipboard
  // Example:
  // %a{ "data-clipboard-text": 'this text will be copied to clipboard', role: "copy-to-clipboard" }
  // %i.glyphicon.glyphicon-copy{ "aria-hidden": true }
  $('@copy-to-clipboard').clipboardShortcut()

  // Looking for copy-to- clipboard buttons in the newly opened popover window
  $('[role="popover"]').on('shown.bs.popover', () => $('@copy-to-clipboard').clipboardShortcut())
})
