import swal from 'sweetalert'

window.business_unit_review_hide_row = (row, message) => {
  swal({ title: '', text: message, icon: 'success' })
  row.remove()
  if (window.table.find('tbody tr').length === 0) {
    window.table.parent().remove()
    window.table.remove()
  }
}
