export const defaultTagColor = '777777'
export const tagColors = [
  defaultTagColor,
  'E74C3C',
  'E67E22',
  '9C8061',
  'F1C40F',
  '72C02C',
  '1ABC9C',
  '27D7E7',
  '3498DB',
  '4765A0',
  '9B6BCC',
]
