import angular from 'angular'

angular.module('ArgonModule').directive('ngPopover', [
  '$timeout',
  function () {
    return {
      link: ($scope, element) => {
        $(element).trigger('argon:createPopover')
      },
    }
  },
])
