$(() => {
  const $container = $('@ordersInvoicesAndReportsContainer')
  if ($container.length === 0) return

  const $completedOrdersSelect = $container.find('@completedOrdersSelect')
  $container.on('ajax:success', '@completedOrdersFilterForm', (e, data) => {
    $completedOrdersSelect.select2('data', data.invoices_and_reports)
    $completedOrdersSelect.change()
  })
})
