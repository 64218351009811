import swal from 'sweetalert'
import angular from 'angular'
import { merge } from 'lodash-es'
import { AirwatchInfo } from '../../models/airwatch_info'

angular.module('ArgonModule').controller('AirwatchDeviceActionsCtrl', [
  '$scope',
  '$timeout',
  'formatter',
  function ($scope, $timeout, formatter) {
    const VM = this

    VM.init = (params) => {
      VM.formatter = formatter

      VM.urls = merge(
        $('#mdm-airwatch-device-urls').data('urls'),
        $('#mdm-airwatch-urls').data('urls')
      )
      VM.availableActions = []
      VM.mdmInfo = { data: new AirwatchInfo(params.data), isLoading: false }
      initializeData(params.data)

      checkApiAvailability()
    }

    VM.toggleView = () =>
      (VM.mdmInfo.data.displayMode = VM.mdmInfo.data.displayMode === 'short' ? 'full' : 'short')

    VM.lock = () => {
      if (!(VM.urls && VM.urls.lock)) return
      makeRequest(VM.urls.lock)
    }

    VM.displayCompliance = () => {
      if (!(VM.urls && VM.urls.compliance)) return
      $('#mdm-airwatch-compliance').modal('show')
      VM.compliance.data = []
      VM.compliance.isLoading = true
      $.post(VM.urls.compliance, {}, (response) => {
        if (!response.data) return
        VM.compliance.isLoading = false
        VM.compliance.data = response.data
        $scope.$apply()
      })
    }

    VM.sync = () =>
      confirmAction(
        'Are you sure you want to send request to synchronize device information?',
        VM.urls.sync
      )

    VM.clearPasscode = () =>
      confirmAction(
        'Are you sure you want to clear passcode for this device?',
        VM.urls.clear_passcode
      )

    VM.fullWipe = () =>
      confirmAction('Are you sure you want to wipe this device?', VM.urls.full_wipe)

    VM.enterpriseWipe = () =>
      confirmAction('Are you sure you want to unenroll this device?', VM.urls.enterprise_wipe)

    VM.changePasscode = () => {
      if (!(VM.urls && VM.urls.change_passcode)) return
      if (VM.changePasscode.value) {
        $('#mdm-airwatch-change-passcode').modal('hide')
        makeRequest(VM.urls.change_passcode, { passcode: VM.changePasscode.value })
        VM.changePasscode.value = ''
        VM.changePasscode.confirmation = ''
      }
    }

    VM.displaySoftware = () => {
      if (!(VM.urls && VM.urls.software)) return
      $('#mdm-airwatch-applications').modal('show')
      VM.installedApplications.data = []
      VM.installedApplications.isLoading = true
      $.post(VM.urls.software, {}, (response) => {
        VM.installedApplications.isLoading = false
        if (!response.data) return
        VM.installedApplications.data = response.data
        $scope.$apply()
      })
    }

    VM.displayProfiles = () => {
      if (!(VM.urls && VM.urls.profiles)) return
      $('#mdm-airwatch-profiles').modal('show')
      VM.profiles.data = []
      VM.profiles.isLoading = true
      $.post(VM.urls.profiles, {}, (response) => {
        VM.profiles.isLoading = false
        if (!response.data) return
        VM.profiles.data = response.data
        $scope.$apply()
      })
    }

    VM.profileAssignmentType = (id) => {
      switch (id) {
        case 1:
          return 'Auto'
        case 3:
          return 'Optional'
        case 5:
          return 'Compliance'
        default:
          return id
      }
    }

    VM.displaySendMessageDialog = () => {
      $('#mdm-airwatch-send-message').modal('show')
      return
    }

    VM.displayLocationHistory = () => {
      $('#mdm-airwatch-location-history').modal('show')
      $timeout(() => $('#reinitialize-locations-history').click())
    }

    VM.isAllowedAction = (actionName) =>
      VM.availableActions.length > 0 && VM.availableActions.indexOf(actionName) >= 0

    VM.fetchMdmInfo = () => {
      if (!(VM.urls && VM.urls.refresh_mdm_info)) return
      VM.mdmInfo.isLoading = true
      $.post(VM.urls.refresh_mdm_info, { force: true }, (response) => {
        VM.mdmInfo.data = new AirwatchInfo(response.data)
        initializeData(response.data)
        VM.mdmInfo.isLoading = false
        $scope.$apply()
      })
    }

    //

    const initializeData = (data) => {
      VM.installedApplications = { data: [], isLoading: false }
      VM.compliance = { data: [], isLoading: false }
      VM.profiles = { data: [], isLoading: false }

      VM.send_message = {
        // possible values: 'email', 'push' and 'sms'
        type: 'email',
        text: '',
        app_name: 'AirWatch Agent',
        phone_number: data.base['PhoneNumber'],
        email: data.base['UserEmailAddress'],
        maxPushNotificationLength: (function () {
          switch (data.base['Platform']) {
            case 'Apple':
              return 208
            case 'Android':
              return 1500
            default:
              return -1
          }
        })(),
      }
    }

    const checkApiAvailability = () => {
      if (!VM.urls) return

      $.post(VM.urls.status, {}, (response) => {
        if (response.available) {
          VM.fetchMdmInfo()
          displayActionButtons()
        } else {
          VM.mdmInfo.isLoading = false
        }
      })
    }

    const displayActionButtons = () => {
      if (!(VM.urls && VM.urls.device_actions)) return
      $.post(VM.urls.device_actions, {}, (response) => {
        if (!response.data) return
        VM.availableActions = response.data
        $scope.$apply()
      })
    }

    const confirmAction = (message, url, params) => {
      if (!url) return

      const swal_params = {
        title: 'Confirmation',
        text: message,
        icon: 'warning',
        buttons: true,
      }

      swal(swal_params).then((isConfirmed) => {
        if (!isConfirmed) return
        makeRequest(url, params)
      })
    }

    const makeRequest = (url, params = {}) => {
      const success = (response) => {
        swal({
          title: response.succeeded ? 'Success' : 'Failure',
          text: response.message,
          icon: response.succeeded ? 'info' : 'error',
        })
      }
      const fail = () => swal({ title: 'Failure', text: 'Something went wrong', icon: 'error' })

      return $.post(url, params).done(success).fail(fail)
    }

    return VM
  },
])
