import angular from 'angular'
import { CanadianCitiesSearch } from '../models/canadian_cities_search'

angular.module('ArgonModule').directive('canadianCitiesSearch', [
  '$timeout',
  function ($timeout) {
    return {
      require: '?ngModel',
      link: ($scope, element, attributes, $ngModel) => {
        const search = new CanadianCitiesSearch(element)
        $timeout(() => search.activate())

        // If given model changes
        const model_getter = () => $ngModel.$modelValue
        $scope.$watch(model_getter, (nV) => $timeout(() => search.chooseOption(nV)))

        // If selected value changes
        element.on('change', () => {
          $ngModel.$modelValue = element.select2('data').id
          return $timeout(() => true)
        })
      },
    }
  },
])
