import angular from 'angular'
import { customer_orderable_suspended_lines_path } from '~/helpers/rails-routes'

function controller($scope, $searchFilters) {
  const ctrl = this

  ctrl.filters = $searchFilters
  ctrl.urlForSuspendedLines = null

  const filtersWatcher = () => {
    if (ctrl.filters.isSuspendedLinesMode()) {
      ctrl.loadSuspendedLines()
    } else {
      // hide template
      ctrl.urlForSuspendedLines = null
    }
  }

  $scope.ctrl = ctrl
  $scope.$watch('ctrl.filters', filtersWatcher, true)

  $scope.$on('$includeContentRequested', () => (ctrl.loaded = false))
  $scope.$on('$includeContentLoaded', () => (ctrl.loaded = true))

  const urlForSuspendedLines = () => {
    if (!ctrl.filters.customer_id) return

    const options = {
      customer_id: ctrl.filters.customer_id,
    }

    // true if order has some items.
    if (ctrl.filters.required_carrier_id) {
      options.carrier_base_ids = [ctrl.filters.required_carrier_id]
    }

    return customer_orderable_suspended_lines_path(options)
  }

  // $event needed for pagination
  // angular will render template by url
  const loadSuspendedLines = ($event = null) => {
    if ($event) $event.preventDefault()
    const customUrl = $event?.target.href

    return (ctrl.urlForSuspendedLines = customUrl || urlForSuspendedLines())
  }

  ctrl.loadSuspendedLines = loadSuspendedLines

  return ctrl
}

controller.$inject = ['$scope', '$hwSearchFilters']

angular.module('argon.hardwareOrdering').controller('hwOrderableSuspendedLinesCtrl', controller)
