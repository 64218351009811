import { each } from 'lodash-es'
import { DatatableLib } from '~/old/elements/datatable'
import {
  index_datatable_customer_accounting_categories_path,
  edit_customer_accounting_category_path,
  customer_accounting_category_path,
  retire_customer_accounting_category_path,
  unretire_customer_accounting_category_path,
} from '~/helpers/rails-routes'

const updateStateForClosestSubmitButton = (element) => {
  const form = $(element).closest('form')
  const checked = form.find('input[name="accounting_category_ids[]"]:checked').length > 0
  const button = form.find('input[type=submit]')

  button.attr('disabled', !checked)
}

$(() => {
  $('input[role="select-all"]').on('click', function () {
    const $target_table = $(this).closest('table')
    const $checkboxes = $target_table.find('input[name="accounting_category_ids[]"]')
    if (this.type === 'button') {
      $target_table.find('input[role="select-all"]').prop('checked', !this.checked)
      this.value = this.checked ? 'Select None' : 'Select All'
    }
    $checkboxes.prop('checked', this.checked)
    updateStateForClosestSubmitButton(this)
  })

  $('.tabs-container').on('click', 'input[role="ac-checkbox"]', function () {
    updateStateForClosestSubmitButton(this)
  })
})

$(() => new AccountingCategoriesIndex($('@accountingCategoriesIndexContainer')))

class AccountingCategoriesIndex {
  /** @param {JQuery} $container */
  constructor($main_container) {
    if ($main_container.length === 0) return

    $main_container.on('shown.bs.tab', '@tab', (e) => {
      const $datatables = $($(e.target).attr('href')).find('@categoriesDatatable')
      each($datatables, (container) => {
        const $container = $(container)
        const customer_id = $('body').data('customer-id')
        const type_id = $container.data('type-id')
        const show_approval_managers = $container.data('show-approval-managers') !== undefined
        const path = index_datatable_customer_accounting_categories_path(customer_id)
        new DatatableLib(
          $container,
          this.datatable_options(
            path,
            type_id,
            this.table_columns(show_approval_managers),
            this.currentScope()
          )
        )
      })
    })

    const $datatablesInNotActiveScope = $('.tab-pane.active @categoriesDatatable')
    each($datatablesInNotActiveScope, (container) => {
      const $container = $(container)
      const customer_id = $('body').data('customer-id')
      const type_id = $container.data('type-id')
      const show_approval_managers = $container.data('show-approval-managers') !== undefined
      const path = index_datatable_customer_accounting_categories_path(customer_id)
      new DatatableLib(
        $container,
        this.datatable_options(
          path,
          type_id,
          this.table_columns(show_approval_managers),
          this.currentScope()
        )
      )
    })
  }

  datatable_options(data_provider_url, type_id, table_columns, categories_type) {
    return {
      ajax: {
        url: data_provider_url,
        type: 'POST',
        data: {
          type_id: type_id,
          categories_type: categories_type,
        },
      },
      iDisplayLength: 10,
      bProcessing: true,
      bServerSide: true,
      bFilter: true,
      searchDelay: 500,
      ordering: true,
      order: [1, 'asc'],
      columns: table_columns,
    }
  }

  table_columns(show_approval_managers) {
    const columns = [
      { data: 'id', render: this.checkbox_fn.bind(this), orderable: false },
      { data: 'name', className: 'dt-right' },
    ]
    if (show_approval_managers) {
      columns.push({ data: 'approval_managers_names', className: 'dt-right', orderable: false })
    }
    return columns.concat([
      { data: 'description', className: 'dt-right' },
      {
        data: 'btns_data',
        className: 'dt-right',
        render: this.btns_fn.bind(this),
        orderable: false,
      },
    ])
  }

  checkbox_fn(data, type, full) {
    const disabled_class = this.editingLocked() ? "disabled='disabled'" : ''
    let html = `<input type='checkbox' name='accounting_category_ids[]' ${disabled_class}`
    html += ` id='accounting_category_ids_' value='${full.id}' role='ac-checkbox'>`
    return html
  }

  btns_fn(data, type, full) {
    const customer_id = $('body').data('customer-id')
    const edit_href = edit_customer_accounting_category_path(customer_id, full.id)
    const view_href = customer_accounting_category_path(customer_id, full.id)
    const view_edit_href = this.editingLocked() ? view_href : edit_href
    const button_show = this.editingLocked() ? 'View' : 'Edit'
    const retire_href = retire_customer_accounting_category_path(customer_id, full.id)
    const unretire_href = unretire_customer_accounting_category_path(customer_id, full.id)
    const disabled_class = this.editingLocked() ? 'disabled' : ''

    let html = "<a class='btn btn-primary primary-branded-background btn-sm' "
    html += `href='${view_edit_href}'>${button_show}</a>`
    if (/retired/i.test(this.currentScope())) {
      html += `<a data-confirm='Are you sure?' class='btn btn-danger btn-sm ${disabled_class}' `
      html += `rel='nofollow' data-method='post' href='${unretire_href}'>Unretire</a>`
    } else {
      html += `<a data-confirm='Are you sure?' class='btn btn-danger btn-sm ${disabled_class}' `
      html += `rel='nofollow' data-method='post' href='${retire_href}'>Retire</a>`
    }
    return html
  }

  currentScope() {
    return $('@tablist > li.active').data('categories-type')
  }

  editingLocked() {
    return $('@categoriesDatatable').data('editing-locked') !== undefined
  }
}
