import { each } from 'lodash-es'
import { customer_settings_accounting_type_accounting_categories_path } from '~/helpers/rails-routes'

$(() => new UserEdit($('@userEdit')))

class UserEdit {
  /**
   *
   * @param {JQuery} $container
   */
  constructor($container) {
    if ($container.length === 0) return
    const customer_id = $('body').data('customer-id')
    const $userRestrictions = $container.find('@userRestrictionsAccountingCategories')
    const $BusinessAccounts = $container.find('@userRestrictionsBusinessAccounts')
    this.disableCheckboxes()
    $BusinessAccounts.on(
      'change',
      function () {
        this.disableCheckboxes()
      }.bind(this)
    )

    if ($userRestrictions.length === 0) return
    const type_id = $userRestrictions.data('type').id

    $userRestrictions
      .select2({
        ajax: {
          placeholder: 'Select category...',
          url: customer_settings_accounting_type_accounting_categories_path(customer_id, type_id),
          dataType: 'json',
          delay: 250,
          data: (params) => ({ term: params, page_limit: 10 }),
          results: (data) => {
            return {
              results: $.map(data.data, (item) => ({ text: item.name, id: item.id })),
            }
          },
          cache: true,
        },
        multiple: true,
        minimumInputLength: 1,
        initSelection: (element, callback) => {
          const data = []
          const categories = element.data('default')
          each(categories, (category) => {
            if (category && category[0]) {
              data.push({ id: category[0], text: category[1] })
            }
          })
          callback(data)
        },
        formatResult: this.formatRepo.bind(this),
        formatSelection: this.formatRepoSelection.bind(this),
      })
      .select2('val', $userRestrictions.data('defaultids'))
      .on(
        'change',
        function () {
          this.disableCheckboxes()
        }.bind(this)
      )
  }

  formatRepo(data) {
    return `<option data-id=${data.id} role='accountingCategoryOption'>` + data.text + '</option>'
  }

  formatRepoSelection(data) {
    return (
      `<span role='currentAccountingCategory' data-category='${JSON.stringify(data.id)}'>` +
      `${data.text} </span>`
    )
  }

  disableCheckboxes() {
    const userBusinessAccounts = $('#user_business_accounts')
    const userRestrictionsAccountingCategoryIds = $('#user_restrictions_accounting_category_ids_')
    const accessToSystemSettings = $('#user_restrictions_access_to_system_settings')
    const accessToAdminSettings = $('#user_restrictions_access_to_admin_settings')

    if (
      (userBusinessAccounts.val() && userBusinessAccounts.val().length > 0) ||
      (userRestrictionsAccountingCategoryIds.val() &&
        userRestrictionsAccountingCategoryIds.val().length > 0)
    ) {
      accessToSystemSettings.prop({ disabled: true, checked: false })
      accessToAdminSettings.prop({ disabled: true, checked: false })
    } else {
      accessToSystemSettings.prop('disabled', false)
      accessToAdminSettings.prop('disabled', false)
    }
  }
}
