import angular from 'angular'
import { includes, sumBy } from 'lodash-es'

// Angular BusinessUnitCtrl
angular.module('ArgonModule').controller('BusinessUnitsCtrl', [
  '$scope',
  function ($scope) {
    const VM = this

    const rule_applies = (rule, unit) => {
      // Check if "rule"'s condition is applicable to "unit"
      let result = false
      for (const type_id in rule.when) {
        const value = rule.when[type_id]
        const expected_value = value.category_id + ''
        if (!unit.categories[type_id]) unit.categories[type_id] = ''
        const current_value = unit.categories[type_id] + ''
        if (expected_value == '<any>') {
          if (current_value && !includes(VM.none_categories, current_value)) {
            result = true
          }
        } else {
          if (current_value && current_value == expected_value) {
            result = true
          }
        }
      }
      return result
    }

    const check_rule = (rule, unit) => {
      // Check if "unit" has the same result as "rule"
      let error = false
      for (const type_id in rule.then) {
        const value = rule.then[type_id]
        const expected_value = value.category_id + ''
        const current_value = unit.categories[type_id] + ''
        if (expected_value == '<any>') {
          if (current_value && includes(VM.none_categories, current_value)) {
            error = true
          }
        } else {
          if (current_value && current_value != expected_value) {
            error = true
          }
        }
      }
      if (error) {
        rule.passes = false
        unit.errors.push(rule.description)
      }
    }

    VM.validate_values = () => {
      // mark all rules as passing
      VM.rules.forEach((rule) => (rule.passes = true))
      VM.values.forEach((unit) => {
        unit.errors = []
        VM.rules.forEach((rule) => {
          if (rule_applies(rule, unit)) {
            check_rule(rule, unit)
          }
        })
      })
    }

    VM.validate_percentages_sum = () => sumBy(VM.values, 'percentage') === 100

    $scope.$watch('VM.values', VM.validate_values, true)

    return VM
  },
])
