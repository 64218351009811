import { defineMessages, useI18n } from '~/helpers/use-i18n'

const messages = defineMessages({
  en: {
    cancelled: 'Cancelled',
  },
  'fr-CA': {
    cancelled: 'annulé',
  },
})
const { t } = useI18n({ messages })

$(() => {
  const $form = $('#device_usage_threshold_form')
  const $deviceMake = $form.find('#device_usage_threshold_device_make_ids')
  const $selectGroup = $form.find('.threshold-make-groups a')

  const setDeviceMakes = (link) => {
    $deviceMake.val($(link).data('make-ids'))
    $deviceMake.trigger('change')
  }

  $selectGroup.click(function () {
    setDeviceMakes(this)
  })

  const $devices = $('#device_usage_threshold_device_ids')
  if (!$devices.length) return

  const devicesData = $devices.data()

  $devices.select2({
    multiple: true,
    minimumInputLength: 2,
    formatSelection: (device) => {
      return device.status === 'cancelled'
      ? `<span class='text-danger'>${device.text} (${t('cancelled')})</span>`
      : device.text
    },
    initSelection: (el, cb) => cb(devicesData.options),
    ajax: {
      quietMillis: 300,
      url: devicesData.url,
      data: (term) => ({ term }),
      results: (res) => ({ results: res }),
    },
  })
  $devices.val(devicesData.values).trigger('change')
})

