$(() => new ReportsRoamingNew($('@reportsRoamingNewContainer')))

class ReportsRoamingNew {
  /**
   *
   * @param {JQuery} $container
   */
  constructor($container) {
    if ($container.length === 0) return
    $container.find('@reportsRoamingNewByUserDatatable').dataTable(this.byUserDatatableParams())
    $container
      .find('@reportsRoamingNewByCountryDatatable')
      .dataTable(this.byCountryDatatableParams())
  }

  byUserDatatableParams() {
    return $.extend(this.datatableParams(), { order: [9, 'desc'] })
  }

  byCountryDatatableParams() {
    return $.extend(this.datatableParams(), { order: [0, 'asc'] })
  }

  datatableParams() {
    return {
      dom: 'Bfrtip',
      pagingType: 'simple_numbers',
      bRetrieve: true,
      bLengthChange: false,
      bFilter: true,
      pageLength: 20,
      buttons: {
        dom: {
          button: {
            className: 'btn btn-sm btn-default margin-top-20 margin-left-15',
          },
          buttonLiner: {
            // prevent button's text wrapping within span tag
            tag: '',
          },
        },
        buttons: [{ extend: 'csv', text: '<i class="fa fa-file-excel-o"></i> Export to CSV' }],
      },
    }
  }
}
