/**
 * @typedef {{selector: string; app: () => Promise<{initFn: (selector: string) => void}>;}} OptionsType
 */

/**
 * @param {OptionsType[]} configs
 * @returns {OptionsType[]}
 */
export const defineJsApps = (configs) => configs

/**
 * @param {OptionsType} options
 * @returns {void}
 */
export const createJsApp = ({ selector, app }) => {
  const initFn = () => {
    // do nothing if no element was found in DOM
    if (!document.querySelector(selector)) return
    // load app if element was found in DOM
    app().then((module) => {
      // check that element is still in DOM
      if (!document.querySelector(selector)) return
      // initFn function have to be exported from app module
      if (!module.initFn) {
        console.error('No "initFn" for:')
        console.error('selector:', selector)
        console.error('module:', module)
      }
      // initialize app
      module.initFn(selector)
    })
  }

  document.addEventListener('DOMContentLoaded', initFn)
}
