import angular from 'angular'

angular.module('ArgonModule').directive('crFilter', () => ({
  restrict: 'E',
  templateUrl: 'custom-report-filter.html',
  transclude: true,
  scope: {
    key: '@',
    column: '@',
    operators: '=',
    title: '@',
    unit: '@',
    vm: '=',
    customBehaviour: '@',
  },
  link: (scope, element, _attrs, _ctrl, transclude) => {
    element.addClass('cr_filter')
    scope.VM = scope.vm
    if (!scope.vm.report.conditions[scope.key]) scope.vm.report.conditions[scope.key] = {}
    scope.condition = scope.vm.report.conditions[scope.key]
    if (!scope.operators) scope.operators = []
    if (!scope.condition.operator) scope.condition.operator = scope.operators[0]?.value || 'is'
    if (!scope.column) scope.column = scope.key

    transclude(scope, (clone, scope) => {
      if (scope.operators.length > 0) {
        element.find('.transclude_placeholder_with_operators').replaceWith(clone)
      }
      if (scope.operators.length == 0) {
        element.find('.transclude_placeholder_without_operators').replaceWith(clone)
      }
    })
  },
}))
