import { DatatableLib } from '~/old/elements/datatable'
import { customer_business_approval_mappings_path } from '~/helpers/rails-routes'

$(() => new ApprovalMappingsIndex($('@approvalMappingsContainer')))

class ApprovalMappingsIndex {
  /**
   *
   * @param {JQuery} $container
   */
  constructor($container) {
    this.$container = $container

    const customer_id = $container.data('customer')
    $container.find('.dataTable').each((i, table) => {
      const route_params = {
        customer_id: customer_id,
        state: $(table).data('state'),
      }
      const data_provider_url = customer_business_approval_mappings_path(route_params)
      new DatatableLib($(table), this.datatable_options(data_provider_url))
    })

    $container.on('selected select2-removed', '@contactsForm', (e) => {
      $(e.target).closest('form').trigger('submit')
    })
  }

  datatable_options(data_provider_url) {
    return {
      ajax: data_provider_url,
      sort: true,
      bFilter: true,
      iDisplayLength: 25,
      columns: this.$container.data('accountingTypes'),
    }
  }
}
