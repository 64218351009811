import angular from 'angular'
import { each, find } from 'lodash-es'
import { edit_carrier_carrier_rate_plan_path } from '~/helpers/rails-routes'
import { defineMessages, useI18n } from '~/helpers/use-i18n'

const messages = defineMessages({
  en: {
    approved: 'Approved',
    notApproved: 'Not Approved',
    carrierId: 'Carrier ID',
  },
  'fr-CA': {
    approved: 'Approuvé',
    notApproved: 'Non approuvé',
    carrierId: 'Identification de fournisseur',
  },
})
const { t } = useI18n({ messages })

angular.module('ArgonModule').controller('CustomerRatePlansMapCtrl', [
  '$scope',
  '$element',
  function ($scope, $element) {
    $scope.form = {}
    $scope.defaultBellFeatureNames = [
      'Corporate Data Flex USA',
      'Corporate Data Flex Zone 1',
      'Corporate Data Flex Zone 2',
      'Corporate Data Flex Zone 3',
      'Corporate Text Flex USA',
      'Corporate Text Flex Zone1',
      'Corporate Text Flex Zone2',
      'Corporate Text Flex Zone3',
      'Corporate Voice Flex USA',
      'Corporate Voice Flex Zone1',
      'Corporate Voice Flex Zone2',
      'Corporate Voice Flex Zone3',
      'Message Centre & Call Display Bundle',
      'Messaging Pack Unlimited',
      'Smart email & Internet 6GB',
    ]

    $scope.form.ratePlans = $element.data('ngdata')['rate_plans'] || []
    $scope.permissions = $element.data('ngdata')['permissions'] || {}
    $scope.scrapedBellRatePlansIds = ($element.data('ngdata')['scraped_bell_rate_plans'] || []).map(
      (el) => el.scraped_id
    )

    $scope.form.defaults = {}
    $scope.form.unsubsidized_defaults = {}

    // find defaults value
    for (const device_make_id in $scope.permissions) {
      const permissions = $scope.permissions[device_make_id]
      for (let i = 0, len = permissions.length; i < len; i++) {
        const permission = permissions[i]
        if (permission.default) $scope.form.defaults[device_make_id] = permission
        if (permission.default_unsubsidized)
          $scope.form.unsubsidized_defaults[device_make_id] = permission
      }
    }

    $scope.init = (options = {}) => ($scope.available_features = options.features || [])

    $scope.form.unusedPlans = (current_permission = {}) => {
      const device_make_id = current_permission.device_make_id

      const exists_ids = $scope.permissions[device_make_id].map(
        (permission) => permission.carrier_rate_plan?.id ?? 0
      )

      return $scope.form.ratePlans.filter((plan) => {
        if (plan.device_make_ids.indexOf(device_make_id) == -1) return false
        if (Number(plan.id) == Number(current_permission.carrier_rate_plan.id)) return true
        return exists_ids.indexOf(Number(plan.id)) == -1
      })
    }

    $scope.form.setDefaults = (device_make_id) => {
      $scope.permissions[device_make_id].forEach((permission) => (permission.default = false))

      if (!$scope.form.defaults[device_make_id]) $scope.form.defaults[device_make_id] = {}
      $scope.form.defaults[device_make_id].default = true
    }

    $scope.form.setDefaultUnsubsidized = (device_make_id) => {
      $scope.permissions[device_make_id].forEach(
        (permission) => (permission.default_unsubsidized = false)
      )

      if (!$scope.form.unsubsidized_defaults[device_make_id])
        $scope.form.unsubsidized_defaults[device_make_id] = {}
      $scope.form.unsubsidized_defaults[device_make_id].default_unsubsidized = true
    }

    $scope.addDefaultBellFeatures = (permission) => {
      each($scope.defaultBellFeatureNames, (name) => {
        const feature = find($scope.available_features[permission.device_make_id], {
          name: name,
        })
        if (feature) permission.carrier_features.push(feature)
      })
    }

    $scope.addPermission = (device_make_id) => {
      if (!$scope.permissions[device_make_id]) $scope.permissions[device_make_id] = []

      const newPermission = {
        device_make_id: device_make_id,
        carrier_rate_plan: { name: '', id: '' },
        hardware_ordering: true,
        carrier_features: [],
        default_unsubsidized: false,
      }

      $scope.permissions[device_make_id].push(newPermission)
      if (!$scope.form.defaults[device_make_id]) {
        $scope.form.defaults[device_make_id] = newPermission
      }
      $scope.form.defaults[device_make_id].default = true
    }

    $scope.removePermission = (permission) => {
      const device_make_id = permission.device_make_id

      if (permission.id != null) {
        permission['_destroy'] = '1'
      } else {
        const index = $scope.permissions[device_make_id].indexOf(permission)
        $scope.permissions[device_make_id].splice(index, 1)
      }

      if (permission.default) {
        permission.default = false
        removeDefaults(device_make_id)
      }

      if (permission.default_unsubsidized) {
        permission.default_unsubsidized = false
        removeDefaultUnsubsidized(device_make_id)
      }
    }

    $scope.link_to_rate_plan = (permission) => {
      const rate_plan = permission.carrier_rate_plan
      const carrier_id = rate_plan.carrier_base_id
      return edit_carrier_carrier_rate_plan_path(carrier_id, rate_plan.id)
    }

    const removeDefaults = (device_make_id) => {
      $scope.form.defaults[device_make_id] = $scope.form.existingPermissions(device_make_id)[0]
      if ($scope.form.defaults[device_make_id]) {
        $scope.form.defaults[device_make_id].default = true
      }
    }

    const removeDefaultUnsubsidized = (device_make_id) => {
      delete $scope.form.unsubsidized_defaults[device_make_id]
    }

    $scope.form.existingPermissions = (device_make_id) =>
      $scope.permissions[device_make_id].filter((permission) => !permission['_destroy'])

    $scope.planBadgeClass = (scrapedId) =>
      $scope.scrapedBellRatePlansIds.includes(scrapedId) ? 'badge badge-green' : 'badge badge-red'

    $scope.planBadgeTitle = (scrapedId) =>
      $scope.scrapedBellRatePlansIds.includes(scrapedId) ? t('approved') : t('notApproved')

    $scope.ratePlanOptionTitle = (plan) => {
      let title = plan.name
      if (plan.scraped_id) {
        title += ` <span class='${$scope.planBadgeClass(plan.scraped_id)}'>${$scope.planBadgeTitle(
          plan.scraped_id
        )}</span>`
        title += ` <span class='badge' data-test="select-scraped-id-${plan.scraped_id}">${t(
          'carrierId'
        )}: ${plan.scraped_id}</span>`
      }

      return title
    }
  },
])
