import angular from 'angular'

angular.module('ArgonModule').controller('DataGridCtrl', [
  '$scope',
  '$element',
  '$timeout',
  function ctrl($scope, $element, $timeout) {
    const $pagination = $element.find('.pagination')
    const $defaultColumn = $element.find('th.datagrid-sort-default')

    const url = $element.data('url')
    const defaultSort = $defaultColumn.data('sort')
    const defaultDir = $defaultColumn.data('dir')

    const resetData = () => {
      $scope.data = []
      $scope.isLoading = true
      $scope.isError = false
      $timeout()
    }

    const resetScope = () => {
      $scope.params = {
        page: 1,
        sort: defaultSort,
        dir: defaultDir,
      }
      resetData()
    }

    resetScope()

    $scope.dirClass = (column) => {
      let res = 'fa-sort'
      if ($scope.params.sort == column) {
        const dir = $scope.params.dir || 'asc'
        res += '-' + dir
      }
      return res
    }

    $scope.isResultEmpty = () => !$scope.isLoading && !$scope.isError && $scope.data.length == 0

    const loadComplete = () => {
      $scope.isLoading = false
      $timeout()
    }

    const applyData = (res) => {
      $scope.data = res.data
      $pagination.pagination('enable')
      $pagination.pagination('updateItems', res.total_count)
      $pagination.pagination('updateItemsOnPage', res.per_page)
      $pagination.pagination('drawPage', res.page)
      if (res.total_count <= res.per_page) $pagination.pagination('disable')
      loadComplete()
    }

    const reloadData = () => {
      resetData()
      const args = $.extend({ format: 'json' }, $scope.params)
      const xhr = $.get(url, args, applyData)
      xhr.fail(() => {
        $scope.isError = true
        loadComplete()
      })
    }

    const sortData = function () {
      const column = $(this).data('sort')
      let dir = 'asc'
      if ($scope.params.sort == column && $scope.params.dir == 'asc') dir = 'desc'
      $scope.params.dir = dir
      $scope.params.sort = column
      reloadData()
    }

    const excelExport = () => {
      const path = url.split('?')[0]
      const args = $.extend({ format: 'xlsx' }, $scope.params)
      window.location.href = path + '?' + $.param(args)
    }

    $pagination.pagination({
      prevText: 'Previous',
      onPageClick: (page, event) => {
        if (!event) return
        $scope.params.page = page
        reloadData()
      },
    })
    $pagination.pagination('disable')

    const $autoload = $element.find('.datagrid.datagrid-autoload')
    if ($autoload.length) reloadData()

    const $preload = $element.find('.datagrid.datagrid-preload')
    if ($preload.length) applyData($element.data('values'))

    $element.find('.datagrid').on('datagrid-reload', (e, obj) => {
      resetScope()
      $.extend($scope.params, obj)
      reloadData()
    })

    $element.find('th.datagrid-sort').on('click', sortData)
    $element.find('.datagrid-excel').on('click', excelExport)
  },
])
