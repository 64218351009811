export class ApproversSearch {
  constructor(element) {
    this.element = element
  }

  activate() {
    const $input = $(this.element)

    $input.select2({
      minimumInputLength: 3,
      placeholder: $input.attr('placeholder'),
      url: $input.data('action'),
      ajax: {
        url: $input.data('action'),
        dataType: 'json',
        delay: 250,
        cache: true,
        data: (params) => ({ q: { name_or_email_cont: params } }),
        results: (data) => ({ results: data.approvers }),
      },
      formatNoMatches: () => '',
      formatResult: (approver) => `${approver.name} (${approver.email})`,
      formatSelection: (approver) => `${approver.name} (${approver.email})`,
    })
  }
}
