import angular from 'angular'

function PurchaseOrderCtrl($scope, $attrs, $purchaseOrder) {
  const ctrl = this
  ctrl.purchaseOrder = $purchaseOrder

  const addNewEmail = () => ctrl.purchaseOrder.addEmail('')
  const removeEmail = (index) => ctrl.purchaseOrder.removeEmail(index)

  ctrl.addNewEmail = addNewEmail
  ctrl.removeEmail = removeEmail

  return ctrl
}

angular.module('argon.hardwareOrdering').controller('hwPurchaseOrderCtrl', PurchaseOrderCtrl)

PurchaseOrderCtrl.$inject = ['$scope', '$attrs', '$hwPurchaseOrder']
