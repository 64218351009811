import angular from 'angular'

angular.module('ArgonModule').directive('animateShow', function () {
  return function ($scope, element, attrs) {
    $scope.$watch(attrs.animateShow, (nV) => {
      if (!nV) element.slideUp('slow')
      if (nV) element.slideDown('slow')
    })
  }
})
