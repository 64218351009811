$(() => {
  $('#wireline_charges').dataTable({
    processing: true,
    serverSide: true,
    ajax: location.pathname + '/charges',
    pageLength: 25,
    // Multiple column ordering
    orderMulti: false,
    // default order, new charges first
    order: [[0, 'desc']],
    columnDefs: [{ targets: [3], orderable: false }],
  })

  $('.wireline, .bulk_update').on('cocoon:after-insert', (e, insertedItem) => {
    // Initializing Accounting Codes selects on wirelines#edit
    insertedItem.find('.select2').select2()
  })
})
