$(() => {
  $('@edit-inventory-return-request-status').on('change', () => {
    const option = $('@edit-inventory-return-request-status option:selected').text()
    if (option === 'Sent') {
      $('@edit-inventory-return-request-sent-info-container').show()
      $('@edit-inventory-return-request-info-container').show()
      $('@edit-inventory-return-request-info').val('')
    } else if (option === 'Other') {
      $('@edit-inventory-return-request-sent-info-container').hide()
      $('@edit-inventory-return-request-info-container').show()
      $('@edit-inventory-return-request-info').val('')
    } else {
      $('@edit-inventory-return-request-sent-info-container').hide()
      $('@edit-inventory-return-request-info-container').hide()
      $('@edit-inventory-return-request-info').val(option)
    }
  })
  $('@edit-inventory-return-request-status').change()
})
