$(() => {
  $(document).on('change', '@uploadbutton input[type=file]', function () {
    const filename = $(this).parent('@uploadbutton').find('[role=filename]')
    let new_names = []
    for (let i = 0; this.files.length < i; i++) {
      new_names.push(this.files[i].name)
    }
    new_names = new_names.join('<br>')
    filename[0].innerHTML = new_names
  })
})
