import swal from 'sweetalert'

$(() => {
  $('@deviceRoamingIndex').on('click', '@confirmRequest', (e) => {
    swal({ title: 'Are you sure?', icon: 'warning', buttons: true }).then((isConfirm) => {
      if (!isConfirm) return
      $.ajax({ type: 'POST', dataType: 'json', url: $(e.target).data('url') }).always((json) => {
        if (json.status === 'success') {
          $(e.target).replaceWith(`<span class='green'>${json.message}</span>`)
        } else if (json.status === 'failed') {
          swal({ title: json.message, icon: 'error' })
        }
      })
    })
  })
})
