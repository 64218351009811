import angular from 'angular'

angular.module('ArgonModule').directive('crSelectWirelineColumnsGroup', [
  'crWirelineColumnsService',
  (crWirelineColumnsService) => {
    return {
      restrict: 'E',
      scope: {
        group: '@',
        vm: '=',
      },
      templateUrl: 'cr_select_wireline_columns_group.html',
      link: (scope) => {
        scope.VM = scope.vm
        scope.columns = crWirelineColumnsService.columns_grouped_for_select()[scope.group]
      },
    }
  },
])
