import angular from 'angular'
import { join, floor } from 'lodash-es'
import { formatCurrency, formatNumber, formatPercent } from '~/helpers/format-unit'
import { dataMessages } from '~/helpers/human-size'
import { useI18n } from '~/helpers/use-i18n'

const { t } = useI18n({ messages: dataMessages })

angular.module('ArgonModule').factory('formatter', function () {
  const FMT = this

  FMT.formatPhone = (number) => {
    if (number == undefined || number == '' || number == null) return ''
    return number.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
  }

  FMT.formatMoney = (number) => {
    if (number == undefined || number == '' || number == null) return ''
    if (number == 'Unknown') return 'Unknown'
    return formatCurrency(parseFloat(number) || 0)
  }

  FMT.formatDuration = (number) => {
    if (number == undefined || number == '' || number == null) return ''
    if (number == 'Unknown') return 'Unknown'

    const sec_num = parseInt(number)
    const hours = floor(sec_num / 3600)
    const minutes = floor((sec_num - hours * 3600) / 60)
    const seconds = sec_num - hours * 3600 - minutes * 60

    const result = []
    if (hours > 0) result.push(`${hours} h`)
    if (minutes > 0) result.push(`${minutes} m`)
    if (seconds > 0) result.push(`${seconds} s`)
    return join(result, ' ') || 0
  }

  FMT.formatNumber = (number) => {
    if (number == undefined || number == '' || number == null) return ''
    return formatNumber(parseFloat(number))
  }

  FMT.formatPercentage = (number) => {
    if (number == undefined || number == '' || number == null) return ''
    return formatPercent(parseFloat(number))
  }

  // Accepts the data amount (in megabytes) and returns formatted one.
  // Ever for small values it always formats in gigabytes.
  // The data amount may be number or string.
  // In case of malformed number it will fallback to zero.
  FMT.formatData = (megabytes) => {
    // `parseFloat` may return NaN for malformed numbers.
    const value = parseFloat(megabytes) || 0

    // Display value in KB if it's less than 1 MB
    if (0 <= value < 1) {
      return (value * 1024).toFixed(0) + ' ' + t('kb')
    }

    // Display value in MB if it's less than 1 GB
    if (value < 1024) {
      return value.toFixed(2) + ' ' + t('mb')
    }

    // The numbers of [1;10) are formatted keeping 3 decimals.
    // Other numbers are formatted keeping 2 decimals.
    // The numbers of [0;1) are formatted as 0.00 GB.
    return (value / 1024).toFixed(1 <= value < 10 ? 3 : 2) + ' ' + t('gb')
  }

  return FMT
})
