$(() => {
  const formEl = $('@devices-import-form')
  if (formEl.data('step') !== 'mapping') return

  // Show warning message if 'Number' column isn't selected over one of the mapping selects
  formEl.submit((e) => {
    const numberSelect = formEl
      .find('.devices-import-mapping-select')
      .filter((index, el) => el.value && el.value.toLowerCase() === 'number')
    const button = formEl.find('button[type="submit"]')
    if (numberSelect.length) {
      button.prop('disabled', true).text('Loading...')
    } else {
      e.preventDefault()
      alert(button.data('error-text'))
    }
  })
})
