import {
  new_customers_bell_account_executive_path,
  customers_bell_account_executives_path,
  customer_settings_accounting_type_accounting_categories_path,
} from '~/helpers/rails-routes'

$(() => new DeviceSettingsGeneral($('@deivceSettingsGeneral')))

class DeviceSettingsGeneral {
  /**
   *
   * @param {JQuery} $container
   */
  constructor($container) {
    if ($container.length === 0) return

    const $modal = $('@modalRemote')
    const url = new_customers_bell_account_executive_path({ modal: true })
    const customer_id = $('body').data('customer-id')

    $container.on('click', '@customerSettingsCreateAccountExecutive', (e) => {
      e.preventDefault()
      $modal.find('.modal-body').load(url, () => $modal.modal())
    })

    $(document).on('ajax:success', '@accountExecutiveForm', (e, data) => {
      data.status === 'success'
        ? $modal.modal('hide')
        : $modal.find('.modal-body').load(url, () => $modal.modal())
    })

    $(document).on('ajax:error', '@accountExecutiveForm', () => {
      $modal.find('.modal-body').load(url, () => $modal.modal())
    })

    $('@accountExecutiveSelect').select2({
      ajax: {
        placeholder: 'Select the account executive...',
        url: customers_bell_account_executives_path(),
        dataType: 'json',
        delay: 250,
        data: (params) => ({ term: params, page_limit: 10 }),
        results: (data) => {
          const bell_account_executives = JSON.parse(data.bell_account_executives)
          return {
            results: $.map(bell_account_executives, (item) => {
              return {
                text: {
                  name: item.name,
                  email: item.email,
                },
                id: item.id,
              }
            }),
          }
        },
        cache: true,
      },
      minimumInputLength: 1,
      formatResult: this.accountExecutiveSelectFormatRepo.bind(this),
      formatSelection: this.accountExecutiveSelectFormatRepoSelection.bind(this),
      initSelection: (element, callback) => {
        let data = {}
        const id = element.data('initvalueId')
        const name = element.data('initvalueName')
        const email = element.data('initvalueEmail')
        if (id && name && email) {
          data = { id: id, text: { name: name, email: email } }
          element.val(id)
        }
        callback(data)
      },
    })

    $container.find('@customerSettingsGeneralAccCatSelect').each((_, select2element) => {
      const type_id = $(select2element).data('type').id
      $(select2element).select2({
        initSelection: (element, callback) => {
          let data = element.data('default')[0]
          if (data && data[0]) {
            data = { id: data[0], text: data[1] }
            element.val(data[0])
          }
          callback(data)
        },
        ajax: {
          placeholder: 'Select category...',
          url: customer_settings_accounting_type_accounting_categories_path(customer_id, type_id),
          dataType: 'json',
          delay: 250,
          data: (params) => ({ term: params, page_limit: 10 }),
          results: (data) => {
            return {
              results: $.map(data.data, (item) => {
                return {
                  text: item.name,
                  id: item.id,
                }
              }),
            }
          },
          cache: true,
        },
        minimumInputLength: 3,
        formatResult: this.formatRepo.bind(this),
        formatSelection: this.formatRepoSelection.bind(this),
      })
    })
  }

  formatRepo(data) {
    return `<option data-id=${data.id} role='accountingCategoryOption'>${data.text}</option>`
  }

  formatRepoSelection(data) {
    return `<span role='currentAccountingCategory' data-category='${JSON.stringify(data.id)}'>${
      data.text
    } </span>`
  }

  accountExecutiveSelectFormatRepo(data) {
    let html = ''
    html += `<option data-id=${data.id} role='accountExecutiveOption'>`
    html += data.text.name + ' [' + data.text.email + '] </option>'
    return html
  }

  accountExecutiveSelectFormatRepoSelection(data) {
    if (data?.text) {
      return `<span role='currentAccountExecutive' data-account-executive='${JSON.stringify(
        data.text
      )}'>${data.text.name} [${data.text.email}]</span>`
    }
  }
}
