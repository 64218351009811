import angular from 'angular'

class PurchaseOrder {
  constructor(attributes = {}) {
    this.emails = []
    this.update(attributes)
  }

  update(attributes) {
    for (let attr in attributes) {
      this[attr] = attributes[attr]
    }
  }

  isRequested() {
    return this.status == 'requested'
  }

  isEntered() {
    return this.status == 'entered'
  }

  isNotNeeded() {
    return this.status == 'not_needed'
  }

  addEmail(email) {
    return this.emails.push(email)
  }

  removeEmail(index) {
    return this.emails.splice(index, 1)
  }

  prettyEmailsList() {
    return this.emails.join('\n')
  }
}

angular.module('argon.hardwareOrdering').service('$hwPurchaseOrder', function () {
  return new PurchaseOrder()
})
