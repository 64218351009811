$(() => {
  const $form = $('#notes-form')
  const $category = $form.find('#note_note_category_id')
  const $period = $form.find('#notes-period')

  $category.change(function () {
    const category = $(this).find('option:selected').text()
    category === 'Device Page' ? $period.show() : $period.hide()
  })
})
