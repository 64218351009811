import angular from 'angular'

angular.module('ArgonModule').directive('ngDatepicker', [
  '$timeout',
  function () {
    return {
      link: ($scope, element) => {
        $(element).datepicker({ format: 'yyyy-mm-dd', autoclose: true, todayHighlight: true })
        if ($(element).data('datepicker-startdate')) {
          $(element).datepicker('update', new Date($(element).data('datepicker-startdate')))
        }
      },
    }
  },
])
