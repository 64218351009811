import { includes } from 'lodash-es'

$(() => {
  const remote_modal_selector = '#remote_modal'
  $(document).on('click', '[data-target=remote_modal]', function (e) {
    const $remote_modal = $(remote_modal_selector)

    $remote_modal.modal({
      keyboard: false,
      backdrop: 'static',
    })
    $remote_modal
      .find('.modal-body')
      .empty()
      .addClass('pure-spinner-block ease-out')
      .load($(this).attr('href'), function () {
        $(this).removeClass('pure-spinner-block')
      })
    e.preventDefault()
    return
  })

  $(document)
    .on('ajax:success', remote_modal_selector, (e, data, status, xhr) => {
      $(remote_modal_selector).find('.modal-body').html(xhr.responseText)
      return
    })
    .on('ajax:error', remote_modal_selector, () => {
      return
    })

  $(document).on('show.bs.modal', (e) => {
    const $btn = $(e.relatedTarget)
    const $modal = $(e.target)
    const modalSizes = ['modal-xl', 'modal-lg', 'modal-sm']

    if (includes(modalSizes, $btn.data('size'))) {
      $modal.find('.modal-dialog').addClass($btn.data('size'))
    }

    if (includes(modalSizes, $modal.data('size'))) {
      $modal.find('.modal-dialog').addClass($modal.data('size'))
    }
  })

  $(document).on('hidden.bs.modal', (e) =>
    $(e.target).find('.modal-dialog').removeClass('modal-xl modal-lg modal-sm')
  )
})
