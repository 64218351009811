import swal from 'sweetalert'

$(() => {
  const $inventory = $('#suspension-inventory')

  const handleButtons = (selector, status) => {
    $inventory.find(selector).click((e) => {
      e.stopPropagation()
      e.preventDefault()
      $inventory.find(".tab-pane.active table input[type='radio'][value='" + status + "']").click()
    })
  }

  handleButtons('@suspensionCandidatesSuspendAll', 'suspended')
  handleButtons('@suspensionCandidatesCancelAll', 'cancelled')

  const $tabs = $inventory.find('.suspension-tab')
  const filter = $inventory.find('.tab-content').data('filter')
  const dOpts = {
    aaSorting: [[0, 'desc']],
    search: { search: filter || '' },
    pageLength: 100,
    bLengthChange: false,
  }

  $tabs.each(function () {
    const $tab = $(this)
    const $table = $tab.find('.suspension-report-table')
    const $search = $tab.find('.suspension-search')
    const dTable = $table.DataTable(dOpts)

    $search.val(filter)
    $search.keyup(function () {
      dTable.search($(this).val()).draw()
    })
  })

  // form submit handling
  $inventory.find('@suspensionCandidatesForm').on('submit', (e) => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const selectedDevices = []
    for (const [key, value] of formData) {
      // collecting device ids that have to be suspended/cancelled
      if (value === 'leave' || !key.includes('devices')) continue
      selectedDevices.push({ id: key.match(/\d+/)[0], status: value })
    }
    // no devices selected
    if (!selectedDevices.length) return
    // a lot of devices selected
    const msg = `You've selected ${selectedDevices.length} devices. Do you want to bulk update all of them?`
    if (selectedDevices.length > 100 && !confirm(msg)) {
      return
    }
    // custom event for custom-report-device-suspend-cancel.vue app
    const event = new CustomEvent('showSuspendCancelModal', { detail: selectedDevices })
    window.dispatchEvent(event)
  })
})

$(document).on('click', '@exportToExcel', function (event) {
  event.stopPropagation()
  event.preventDefault()

  // You can find another datatable really.
  const dataTable = $('.suspension-report-table').DataTable()

  const ids = []
  const visible_rows = dataTable.rows({ filter: 'applied' })
  visible_rows.indexes().each((rowIdx) => {
    ids.push($(dataTable.row(rowIdx).node()).data('id'))
  })

  const form = $(this.form)
  form.find('[name=ids]').val(ids)
  form.submit()
})

$(document).on('click', '@allOneOffTrue', function () {
  if ($(this).prop('checked')) {
    $('@selectOneOff').prop('disabled', true).select2('data', null)
  }
})

$(document).on('click', '@allOneOffFalse', function () {
  if ($(this).prop('checked')) {
    $('@selectOneOff').prop('disabled', false)
  }
})

$(document).on('click', '@defaultEmailOneOffTrue', function () {
  if ($(this).prop('checked')) {
    $('@emailFieldOneOff').prop('disabled', true).val('')
  }
})

$(document).on('click', '@defaultEmailOneOffFalse', function () {
  if ($(this).prop('checked')) {
    $('@emailFieldOneOff').prop('disabled', false)
  }
})

$(document).on('click', '@allAdjustmentsTrue', function () {
  if ($(this).prop('checked')) {
    $('@selectAdjustments').prop('disabled', true).select2('data', null)
  }
})

$(document).on('click', '@allAdjustmentsFalse', function () {
  if ($(this).prop('checked')) {
    $('@selectAdjustments').prop('disabled', false)
  }
})

$(document).on('click', '@defaultEmailAdjustmentsTrue', function () {
  if ($(this).prop('checked')) {
    $('@emailFieldAdjustments').prop('disabled', true).val('')
  }
})

$(document).on('click', '@defaultEmailAdjustmentsFalse', function () {
  if ($(this).prop('checked')) {
    $('@emailFieldAdjustments').prop('disabled', false)
  }
})

$(() => {
  $('@oneOffForm @sweetAlert@reportsManage@oneOff').on('click', (event) => {
    const target = event.target
    event.preventDefault()

    const managers_names = $.map($('@oneOffForm #s2id_manager_ids_ li div'), (element) =>
      $(element).text()
    ).join(', ')

    let oneoff_message = ''
    if ($('@allOneOffTrue').prop('checked')) {
      oneoff_message = 'This will send reports of all managers'
    } else if ($('@allOneOffFalse').prop('checked')) {
      oneoff_message = `This will send ${managers_names} reports`
    }
    if ($('@defaultEmailOneOffTrue').prop('checked')) {
      oneoff_message += ' to their emails'
    } else if ($('@defaultEmailOneOffFalse').prop('checked')) {
      const email = $('@emailFieldOneOff').val()
      oneoff_message += ` to ${email}`
    }

    let params = {
      title: 'Are you sure?',
      text: oneoff_message,
      icon: 'warning',
      buttons: {
        cancel: true,
        confirm: {
          text: 'Yes, Send Reports',
          closeModal: false,
        },
      },
    }
    const dataParams = $(target).data('sweetalert')
    if (dataParams) {
      params = $.extend(params, dataParams)
    }

    swal(params).then((isConfirm) => {
      if (isConfirm) {
        swal({
          title: 'Done!',
          text: 'Successfully queued up managerial reports',
          icon: 'success',
        })
        if ($(target).prop('type') == 'submit') {
          $(target).closest('form').submit()
        }
      }
      return
    })
  })

  $('@adjustmentsForm @sweetAlert@reportsManage@adjustments').on('click', (event) => {
    const target = event.target
    event.preventDefault()

    const managers_names = $.map($('@adjustmentsForm #s2id_manager_ids_ li div'), (element) =>
      $(element).text()
    ).join(', ')
    let adjustments_message = ''
    if ($('@defaultEmailAdjustmentsTrue').prop('checked')) {
      adjustments_message = `This will send ${managers_names} reports to their emails`
    } else {
      const email = $('@emailFieldAdjustments').val()
      adjustments_message = `This will send #{managers_names} reports to ${email}`
    }

    let params = {
      title: 'Are you sure?',
      text: adjustments_message,
      icon: 'warning',
      buttons: {
        cancel: true,
        confirm: {
          text: 'Yes, Send Reports',
          closeModal: false,
        },
      },
    }
    const dataParams = $(target).data('sweetalert')
    if (dataParams) {
      params = $.extend(params, dataParams)
    }
    swal(params).then((isConfirm) => {
      if (isConfirm) {
        swal({
          title: 'Done!',
          text: 'Successfully queued up managerial reports',
          icon: 'success',
        })
        if ($(target).prop('type') == 'submit') {
          $(target).closest('form').submit()
        }
      }
      return
    })
  })

  $('@sweetAlert@reportsManage@workflow').each((i, sweetAlert) => {
    $(sweetAlert).on('click', (event) => {
      const target = event.target
      event.preventDefault()
      let params = {
        title: 'Are you sure?',
        text: 'This will send the reports to all the users.',
        icon: 'warning',
        buttons: {
          cancel: true,
          confirm: {
            text: 'Yes, Send Reports',
            closeModal: false,
          },
        },
      }
      const dataParams = $(target).data('sweetalert')
      if (dataParams) {
        params = $.extend(params, dataParams)
      }
      swal(params).then((isConfirm) => {
        if (isConfirm) {
          swal({
            title: 'Done!',
            text: 'Successfully queued up managerial reports',
            icon: 'success',
          })
          if ($(target).prop('type') == 'submit') {
            $(target).closest('form').submit()
          }
        }
        return
      })
    })
  })
})
