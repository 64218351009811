import swal from 'sweetalert'

$(() => {
  $(document).on('click', 'button.bell-report-enable', (event) => {
    event.preventDefault()
    const url = $(event.target).data('url')
    const swal_params = {
      title: 'Confirmation',
      text: 'Are you sure to send Initial Report to Bell?',
      buttons: {
        cancel: true,
        confirm: {
          text: 'OK',
          closeModal: false,
        },
      },
    }
    swal(swal_params).then((ok) => {
      if (!ok) return
      $.post(url, { dataType: 'json' }, (response) => {
        if (response.success) {
          $(event.target).hide()
          swal({
            text: response.text,
            title: 'Success',
            icon: 'success',
          })
        } else {
          swal('Warning', response.text, 'warning')
        }
      })
    })
  })
})
