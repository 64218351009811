import swal from 'sweetalert'
import angular from 'angular'
import { isEmpty } from 'lodash-es'

angular.module('ArgonModule').controller('AirwatchDeviceSendMessageCtrl', [
  '$scope',
  function ($scope) {
    const VM = this

    VM.init = (params) => {
      VM.urls = $('#mdm-airwatch-device-urls').data('urls')

      let maxLength = -1
      if (params.mdm_data.base['Platform'] == 'Apple') maxLength = 208
      if (params.mdm_data.base['Platform'] == 'Android') maxLength = 1500

      VM.maxPushNotificationLength = maxLength

      VM.form = {
        errors: initializeErrors(),
        // possible values: 'email', 'push' and 'sms'
        type: 'email',
        text: '',
        appName: 'AirWatch Agent',
        phoneNumber: `+1${params.mdm_data.base['PhoneNumber']}`,
        email: params.mdm_data.base['UserEmailAddress'],
      }
    }

    VM.display = (formName) => {
      VM.form.type = formName
      return validate()
    }

    VM.isFormValid = () => {
      if (VM.form.type === 'email') {
        return (
          !(isEmpty(VM.form.email) || isEmpty(VM.form.text)) &&
          isEmpty(VM.form.errors.email) &&
          isEmpty(VM.form.errors.text)
        )
      }

      if (VM.form.type === 'push') {
        return !(isEmpty(VM.form.appName) || isEmpty(VM.form.text)) && isEmpty(VM.form.errors.text)
      }

      if (VM.form.type === 'sms') {
        return (
          !(isEmpty(VM.form.phoneNumber) || isEmpty(VM.form.text)) &&
          isEmpty(VM.form.errors.phoneNumber) &&
          isEmpty(VM.form.errors.text)
        )
      }

      return false
    }

    VM.validateEmail = () => {
      VM.form.errors.email = []
      if (isEmpty(VM.form.email)) return
      if (!isValidEmail(VM.form.email))
        VM.form.errors.email.push('Please enter a valid email address.')
    }

    VM.validatePhoneNumber = () => {
      VM.form.errors.phoneNumber = []
      if (isEmpty(VM.form.phoneNumber)) return
      if (!/^\+(\d|-|\s){10,}$/.exec(VM.form.phoneNumber)) {
        VM.form.errors.phoneNumber.push('Invalid phone number format.')
        return
      }
      if (VM.form.phoneNumber.replace(/\D/g, '').length < 10) {
        VM.form.errors.phoneNumber.push('Phone number must contain at least 10 digits.')
      }
    }

    VM.validateMessageBody = () => {
      VM.form.errors.text = []
      if (isEmpty(VM.form.text)) return

      if (VM.form.type == 'push' && VM.form.text.length > VM.maxPushNotificationLength) {
        VM.form.errors.text.push(
          `Message length should be a maximum of ${VM.maxPushNotificationLength} characters.`
        )
      }
    }

    VM.sendMessage = () => {
      const success = (response) => {
        if (response.succeeded) {
          VM.form.errors = initializeErrors()
          VM.form.text = ''
          VM.form.subject = ''
          $scope.$apply()
        }
      }
      switch (VM.form.type) {
        case 'email':
          makeRequest(VM.urls.send_email, {
            subject: VM.form.subject,
            email: VM.form.email,
            message: VM.form.text,
          }).done(success)
          break
        case 'push':
          makeRequest(VM.urls.push_notification, {
            app_name: VM.form.appName,
            message: VM.form.text,
          }).done(success)
          break
        case 'sms':
          makeRequest(VM.urls.send_sms, {
            number: VM.form.phoneNumber,
            message: VM.form.text,
          }).done(success)
          break
        default:
          return
      }

      $('#mdm-airwatch-send-message').modal('hide')
      return
    }

    //

    const validate = () => {
      VM.validateEmail()
      VM.validatePhoneNumber()
      VM.validateMessageBody()
    }

    const initializeErrors = () => ({
      text: [],
      email: [],
      appName: [],
      phoneNumber: [],
    })

    const isValidEmail = (email) => Boolean(/^[^@\s]+@[^@\s]+\.[^@\s]+$/.exec(email))

    const makeRequest = (url, params = {}) => {
      const success = (response) => {
        swal({
          title: response.succeeded ? 'Success' : 'Failure',
          text: response.message,
          icon: response.succeeded ? 'info' : 'error',
        })
      }
      const fail = () => swal({ title: 'Failure', text: 'Something went wrong', icon: 'error' })

      return $.post(url, params).done(success).fail(fail)
    }

    return VM
  },
])
