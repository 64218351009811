import { map } from 'lodash-es'
import { customer_employees_path } from '~/helpers/rails-routes'

const reinitializeMissingEmployeeNumbersEventHandlers = () => {
  let requests = []

  // alert close button
  $('@conflict-update-errors')
    .find('.close')
    .on('click', function () {
      $(this).closest('.alert').toggleClass('hide')
    })

  $('table select,input').change(function () {
    $(this).closest('form').data('touched', true)
  })

  $('@invalid-device-form').on('submit', function () {
    const $deviceForm = $(this)
    const url = $(this).attr('action')
    const params = $(this).serialize()
    requests.push(
      $.ajax(url, {
        method: 'post',
        data: params,
        dataType: 'JSON',
        success: () => {
          const success_msg = $('<span class="help-inline text-success"/>').text(
            'Data saved successfully.'
          )
          $deviceForm.parent('td').html(success_msg).closest('tr').delay(3000).fadeOut()
        },
        error: (response) => {
          const errors = (response.responseJSON && response.responseJSON.errors) || [
            response.statusText,
          ]
          $('@conflict-update-errors').removeClass('hide').find('ul li').text(errors.join(', '))
        },
      })
    )
    return false
  })

  $('@update-all-invalid-devices').on('click', function () {
    requests = []
    const $targetTable = $(this).prevAll('table:first')
    const $deviceForms = $targetTable.find('form')
    $deviceForms.map((index, form) => {
      if ($(form).data('touched')) {
        $(form).addClass('pure-spinner-block')
        $(form).submit()
      }
    })
    $.when.apply($, requests).always(() => $deviceForms.removeClass('pure-spinner-block'))
  })

  $('@employees-mapper-employee-number')
    .on('click', function () {
      const $form = $(this).closest('form')
      const $submit = $form.find('button[type="submit"]')
      $form.find('input[name="device[employee_number]"]').val($(this).data('employee-number'))
      $form.find('input[name="device[contact_id]"]').val($(this).data('contact-id'))
      $form.find('input[name="employee_id"]').val($(this).data('employee-id'))
      const is_employee_equal = Boolean($(this).data('employee-name-equal'))
      $form.data('touched', true)
      $submit
        .toggleClass('btn-default', !is_employee_equal)
        .toggleClass('btn-success', is_employee_equal)
      return false
    })
    .each(function () {
      const full_affinity = $(this).text().indexOf('100%') > 0
      if (full_affinity) {
        $(this).data('employee-name-equal', true)
        $(this).click()
      }
    })

  $('@employees-mapper-mark-non-employee-owned').on('click', function () {
    const $form = $(this).closest('form')
    const $input = $form.find('input[name="additional_data[non_employee_owned]"]')
    $input.val('true')
    $form.submit()
  })

  $('@employees-mapper-email-conflict').on('click', function () {
    const device_id = $(this).data('device-id')
    const employee_number = $(this).data('employee-number')
    const employee_name = $(this).data('employee-name')
    $(`input[name='employee_numbers[${device_id}]']`).select2('data', {
      id: employee_number,
      text: employee_name,
    })
  })
}

const loadConflictsData = (url) => {
  $('#data_conflict_tabs').find('#data_conflict_data > .no-data-loaded').show()
  $('#data_conflict_tabs').find('#data_conflict_data > .data-loaded').hide()
  $.ajax({
    method: 'POST',
    url,
    success: (data) => {
      $('#data_conflict_tabs').find('#data_conflict_data > .no-data-loaded').hide()
      $('#data_conflict_tabs').find('#data_conflict_data > .data-loaded').html(data)
      $('#data_conflict_tabs').find('#data_conflict_data > .data-loaded').show()
      reinitializeMissingEmployeeNumbersEventHandlers()
    },
    error: () => $('#data_conflict_tabs').find('.failed').show(),
  })
}

$(document).on('click', '#data_conflict_data .pagination a', (e) => {
  e.preventDefault()
  loadConflictsData($(e.target).attr('href'))
})

$(document).on('click', '@hrConflictsSuspendDevicesButton', (e) => {
  e.preventDefault()
  const formData = new FormData($(e.target).closest('form')[0])
  const selectedDevices = []
  for (const [key, value] of formData) {
    // collecting device ids that have to be suspended
    if (value === 'suspended' && key.includes('devices')) {
      selectedDevices.push({ id: key.match(/\d+/)[0], status: 'suspended' })
    }
  }
  // no devices selected
  if (!selectedDevices.length) return
  // a lot of devices selected
  const msg = `You've selected ${selectedDevices.length} devices. Do you want to bulk update all of them?`
  if (selectedDevices.length > 100 && !confirm(msg)) {
    return
  }
  // custom event for custom-report-device-suspend-cancel.vue app
  const event = new CustomEvent('showSuspendCancelModal', { detail: selectedDevices })
  window.dispatchEvent(event)
})

$(document).on('click', '@hrConflictsChangeEmployeeButton', (e) => {
  e.preventDefault()
  $('@hrConflictsResolver').val('change_employee')
  $('@hrConflictsDeviceId').val($(e.target).closest('tr').attr('data-device-id'))
  $(e.target).closest('form').removeAttr('role').submit()
})

$(document).on('click', '@hrConflictsSaveAllOver75Button', (e) => {
  e.preventDefault()
  $('@hrConflictsSaveAllOver75').val(true)
  $(e.target).closest('form').submit()
  $('@hrConflictsSaveAllOver75').val(false)
  location.reload()
})

$(() => {
  const parent = $('#data_conflict_tabs')
  const tab = parent.find('ul a.active')
  if (tab.length > 0) {
    loadConflictsData(tab.data('data'))
  }
})

// This fires on DataTable's 'drawCallback' event. Use this event to initialize select2 at all pages.
$(document).on('init.argon.ui', () => {
  const customer_id = $(document.body).data('customer-id')
  const url = customer_id ? customer_employees_path(customer_id) : undefined

  $('@hrConflictsEmployeeSelect')
    .filter(function () {
      return $(this).data('select2') === undefined
    })
    .select2({
      minimumInputLength: 3,
      ajax: {
        url: url,
        dataType: 'json',
        delay: 250,
        placeholder: 'Select employee',
        data: (params) => ({ q: params }),
        results: (data) => {
          const fetchedData = map(data.items, (employee) => {
            if (employee?.employee_number) {
              return {
                id: employee.employee_number,
                text: `${employee.name} (${employee.employee_number})`,
              }
            } else {
              return { id: '', text: '' }
            }
          })

          return { results: fetchedData }
        },
        cache: true,
      },
    })
})
