import swal from 'sweetalert'
import angular from 'angular'
import { at } from 'lodash-es'

angular.module('ArgonModule').controller('ReportsManageCtrl', function () {
  const VM = this

  VM.init = (params) => {
    VM.individualReportUsers = params.individualReportUsers
    VM.individualReportLines = 'all'
    VM.individualReportSendVia = 'default'
  }

  VM.recipients = () => at(VM.individualReportUsers, VM.individualReportLinesList).join(', ')

  VM.individualReportSweetalertMessage = () => {
    const to = VM.individualReportLines == 'all' ? 'all individual' : VM.recipients()
    const emails =
      VM.individualReportSendVia == 'default' ? 'their emails' : VM.individualReportEmail
    return `This will send <strong>${to}</strong> reports to <strong>${emails}</strong>.`
  }

  VM.isDisabled = () => {
    if (VM.individualReportLines === 'selected' && VM.recipients().length == 0) return true
    if (VM.individualReportSendVia === 'email' && VM.individualReportEmail == null) return true
  }

  VM.submitIndividuals = (event) => {
    event.preventDefault()
    const $btn = $(event.target)
    const contentEl = document.createElement('div')
    contentEl.insertAdjacentHTML('afterbegin', VM.individualReportSweetalertMessage())
    const params = {
      title: 'Are you sure?',
      content: contentEl,
      icon: 'warning',
      buttons: {
        cancel: 'Cancel',
        confirm: {
          text: 'Yes, Send Reports',
          closeModal: false,
        },
      },
    }
    swal(params).then((isConfirm) => {
      if (isConfirm) {
        swal({
          title: 'Done!',
          text: 'Successfully queued up individual reports',
          icon: 'success',
        })
        if ($btn.prop('type') == 'submit') {
          $btn.closest('form').submit()
        }
      }
      return
    })
  }

  VM.submitDataUsageNotifications = (event) => {
    event.preventDefault()
    const $btn = $(event.target)
    const params = {
      title: 'Are you sure?',
      text: 'This will send usage notification alerts via Email or SMS',
      icon: 'warning',
      buttons: {
        cancel: 'Cancel',
        confirm: {
          text: 'Yes, Send Notifications',
          closeModal: false,
        },
      },
    }
    swal(params).then((isConfirm) => {
      if (isConfirm) {
        swal({
          title: 'Done!',
          text: 'Successfully queued up data usage notifications',
          icon: 'success',
        })
        if ($btn.prop('type') == 'submit') {
          $btn.closest('form').submit()
        }
      }
      return
    })
  }

  return VM
})
