import swal from 'sweetalert'

const travelRequestNotificationAlertConfirmation = (url) => {
  $.ajax({
    type: 'GET',
    dataType: 'json',
    url: url,
  }).always((json) => {
    const id = json.travel_request_id
    if (json.status == 'success') {
      $(`@travelRequestNotificationAlertControls[data-request-id=${id}]`).remove()
      $(`@travelRequestNotificationAlertStatus[data-request-id=${id}]`).text(json.message)
    } else if (json.status == 'error') {
      if (json.redirest_to) {
        window.location.href = json.redirest_to
      }
    }
  })
}

const travelRequestApproveOrDecline = (url, code) => {
  $.ajax({
    type: 'GET',
    dataType: 'json',
    url,
    data: { code },
  }).always((json) => {
    if (json.status == 'error') {
      swal({ title: json.message, icon: 'error', timer: 2000 })
    } else {
      const id = json.travel_request_id
      $(`@travelRequestNotificationAlertControls[data-request-id=${id}]`).remove()
      $(`@travelRequestNotificationAlertStatus[data-request-id=${id}]`).text(json.message)
    }
  })
}

let timerId
const delay = (ms, callback) => {
  clearTimeout(timerId)
  timerId = setTimeout(callback, ms)
}

const findCityByNumber = () => {
  delay(300, () => {
    const $this = $('#device_number:visible, #wireline_number:visible')
    const number = $this.val()
    if (number && number.length > 0) {
      $.getJSON(`/exchanges/${number}`, (data) => {
        const location = data.location || '?'
        $('#device_location:visible, #wireline_location:visible').val(location)
      })
    }
  })
}

$(document).on('change', '#bulk_edit input[name="device_ids[]"]', () => {
  $('#bulk_edit_button').prop(
    'disabled',
    $('#bulk_edit input[name="device_ids[]"]:checked').length == 0
  )
})

$(document).on('click', '#bulk_edit_button', () => $('form#bulk_edit').submit())

$(document).on('click', '#bulk_edit_select_all', () => {
  if ($('#bulk_edit input[name="device_ids[]"]:not(:checked)').length == 0) {
    $('#bulk_edit input[name="device_ids[]"]').click()
  } else {
    $('#bulk_edit input[name="device_ids[]"]:not(:checked)').click()
  }
})

$(document).on('click', '@similar_devices_display_all_link', (e) => {
  $('@similar_device').removeClass('hide')
  $(e.target).remove()
})

$(document).on('click', '@add-device-mdm-modal', () => {
  const modalForm = $('#mdm-enroll-device.modal').first()
  modalForm.modal('show')
  modalForm.find('#initialize-mdm-form').first().click()
})

$(document).on('keyup', '#device_number, #wireline_number', findCityByNumber)

$(() => {
  $('@travelRequestNotificationAlertApprove, @travelRequestNotificationAlertDecline').on(
    'click',
    (e) => travelRequestApproveOrDecline($(e.target).data('url'), $(e.target).data('code'))
  )

  $('@travelRequestNotificationAlertConfirm').on('click', (e) =>
    travelRequestNotificationAlertConfirmation($(e.target).data('url'))
  )

  $('.select2[role=approver_picker]').on('select2-selecting', (e) => {
    $.each($(e.target).parent().children('a'), (i, button) => $(button).data('code', e.val))
  })

  if (
    $('#device_number').length &&
    $('#device_location').length &&
    $('#device_location').val() == ''
  ) {
    findCityByNumber()
  }
  if (
    $('#wireline_number').length &&
    $('#wireline_location').length &&
    $('#wireline_location').val() == ''
  ) {
    findCityByNumber()
  }
  handleCarrierSelectionChange.apply($('#device_business_account_id'))
})

function handleCarrierSelectionChange() {
  const selectedElem = $(this).find(':selected')
  const attrCountry = selectedElem.attr('data-carrier-country')
  const attrIsInternational = selectedElem.attr('data-international')
  if (attrIsInternational === 'true') {
    $('#device_location').val(attrCountry)
    $('#device_location').prop('readonly', true);
  } else {
    $('#device_location').prop('readonly', false);
    $('#device_location').val('')
  }
}

$('#device_business_account_id').on('change', handleCarrierSelectionChange)
