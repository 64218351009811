$(() => {
  $('li.list-group-item a.active').each((_index, element) => {
    $(element).removeClass('active')
    $(element).parent().addClass('active')
  })

  $('ul.nav.nav-tabs > li > a.active').each((_index, element) => {
    $(element).parent().addClass('active')
  })
})
