import { each, isEmpty } from 'lodash-es'
import { DateHelper } from './date_helper'

export class MsIntuneInfo {
  static displayDate = (dateTime) => DateHelper.displayDate(dateTime)

  static mapping = {
    compliance_state: {
      title: 'Compliance state',
    },
    device_registration_state: {
      title: 'Registration state',
    },
    device_type: {
      title: 'Device type',
    },
    device_name: {
      title: 'Device name',
    },
    email_address: {
      title: 'Email',
    },
    id: {
      title: 'MS Intune ID',
    },
    imei: {
      title: 'IMEI',
    },
    jail_broken: {
      title: 'Rooted',
    },
    last_sync_date_time: {
      title: 'Last sync date',
      formatter: MsIntuneInfo.displayDate,
    },
    lost_mode_state: {
      title: 'Lost-mode state',
    },
    manufacturer: {
      title: 'Manufacturer',
    },
    model: {
      title: 'Model',
    },
    operating_system: {
      title: 'OS',
    },
    os_version: {
      title: 'OS Version',
    },
    phone_number: {
      title: 'Phone Number',
    },
    serial_number: {
      title: 'Serial Number',
    },
  }

  static groupedAttributes = {
    short: [
      {
        title: 'Device',
        attributeNames: ['serial_number', 'imei', 'compliance_state'],
      },
      {
        title: 'Info',
        attributeNames: ['device_name', 'phone_number'],
      },
      {
        title: 'Intune Status',
        attributeNames: ['id', 'device_registration_state'],
      },
    ],
    full: [
      {
        title: 'Device',
        attributeNames: [
          'serial_number',
          'imei',
          'compliance_state',
          'device_type',
          'manufacturer',
          'model',
          'operating_system',
          'os_version',
          'jail_broken',
        ],
      },
      {
        title: 'Info',
        attributeNames: ['device_name', 'phone_number', 'email_address'],
      },
      {
        title: 'Intune Status',
        attributeNames: [
          'id',
          'device_registration_state',
          'last_sync_date_time',
          'lost_mode_state',
        ],
      },
    ],
  }

  static buttons = {
    short: ['data_update', 'installed_applications', 'actions_history'],
    full: [
      'data_update',
      'installed_applications',
      'actions_history',
      'remote_lock',
      'wipe_device',
      'reset_passcode',
    ],
  }

  static value = (name) => {
    if (!MsIntuneInfo.mapping[name].formatter) return MsIntuneInfo.data.base[name]
    return MsIntuneInfo.mapping[name].formatter(MsIntuneInfo.data.base[name])
  }

  constructor(data) {
    MsIntuneInfo.data = data
    this.updatedAt = DateHelper.displayDate(new Date(Date.parse(data.updated_at)))
    this.displayMode = 'short'
    if (isEmpty(data.base)) {
      $('.mdm-info').hide()
      return
    }
    this.managedStatus = data.base.maas360_managed_status
    this.displayData = {
      attributeGroups: this.prepareData(this.displayMode),
      buttons: this.prepareButtons(this.displayMode),
    }
  }

  prepareData(mode) {
    const result = []
    each(MsIntuneInfo.groupedAttributes[mode], (group) => {
      const groupedAttrs = { attributes: [], title: group.title }
      each(group.attributeNames, (name) => {
        const value = MsIntuneInfo.value(name)
        if (value) {
          const obj = { title: MsIntuneInfo.mapping[name].title, value: value }
          groupedAttrs.attributes.push(obj)
        }
      })
      result.push(groupedAttrs)
    })
    return result
  }

  prepareButtons(mode) {
    const result = {}
    each(MsIntuneInfo.buttons[mode], (name) => (result[name] = true))
    return result
  }

  getAttributeRawValue(name) {
    return MsIntuneInfo.data.base[name]
  }

  switchViewMode() {
    this.displayMode = this.displayMode === 'short' ? 'full' : 'short'
    this.displayData.attributeGroups = this.prepareData(this.displayMode)
    this.displayData.buttons = this.prepareButtons(this.displayMode)
  }
}
