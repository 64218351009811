import angular from 'angular'
import { ZohoInfo } from '../../models/zoho_info'

angular.module('ArgonModule').controller('ZohoDeviceActionsCtrl', [
  '$scope',
  function ($scope) {
    const VM = this

    VM.init = (params) => {
      VM.urls = $('#mdm-zoho-device-urls').data('urls')
      VM.mdmInfo = { data: new ZohoInfo(params.data), isLoading: true }
      checkApiAvailability()
    }

    VM.fetchMdmInfo = () => {
      if (!VM.urls) return
      VM.mdmInfo.isLoading = true
      $.post(VM.urls.refresh_mdm_info, { force: true }, (response) => {
        if (response.succeeded) {
          VM.mdmInfo.data = new ZohoInfo(response.data)
          initializeData()
        }

        VM.mdmInfo.isLoading = false
        $scope.$apply()
      })
    }

    const initializeData = () => {
      VM.installedApplications = { data: [], isLoading: false }
      VM.actionsHistory = { data: [], isLoading: false }
    }

    const checkApiAvailability = () => {
      if (!VM.urls) return
      $.post(VM.urls.status, {}, (response) => {
        if (response.available) {
          VM.fetchMdmInfo()
        } else {
          VM.mdmInfo.isLoading = false
          $scope.$apply()
        }
      })
    }

    return VM
  },
])
