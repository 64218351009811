import angular from 'angular'
import { merge, map, pull, sumBy } from 'lodash-es'

class HardwareOrder {
  constructor(itemFactory) {
    this.customer_id = null
    this.carrier_id = null

    this.customAccessory = {}
    this.ordered_items = []
    this.removedItems = []

    this.itemFactory = itemFactory
  }

  update(attributes) {
    for (const attr in attributes) {
      this[attr] = attributes[attr]
    }
    this.ordered_items = map(this.ordered_items, (item) =>
      this.itemFactory.new(merge(item, { order: this }))
    )
  }

  buildItem(attributes = {}) {
    attributes.tab = this.defaultTab()
    return this.itemFactory.new(merge(attributes, { order: this }))
  }

  // so for new orders no tab was preselected and I will show easy_mode_select template
  buildFirstItem(attributes = {}) {
    return this.itemFactory.new(merge(attributes, { order: this }))
  }

  removeItem(orderItem) {
    if (orderItem.id) this.removedItems.push(orderItem)
    return pull(this.ordered_items, orderItem)
  }

  totalCost(orderedItems) {
    return sumBy(orderedItems, 'total_cost')
  }

  isMixed() {
    return this.maximum_items != 1
  }

  defaultMode() {
    return this.config.default_mode
  }

  defaultTab() {
    return this.config.default_tab
  }

  attributes() {
    return {
      carrier_id: this.carrier_id,
      ordered_items_attributes: this.orderedItemsAttributes(),
    }
  }

  orderedItemsAttributes() {
    return this.existingItemsAttributes().concat(this.removedItemsAttributes())
  }

  existingItemsAttributes() {
    return map(this.ordered_items, (item) => item.attributes())
  }

  removedItemsAttributes() {
    return map(this.removedItems, (item) => ({ id: item.id, _destroy: 1 }))
  }
}

angular.module('argon.hardwareOrdering').service('$hwHardwareOrder', HardwareOrder)

HardwareOrder.$inject = ['$hwItemFactory']
