import swal from 'sweetalert'
import { DatatableLib } from '~/old/elements/datatable'
import {
  device_roaming_logs_path,
  customer_device_path,
  device_roaming_log_confirm_path,
  confirm_roaming_customer_device_path,
} from '~/helpers/rails-routes'

import { each } from 'lodash-es'

$(() => {
  const $containers = $('@deviceRoamingLogsContainer')
  if ($containers.length) {
    new DeviceRoamingLogsAll($containers)
  }
})

class DeviceRoamingLogsAll {
  /**
   *
   * @param {JQuery} $container
   */
  constructor($containers) {
    const $mainContainer = $('@roamingCatchesPanel')
    const scope = $('a.active:last').attr('href').replace('?scope=', '')

    each($containers, (container) => {
      const $container = $(container)
      $container.on('ajax:complete', '@remoteConfirmDeviceRoamingLog', (event, data) => {
        const $element = $(event.target)
        const json = data.responseJSON
        if (data.status) {
          const status = json.status == 'success' ? 'success' : 'fail'
          this.showNotificationInRow($element.closest('@row'), status, json.message)
        } else {
          this.showNotificationInRow($element.closest('@row'), 'fail', 'Something went wrong')
        }
      })

      $container.on('click', '@roamingNotificationConfirmation', (e) => {
        this.roamingNotificationConfirmation($(e.target).data('url'), $(e.target))
      })

      if (scope != 'Confirmed Roaming Catches') {
        const customer_id = $container.data('customerId')
        const path = device_roaming_logs_path({ customer_id: customer_id, scope: scope })
        new DatatableLib(
          $container.find('@deviceRoamingLogsDatatable'),
          this.pending_datatable_options(path)
        )
      }
    })

    $mainContainer.on('click', '@activateRoamingCatchesDatatable', (e) => {
      if (scope != 'Confirmed Roaming Catches') return
      $(e.target).removeAttr('role')
      $(e.target).find('@exposeIcon').remove()
      const $block = $(e.target).closest('@deviceRoamingLogsBlock')
      const $container = $block.find('@deviceRoamingLogsContainer')
      $container.show()
      const customer_id = $container.data('customerId')
      const path = device_roaming_logs_path({ customer_id: customer_id, scope: scope })
      new DatatableLib(
        $container.find('@deviceRoamingLogsDatatable'),
        this.confirmed_datatable_options(path)
      )
    })
  }

  roamingNotificationConfirmation(url, $btn) {
    swal({ title: 'Are you sure?', icon: 'warning', buttons: true }).then((isConfirm) => {
      if (!isConfirm) return
      $.ajax({
        type: 'POST',
        dataType: 'json',
        url,
      }).always((json) => {
        if (json.status == 'success') {
          $btn.closest('@row').find('@requestStatus').addClass('green').text('Confirmed')
          $btn.remove()
        } else if (json.status == 'error') {
          swal({ title: json.message, icon: 'error' })
        }
      })
    })
  }

  // Overlay row and show notification on this wrapper.
  // Color of the wrapper depends on status param.
  // If the status is 'success', remove this row from datatable.
  // @param [string] status - status of the request, possible values: 'success', 'fail'
  showNotificationInRow($row, status, message) {
    swal({ title: status, text: message, icon: status })
    if (status == 'success') {
      const $datatable = $row.closest('@deviceRoamingLogsContainer').find('.datatable')
      $datatable.DataTable().rows($row).remove().draw()
    }
  }

  confirmed_datatable_options(data_provider_url) {
    return {
      ajax: {
        url: data_provider_url,
        type: 'POST',
      },
      iDisplayLength: 5,
      bProcessing: true,
      bServerSide: true,
      bFilter: true,
      searchDelay: 500,
      columns: [
        { data: 'device_data', render: this.device_link_fn.bind(this) },
        { data: 'confirmer_data', render: this.confirmer_data_fn.bind(this) },
        { data: 'country', className: 'dt-right' },
        { data: 'created_at', className: 'dt-right', render: this.created_at_fn.bind(this) },
        { data: 'roaming_data_usage', className: 'dt-right', type: 'numeric-file-size' },
        {
          data: 'roaming_data_usage_cost',
          className: 'dt-right',
          type: 'numeric-file-size',
          render: this.roaming_cost_fn.bind(this),
        },
        {
          data: 'current_roaming_packages',
          className: 'dt-right',
          render: this.package_link_fn.bind(this),
        },
        {
          data: 'future_roaming_packages',
          className: 'dt-right',
          render: this.package_link_fn.bind(this),
        },
        {
          data: 'roaming_log_links_data',
          className: 'dt-right',
          render: this.roaming_log_link_fn.bind(this),
        },
      ],
    }
  }

  pending_datatable_options(data_provider_url) {
    return {
      ajax: {
        url: data_provider_url,
        type: 'POST',
      },
      iDisplayLength: -1,
      bProcessing: true,
      bServerSide: true,
      columns: [
        { data: 'device_data', render: this.device_link_fn.bind(this) },
        { data: 'country', className: 'dt-right' },
        { data: 'created_at', className: 'dt-right', render: this.created_at_fn.bind(this) },
        { data: 'roaming_data_usage', className: 'dt-right', type: 'numeric-file-size' },
        {
          data: 'roaming_data_usage_cost',
          className: 'dt-right',
          type: 'numeric-file-size',
          render: this.roaming_cost_fn.bind(this),
        },
        {
          data: 'current_roaming_packages',
          className: 'dt-right',
          render: this.package_link_fn.bind(this),
        },
        {
          data: 'future_roaming_packages',
          className: 'dt-right',
          render: this.package_link_fn.bind(this),
        },
        {
          data: 'roaming_log_links_data',
          className: 'dt-right',
          render: this.roaming_log_link_fn.bind(this),
        },
      ],
      drawCallback: function (settings) {
        if (!/pending/i.test(settings['ajax']['url'])) return
        const pagination = $(this).closest('.dataTables_wrapper').find('.dataTables_paginate')
        const pagination_info = $(this).closest('.dataTables_wrapper').find('.dataTables_info')
        pagination.toggle(this.api().page.info().pages > 1)
        pagination_info.toggle(this.api().page.info().pages > 1)
      },
    }
  }

  device_link_fn(data) {
    const link = customer_device_path(data.customer_id, data.device_id)
    let html = '<a target="_blank" href="' + link + '">'
    html += data.username + '<br>' + data.number + '</a>'

    return html
  }

  roaming_log_link_fn(data) {
    const scope = $('a.active:last').attr('href').replace('?scope=', '')
    let html = ''
    if (scope == 'Confirmed Roaming Catches') {
      return html
    }
    const confirm_link = device_roaming_log_confirm_path(data.id, {
      device_roaming_log: data.id,
    })
    html += '<a class="btn btn-success btn-xs" role="remoteConfirmDeviceRoamingLog" '
    html += 'data-device-roaming-log-id="' + data.id + '" data-remote="true" '
    html += 'href="' + confirm_link + '">Confirm</a>'

    return html
  }

  package_link_fn(data) {
    data = data[0]
    if (!data) return
    const status_color = data.status == 'Confirmed' ? 'green' : 'red'
    let html =
      '<p><span><span class="indicator" data-content=\'<strong class="green">' +
      data.hover_text +
      '</strong>'
    html += '<br>includes:<br>' + data.content + '\' data-placement="bottom" data-trigger="hover" '
    html += 'role="popover">' + data.title + '</span>'
    if (data.is_roaming_catch) {
      html += '<br><span class="red">Roaming Catch</span>'
    }

    html += ' - <span role="requestStatus" class="' + status_color + '">' + data.status + '</span>'
    html += '</span>'
    if (data.pending_simplex_confirm) {
      const confirm_link = confirm_roaming_customer_device_path(data.customer_id, data.device_id)
      html +=
        '<a data-url="' +
        confirm_link +
        '" class="btn btn-primary primary-branded-background btn-xs" '
      html += 'role="roamingNotificationConfirmation" rel="nofollow" data-method="post" '
      html += 'href="javascript:void(0)">Confirm</a>'
    }
    html += '</p>'

    return html
  }

  created_at_fn(data) {
    if (!data) return
    let html = '<p><span><span class="indicator" data-content=\'' + data.time
    html += '\' data-placement="bottom" data-trigger="hover" '
    html += 'role="popover">' + data.date + '</span>'
    html += '</span></p>'

    return html
  }

  confirmer_data_fn(data) {
    let html = data.confirmed_at
    if (data.confirmer_name) {
      html = data.confirmer_name + '<br>' + html
    }
    return html
  }

  roaming_cost_fn(data) {
    if (!data) return ''
    if (typeof data == 'string') {
      data = parseInt(data)
    }
    if (data > 100) {
      return '<span class="bold red">$' + data.toFixed(2) + '</span>'
    } else {
      return '<span>$' + data.toFixed(2) + '</span>'
    }
  }
}
