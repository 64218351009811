import { isObject } from 'lodash-es'

// for
// select role=select2 data-select=options
const initSelects = (element) => {
  $(element)
    .find('[role=select2]')
    .each(function () {
      const $input = $(this)
      const options = {}

      const selectOptions = $input.data('select')

      if (isObject(selectOptions)) $.extend(options, selectOptions)

      $input.select2(options)
    })
}

$(document).on('init.argon.selects', (event) => initSelects(event.target))
