import angular from 'angular'

angular.module('ArgonModule').directive('uiDropdown', [
  '$timeout',
  function () {
    return {
      link: ($scope, element) => $(element).trigger('init.argon.dropdown'),
    }
  },
])
