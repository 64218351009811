import angular from 'angular'
import { union, map, size, debounce, each } from 'lodash-es'
import { formatDate } from '~/helpers/format-date'
import { defineMessages, useI18n } from '~/helpers/use-i18n'

const messages = defineMessages({
  en: {
    no_devices_found: 'No devices found',
    start_end_total_devices: '_START_ to _END_ of _TOTAL_ records',
    from_max: '(from _MAX_)',
    loading: 'Loading...',
    processing: 'Processing...',
    next: 'Next',
    previous: 'Previous',
  },
  'fr-CA': {
    no_devices_found: 'Aucun Appareil Trouvé',
    start_end_total_devices: 'Appareils _START_ à _END_ de _TOTAL_',
    from_max: '(de _MAX_)',
    loading: 'Chargement...',
    processing: 'En traitement...',
    next: 'Suivant',
    previous: 'Précédent',
  },
})
const { t } = useI18n({ messages })

angular.module('ArgonModule').controller('crWirelinesController', [
  '$scope',
  '$timeout',
  '$http',
  'WirelineCustomReport',
  'crWirelineColumnsService',
  function ($scope, $timeout, $http, WirelineCustomReport, crWirelineColumnsService) {
    const VM = this

    VM.init = (filter_data, configuration) => {
      crWirelineColumnsService.init(configuration)
      VM.report = new WirelineCustomReport(filter_data)
      VM.export = {
        enabled: false,
        selectAllColumns: () => (VM.report.visible_columns = crWirelineColumnsService.allColumns()),
        selectNoneColumns: () =>
          (VM.report.visible_columns = crWirelineColumnsService.lockedColumns()),
        selectDefaultColumns: () =>
          (VM.report.visible_columns = crWirelineColumnsService.defaultColumns()),
        selectGroup: (group) => {
          const current_columns = VM.report.visible_columns
          const group_columns = crWirelineColumnsService.groupColumns(group)
          VM.report.visible_columns = union(current_columns, group_columns)
        },
        requestFile: (e) => request_export_to_excel(VM.dataTable, e.currentTarget),
      }

      $scope.$watch(
        'VM.report.validFilters()',
        VM.report.clear_value_when_switching_between_isnot_and_contains,
        true
      )
      $scope.$watch('VM.report.visible_columns', recreateDatatable, true)
      $scope.$watch('VM.report.visible_columns', saveColumnsToLocalStorage, true)
      $scope.$watch('VM.report.to_JSON()', () => VM.dataTable.ajax.reload(), true)
    }

    //
    const recreateDatatable = () => {
      if (VM.dataTable) {
        VM.dataTable.destroy(true)
      }
      VM.dataTable = initDatatable(VM.report.visible_columns)
    }

    const saveColumnsToLocalStorage = () => {
      localStorage.setItem(
        'argon_devices_page_visible_columns_2',
        JSON.stringify(VM.report.visible_columns)
      )
      const total_columns = VM.report.visible_columns.length - 1
      $('.devices-table-columns-button').html(
        `<i class='fa fa-columns'/> Select Columns <span class='badge'>${total_columns}</span>`
      )
    }

    const request_export_to_excel = (dt, node) => {
      const rows = dt.rows({ order: 'current', page: 'all', search: 'applied' }).data()
      const numbers = map(rows, 'number')
      const $node = $(node)
      $node.toggleClass('disabled').find('span').toggleClass('hide')

      const query = VM.report.to_JSON({
        datatables_search: dt.search(),
        ordered_phone_numbers: numbers,
      })

      const $form = $(
        "<form action='custom_wireline_report_export.xlsx' method='post'><input type='text' name='query'><input type='text' name='link'></form>"
      )
      $form.find('input[name="link"]').val(location.href)
      $form.find('input[name="query"]').val(JSON.stringify(query))

      const enableButton = () => $node.toggleClass('disabled').find('span').toggleClass('hide')
      // $.post/ajax fails on xlsx file response, using fetch instead of jQuery
      fetch($form.prop('action'), {
        method: 'POST',
        body: new FormData($form[0]),
        headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      })
        .then((resp) => resp.blob())
        .then((blob) => {
          const parts = $form.prop('action').split('.')
          const ext = parts[parts.length - 1]
          const url = URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.download = `Custom Wireline Report - ${formatDate(new Date(), '%Y-%m-%d')}.${ext}`
          document.body.appendChild(a)
          a.click()
          URL.revokeObjectURL(url)
          a.remove()
          enableButton()
        })
        .catch(() => {
          alert('Error happened, please try again')
          enableButton()
        })
    }

    const getEncodedParamsFromServer = () => {
      $.post(
        'custom_report_wirelines.json',
        { query: JSON.stringify(VM.report.to_JSON()) },
        (response) => VM.report.setLink(response.encoded_params)
      )
    }

    const request_devices = (data, callback) => {
      const start_time = new Date()
      const table_update_timeout = $timeout(() => (VM.loading_devices = true), 300)

      $.post(
        'custom_report_wirelines.json',
        { query: JSON.stringify(VM.report.to_JSON()) },
        (response) => {
          VM.devices_found_count = response.data.length
          VM.current_filters = response.filters
          VM.result = {
            breakdown: response.breakdown,
            breakdown_metadata: {
              number_of_makes: size(response.breakdown.by_make),
            },
          }
          VM.debug_data = response.debug
          VM.report.setLink(response.encoded_params)
          // display rows in datatable
          callback(response)
          // Resize window to fix bug in IE: it's not able to figure out what's the width of the table
          setTimeout(() => $(window).resize(), 100)
          $timeout.cancel(table_update_timeout)
          $timeout(() => {
            VM.loading_devices = false
            if (VM.debug_data) {
              VM.debug_data.total_time = `${(new Date() - start_time) / 1000} sec`
            }
          })
        }
      )
    }

    const debounced_request_devices = debounce(request_devices, 300)

    const $tableWrapperElement = $('#custom_report_devices_container')
    const initDatatable = (visible_columns) => {
      const visible_columns_definitions = crWirelineColumnsService.findDefinitions(visible_columns)
      // .display and .compact are DataTable classes: https://www.datatables.net/manual/styling/classes
      const $table = $("<table class='display nowrap compact'>")
      $tableWrapperElement.append($table)
      const datatable = $table.DataTable({
        select: {
          style: 'multi',
          selector: 'td:first-child',
        },
        pageLength: 50,
        // sort by username by default
        order: [[1, 'asc']],
        buttons: {
          dom: {
            buttonLiner: {
              // we don't want to wrap text inside buttons with <span>
              tag: '',
            },
            button: {
              tag: 'a',
              className: 'btn btn-sm',
            },
          },
          buttons: [
            {
              text: 'Select All',
              className: 'btn-success pull-left',
              action: function (e, dt, node) {
                const $node = $(node)

                if ($node.text() == 'Select All') {
                  this.rows({ search: 'applied' }).select()
                  $node.text('Select None')
                } else {
                  this.rows().deselect()
                  $node.text('Select All')
                }
              },
            },
            {
              text: "<i class='fa fa-columns'/> Select Columns",
              className: 'btn-primary devices-table-columns-button',
              action: () => {
                VM.export.enabled = false
                $timeout(() => true)
                $('@custom_report_export').modal()
              },
            },
            {
              text: "<i class='fa fa-file-excel-o'/> Export to Excel",
              className: 'btn-success devices-table-exports-button',
              action: () => {
                VM.export.enabled = true
                $timeout(() => true)
                $('@custom_report_export').modal()
              },
            },
            {
              extend: 'print',
              autoPrint: false,
              className: 'btn-warning hidden-xs',
              text: "<i class='fa fa-print'/> Print",
            },
          ],
        },
        // filter, buttons, table, info, pagination
        dom: '<"col-xs-12"f><"col-xs-12"B>tip',
        scrollX: true,
        autoWidth: true,
        deferRender: true,
        colReorder: {
          fixedColumnsLeft: 2,
          // emit reorder event only on column drop
          realtime: false,
        },
        drawCallback: function () {
          const api = this.api()
          const search_term = api.search()
          const $body = $(api.table().body())
          $body.unhighlight()
          if (search_term && search_term.trim().length > 0) {
            $body.highlight(api.search())
          }
        },
        language: {
          // We don't need search label
          search: '',
          emptyTable: t('no_devices_found'),
          info: t('start_end_total_devices'),
          infoEmpty: t('no_devices_found'),
          infoFiltered: t('from_max'),
          loadingRecords: t('loading'),
          processing: t('processing'),
          zeroRecords: t('no_devices_found'),
          paginate: {
            next: t('next'),
            previous: t('previous'),
          },
        },
        ajax: (data, callback, settings) => debounced_request_devices(data, callback, settings),
        columns: visible_columns_definitions,
      })

      datatable.off('column-reorder')
      datatable.on('column-reorder', (e, settings, details) => {
        const new_columns = []
        each(details.mapping, (new_column_index, array_index) => {
          new_columns[new_column_index] = VM.report.visible_columns[array_index]
        })
        VM.report.visible_columns = new_columns
        saveColumnsToLocalStorage()
        getEncodedParamsFromServer()
      })

      // Don't close dropdown, when the user clicks inside of it:
      $(document).off('click', '#custom_report .dropdown-menu')
      $(document).on('click', '#custom_report .dropdown-menu', (e) => e.stopPropagation())

      $('#custom_report .dataTables_filter input')
        .addClass('form-control')
        .attr('type', 'text')
        .attr('placeholder', 'Search by any cell value...')

      return datatable
    }

    return VM
  },
])
