import swal from 'sweetalert'

// http://thelazylog.com/custom-dialog-for-data-confirm-in-rails/

$(document).on('confirm', '@sweetconfirm', (event) => {
  const $element = $(event.target)

  const message = $element.data('confirm')

  if (!message) return true
  const contentEl = document.createElement('div')
  contentEl.insertAdjacentHTML('afterbegin', message)

  const isAjax = $element.data('ajax')
  const customClass = $element.data('alertClass')

  let params = {
    title: 'Are you sure?',
    content: contentEl,
    icon: 'warning',
    buttons: [true, 'Confirm'],
    className: customClass,
  }

  const dataParams = $element.data('sweetalert')
  if (dataParams) params = $.extend(params, dataParams)

  swal(params).then((isConfirm) => {
    // You can send ajax or just click to link.
    if (isAjax) $.rails.fire($element, 'confirm:complete:ajax', isConfirm)
    else $.rails.fire($element, 'confirm:complete', isConfirm)
  })

  return false
})

$(document).on('confirm:complete', '@sweetconfirm', (event, isConfirm) => {
  const $element = $(event.target)

  if (isConfirm) {
    $element.data('confirm', null)
    // trigger('click') not works if you want go to href.
    event.target.click()
  }

  return true
})
