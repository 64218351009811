$(() => {
  $('.message-preview').click((e) => {
    e.preventDefault()
    $('p[role=message-preview]').text('Loading...')
    const markdownText = $('#myserve_update_message').val()
    $.ajax({
      url: $(e.target).data('preview-url'),
      type: 'POST',
      data: { text: markdownText },
      dataType: 'json',
      success: (json) => {
        $('p[role=message-preview]').html(json.html)
        $('#message-preview').removeClass('hide')
      },
      fail: () => $('p[role=message-preview]').html('Preview error occurred'),
    })
  })
})
