$(() => {
  $('@business-report-individual-reports-table').dataTable({
    processing: true,
    serverSide: true,
    ajax: location.pathname,
    pageLength: 100,
    retrieve: true,
    lengthChange: false,
    ordering: false,
    columns: [{ data: 'name' }, { data: 'email' }, { data: 'link' }],
  })
})
