import angular from 'angular'
import { DeviceSearch } from '../models/device_search'

angular.module('ArgonModule').directive('deviceSearch', [
  '$timeout',
  function ($timeout) {
    return {
      link: ($scope, element, attributes) => {
        const options = JSON.parse(attributes.deviceSearch || '{}')
        $timeout(() => new DeviceSearch(element).activate(options))
      },
    }
  },
])
