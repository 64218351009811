import angular from 'angular'

angular.module('ArgonModule').controller('DeviceUsageThresholdsCtrl', [
  '$scope',
  '$element',
  function ($scope, $element) {
    const $modal = $element.find('#device-usage-users-modal .modal')
    const $grid = $element.find('.datagrid')

    $scope.showPreview = (id, event) => {
      event.preventDefault()
      $modal.find('.modal-dialog').addClass('modal-lg')
      $modal.modal('show')
      $grid.trigger('datagrid-reload', { id: id })
      return
    }
  },
])
