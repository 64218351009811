$(() => {
  const container = $('@modalContainer')
  if (!container.length) return

  container.on('ajax:complete', '@travelRequestNotificationResendModalForm', (e, data) => {
    const notice = data.responseJSON.notice
    const alertClass = data.responseJSON.status === 'success' ? 'alert-success' : 'alert-warning'
    $(e.target).replaceWith(`<div class='alert ${alertClass}'><h2>${notice}</h2></div>`)
    setTimeout(() => container.modal('hide'), 3000)
  })
})
