export class Order {
  constructor() {
    this.purchaseOrder = {}
  }

  total = () => {
    let result = this.orderItems
      .map((item) => item.known_cost)
      .reduce((a, b) => Number(a) + Number(b))

    return (result || 0) + this.shipping_tax + this.accessoriesCost()
  }

  total_with_gst = () => this.total() * 0.05

  total_with_additional_tax = () => (this.total() * Number(this.additional_tax.tax)) / 100
  total_with_taxes = () => this.total() * (1.05 + Number(this.additional_tax.tax) / 100)

  accessoriesCost = () => {
    let accessories = this.orderItems.map((item) => item.accessories)
    accessories = accessories.reduce((a, b) => a.concat(b))

    if (accessories.length <= 0) return 0

    const costs = accessories.map((accessory) => accessory.dealer_cost * accessory.quantity)
    const cost = costs.reduce((a, b) => a + b)

    return cost || 0
  }
}
