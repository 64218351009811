$(() => {
  $(document).on('click', '[role=print]', function (event) {
    event.preventDefault()

    $.get(this.getAttribute('href'), (data) => {
      const elements = $('div').not('non-printable')

      elements.toggleClass('non-printable')

      const printable = $(data)

      const div = $("<div class='only-printable'></div>")
      printable.appendTo(div)
      div.appendTo('body')

      window.print()

      div.remove()
      elements.toggleClass('non-printable')
    })
  })
})
