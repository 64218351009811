import swal from 'sweetalert'
import { users_impersonate_path } from '~/helpers/rails-routes'

$(() => {
  $(document).on('change', 'form.custom.user #user_role', (event) => {
    const row = $('.row.management-companies')

    $(event.target).val() === 'help_desk' ? row.removeClass('hide') : row.addClass('hide')
  })

  $(document).on('click', '#user-impersonate-email-link', (event) => {
    event.preventDefault()
    const swal_params = {
      title: 'Enter user email to sign in',
      content: {
        element: 'input',
        attributes: {
          placeholder: 'email@example.com',
        },
      },
      buttons: {
        cancel: true,
        confirm: {
          text: 'Submit',
          closeModal: false,
        },
      },
    }

    swal(swal_params).then((email) => {
      if (!email) return
      $.get(users_impersonate_path(email), { dataType: 'json' }, (response) => {
        if (response.success) {
          const swal_params = {
            title: 'Please wait',
            text: "You'll be redirected now to the user's homepage",
            icon: 'success',
          }
          swal(swal_params)
          window.location.href = response.data
        } else {
          swal('Warning', response.data, 'warning')
        }
      })
    })
  })
})
