import { locale } from '~/helpers/use-i18n'

const initDatatable = (element) => {
  let options = {
    pagingType: 'simple_numbers',
    bRetrieve: true,
    bLengthChange: false,
    drawCallback: () => {
      const eventName = 'init.argon.ui'
      // jQuery listeners
      $(document).trigger(eventName)
      // native listeners
      document.dispatchEvent(new CustomEvent(eventName))
    },
  }

  const params = deparam(window.location.search.toString().replace(/^\?/, ''))
  if (params.search) {
    options['search'] = { search: params.search }
  }

  const notSortable = []
  $(element)
    .find('th')
    .each((idx) => {
      if ($(element).is('.sorting-disabled')) {
        notSortable.push(idx)
      }
    })

  const args = $(element).data('datatable')
  if (args && typeof args == 'object') {
    options = $.extend(options, args)
  }

  if ($(element).find('th.sorting-disabled').length > 0) {
    options['columnDefs'] = [
      {
        orderable: false,
        sortable: false,
        targets: 'sorting-disabled',
      },
    ]

    if (options['order'] != false && !options['order'] && !options['aaSorting']) {
      options['order'] = [
        [Math.max(0, $(element).find('th:not(.sorting-disabled)').index()), 'asc'],
      ]
    }
  }

  $(element).dataTable(options)
}

$(document).on('init.argon.datatable', (event) => initDatatable(event.target))

// All DataTables available locales
const dataTableLocales = {
  en: {
    sEmptyTable: 'No data available in table',
    sInfo: 'Showing _START_ to _END_ of _TOTAL_ entries',
    sInfoEmpty: 'Showing 0 to 0 of 0 entries',
    sInfoFiltered: '(filtered from _MAX_ total entries)',
    sInfoPostFix: '',
    sInfoThousands: ',',
    sLengthMenu: 'Show _MENU_ entries',
    sLoadingRecords: 'Loading...',
    sProcessing: 'Processing...',
    sSearch: 'Search:',
    sZeroRecords: 'No matching records found',
    oPaginate: {
      sFirst: 'First',
      sLast: 'Last',
      sNext: 'Next',
      sPrevious: 'Previous',
    },
    oAria: {
      sSortAscending: ': activate to sort column ascending',
      sSortDescending: ': activate to sort column descending',
    },
  },
  'fr-CA': {
    sEmptyTable: 'Aucune donnée disponible dans le tableau',
    sInfo: "Affichage de l'élément _START_ à _END_ sur _TOTAL_ éléments",
    sInfoEmpty: "Affichage de l'élément 0 à 0 sur 0 élément",
    sInfoFiltered: '(filtré à partir de _MAX_ éléments au total)',
    sInfoPostFix: '',
    sInfoThousands: ',',
    sLengthMenu: 'Afficher _MENU_ éléments',
    sLoadingRecords: 'Chargement...',
    sProcessing: 'Traitement...',
    sSearch: 'Rechercher :',
    sZeroRecords: 'Aucun élément correspondant trouvé',
    oPaginate: {
      sFirst: 'Premier',
      sLast: 'Dernier',
      sNext: 'Suivant',
      sPrevious: 'Précédent',
    },
    oAria: {
      sSortAscending: ': activer pour trier la colonne par ordre croissant',
      sSortDescending: ': activer pour trier la colonne par ordre décroissant',
    },
    select: {
      rows: {
        _: '%d lignes sélectionnées',
        0: 'Aucune ligne sélectionnée',
        1: '1 ligne sélectionnée',
      },
    },
  },
  es: {
    sProcessing: 'Procesando...',
    sLengthMenu: 'Mostrar _MENU_ registros',
    sZeroRecords: 'No se encontraron resultados',
    sEmptyTable: 'Ningún dato disponible en esta tabla',
    sInfo: 'Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros',
    sInfoEmpty: 'Mostrando registros del 0 al 0 de un total de 0 registros',
    sInfoFiltered: '(filtrado de un total de _MAX_ registros)',
    sInfoPostFix: '',
    sSearch: 'Buscar:',
    sUrl: '',
    sInfoThousands: ',',
    sLoadingRecords: 'Cargando...',
    oPaginate: {
      sFirst: 'Primero',
      sLast: 'Último',
      sNext: 'Siguiente',
      sPrevious: 'Anterior',
    },
    oAria: {
      sSortAscending: ': Activar para ordenar la columna de manera ascendente',
      sSortDescending: ': Activar para ordenar la columna de manera descendente',
    },
    buttons: {
      copy: 'Copiar',
      colvis: 'Visibilidad',
    },
  },
}

// Set default dataTable locale to the current locale
$.extend(true, $.fn.dataTable.defaults, { language: dataTableLocales[locale] })
