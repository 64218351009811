import swal from 'sweetalert'
import { map, slice, snakeCase, first, join, each, difference, reduce } from 'lodash-es'

export class Stripe {
  constructor(disablingButtonsSelector = '') {
    this.disablingButtonsSelector = disablingButtonsSelector
  }

  disableLinks(e, label = 'wait...') {
    $(this.disablingButtonsSelector).attr('disabled', true)
    if (label) $(e.target).text(label)
  }

  csrfData() {
    const csrfToken = $('meta[name=csrf-token]').attr('content')
    const csrfParam = $('meta[name=csrf-param]').attr('content')
    return $('<input>')
      .attr('type', 'hidden')
      .attr('name', csrfParam)
      .attr('value', csrfToken)
      .prop('outerHTML')
  }

  buildNewForm(url) {
    const form = $('<form>').attr('method', 'post').attr('action', url).hide()
    this.addDataToForm(form, this.csrfData())
    return form
  }

  addDataToForm(form, htmlData) {
    return form.append(htmlData).appendTo('body')
  }

  setDataRemoteValue(form, e) {
    return form.data('remote', $(e.target).data('remote-request') || false)
  }

  buildInput(name, value, namePrefixes = []) {
    const path = namePrefixes.concat([name])
    const tail = map(slice(path, 1), (el) => `[${snakeCase(el)}]`)
    name = `${first(path)}${join(tail)}`
    return $('<input>').attr('type', 'hidden').attr('name', name).val(value).prop('outerHTML')
  }

  addAdditionalData(form, data, prefixes) {
    return each(data, (v, k) => {
      if (k == 'url' || k == 'remote-request' || k == 'remoteRequest') return
      this.addDataToForm(form, this.buildInput(k, v, prefixes))
    })
  }

  openStripeCheckout(e, handler) {
    // Open Checkout with further options:
    const target = $(e.target)

    const attrs = {
      description: target.attr('data-description'),
      currency: 'cad',
      amount: Number(target.attr('data-amount')),
      email: target.attr('data-email'),
    }

    return handler.open(attrs)
  }

  pay(e, form = null) {
    e.preventDefault()
    this.disableLinks(e)

    if (!form) form = this.buildNewForm($(e.target).data('url'))

    // Append all additional information about the payment to the form
    this.addAdditionalData(form, $(e.target).data(), ['data'])

    this.setDataRemoteValue(form, e)
    return form.submit()
  }

  registerCreditCardAndPay(e, dataPublicKey) {
    const $target = $(e.target)

    const handler = StripeCheckout.configure({
      panelLabel: $target.data('panel-label'),
      key: dataPublicKey,
      locale: 'auto',
      token: (token) => {
        const form = $target.parent('form')
        this.addAdditionalData(form, { stripeToken: token.id })
        if ($target.data('allow-autopayment')) this.addAdditionalData(form, { autopay: true })
        this.addAdditionalData(form, { receipt_email: token.email })

        return this.pay(e, form)
      },
    })

    if ($target.data('allow-autopayment')) {
      swal({
        title: 'Auto-payment',
        text:
          `This will create a payment profile for ${$target.data('number')}.` +
          ' We will charge you each month for the amount you owe on your bill',
        icon: 'info',
      }).then((agreed) => {
        if (!agreed) return
        this.openStripeCheckout(e, handler)
      })
    } else {
      this.openStripeCheckout(e, handler)
    }

    e.preventDefault()
  }
}

const bindBillingListToPayButton = (checkboxRole, payButtonId) => {
  $(document).on('click', `@${checkboxRole}`, () => {
    const bills = $(`@${checkboxRole}:checked`)
    const checked_ids = map(bills, (el) => $(el).data('line-id'))
    const btnPayAll = $(`a#${payButtonId}`)
    const form = btnPayAll.parent('form')
    const added_ids = map($("[name='billing_data_line_ids[]']"), (input) => $(input).val())

    if (checked_ids.length > 0) {
      btnPayAll.removeClass('disabled')
      const remove_inputs = difference(added_ids, checked_ids)
      each(remove_inputs, (line_id) => form.find(`[value='${line_id}']`).remove())

      const add_inputs = difference(checked_ids, added_ids)
      each(add_inputs, (line_id) => {
        const input = $('<input>').attr('type', 'hidden').attr('name', 'billing_data_line_ids[]')
        input.val(line_id)
        form.append($(input))
      })

      const sum = reduce(bills, (sum, el) => sum + +$(el).data('cost'), 0)
      btnPayAll.attr('data-amount', sum)
    } else {
      btnPayAll.addClass('disabled')
    }
  })
}

bindBillingListToPayButton('cb-bill-line-dashboard', 'pay-all-dashboard')
bindBillingListToPayButton('cb-bill-line-individual', 'pay-all-individual')

// Make POST-request by data-url. It makes sure it happens just once.
$(document).on('click', 'a[role=submit-link]:not([disabled])', (e) => {
  const stripe = new Stripe('a[role=submit-link], a[role=stripe-pay-selected]')
  stripe.pay(e)
})

$(document).on('click', '@stripe-pay-selected:not([disabled])', (e) => {
  e.preventDefault()
  $('a[role=submit-link], a[role=stripe-pay-selected]').attr('disabled', true)
  $(e.target).text('wait...')
  $(e.target).parent('form').submit()
})

$(document).on('click', '@stripe-payment', (e) => {
  const stripe = new Stripe('a[role=submit-link], a[role=stripe-pay-selected]')
  stripe.registerCreditCardAndPay(e, $('@stripe-payment').first().attr('data-public-key'))
})
