import angular from 'angular'
import { EmployeeContactsSearch } from '../models/employee_contacts_search'

angular.module('ArgonModule').directive('employeeContactsSearch', [
  '$timeout',
  function($timeout) {
    return {
      link: (_$scope, element) => {
        $timeout(() => new EmployeeContactsSearch(element).activate())
      },
    }
  },
])
