import angular from 'angular'

angular.module('ArgonModule').directive('selectedObject', [
  '$timeout',
  function ($timeout) {
    return {
      scope: {
        selectedObject: '=',
      },
      link: ($scope, element) => {
        // If selected object changes
        $scope.$watch('selectedObject', (nV) =>
          $timeout(() => (typeof nV !== 'undefined' ? element.select2('data', nV) : null))
        )

        // If selected value changes
        element.on('change', () => {
          $scope.selectedObject = element.select2('data')
          return $timeout(() => true)
        })
      },
    }
  },
])
