import angular from 'angular'

angular.module('ArgonModule').controller('DeviceNumberChange', [
  '$scope',
  ($scope) => {
    $scope.device = {
      deviceId: null,
      carrierName: '',
    }

    $scope.rogers = {
      changeNumber: 'manual',
      province: '',
      city: '',
      newNumber: '',
    }

    $scope.cityList = []
    $scope.availableNumbers = []
    $scope.responseErrors = []

    $scope.isRogers = () => $scope.device.carrierName === 'Rogers'

    $scope.showChangeNumberForm = () =>
      !$scope.isRogers() ||
      ($scope.rogers.changeNumber == 'manual' && $scope.isRogers()) ||
      ($scope.rogers.newNumber && $scope.rogers.changeNumber == 'roster' && $scope.isRogers())

    $scope.updateCityListBox = () => {
      $.getJSON('/cities.json', (data) => {
        $scope.cityList = data[$scope.rogers.province]
        $scope.$apply()
      })
    }

    $scope.getAvailableNumbers = () => {
      const old_number = $('#old_number').val()
      const city = $scope.rogers.city
      if (!city) return
      $scope.rogers.isLoading = true
      $.ajax({
        type: 'GET',
        url: '/quick_tools_requests/get_available_numbers',
        data: {
          device_id: $scope.device.deviceId,
          province: $scope.rogers.province,
          city: city.id,
          number: old_number,
        },
        success: (data) => {
          $scope.rogers.isLoading = false
          if (data.table.status == 'success') {
            $scope.availableNumbers = data.table.available_numbers
          }
          if (data.table.status == 'failure') {
            $scope.responseErrors = data.table.errors
          }
          $scope.$apply()
          $scope.attachSelectedCity()
        },
      })
    }

    $scope.attachSelectedCity = () =>
      $('input#device_location').attr('value', $scope.rogers.city.city)

    $scope.attachSelectedNumber = () =>
      $('input#device_number').attr('value', $scope.rogers.newNumber.toString())

    return $scope
  },
])
